import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";
import { IAvailableTemplate } from "shared/types";

const service = "enterprise/templates";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

interface TemplateListResponse {
  data: {
    templates: IAvailableTemplate[];
  };
}

export function useTemplateListApi(): [
  UseQueryResult<TemplateListResponse, Error>,
  () => void
] {
  const queryKey = "template-list";
  const response = useQuery<TemplateListResponse, Error>(queryKey, async () =>
    (await api.get)<TemplateListResponse>(`${serviceUrl}`)
  );

  const client = useQueryClient();
  const refreshResponse = () => {
    client.invalidateQueries(queryKey);
  };
  return [response, refreshResponse];
}
