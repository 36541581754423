import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";
import { TEventHandlerFunc } from "shared/types";
import { useEffect } from "react";
import { CustomEventSource } from "shared/network/api/event-source";

const service = "enterprise/document/status/stream";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export function useDocumentStatusStreamApi(
  documentId: string | null,
  eventHandler: TEventHandlerFunc
) {
  const url = `${serviceUrl}/${documentId}`;

  useEffect(() => {
    // Create a new EventSource instance to connect to the server
    if (documentId) {
      const eventSource = new CustomEventSource(url);

      eventSource.on("steps", (data) => {
        eventHandler(data);
      });

      eventSource.on("done", (data) => {
        eventHandler(data);
      });

      return () => {
        eventSource.close();
        console.log("EventSource connection terminated");
      };
    }
  }, [documentId]);
}

export function useDocumentStatusApi(
  documentId: string | null
): [UseQueryResult<unknown, Error>, () => void] {
  const queryKey = `document-status-stream-${documentId}`;
  const response = useQuery<unknown, Error>(
    queryKey,
    async () => (await api.get)<unknown>(`${serviceUrl}/${documentId}`),
    { enabled: !!documentId }
  );

  const client = useQueryClient();
  const refreshResponse = () => {
    client.invalidateQueries(queryKey);
  };
  return [response, refreshResponse];
}
