import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  FlexBox,
  Input,
  TabSelector,
  Text,
  View,
} from "mool-design/src";
import styled from "styled-components";
import { MenuItem } from "mool-design/src/components/molecules/dropdown";
import {
  CaretCircleRight,
  CheckCircle,
  WarningCircle,
} from "@phosphor-icons/react";
import {
  useDesignationList,
  useOnboarding,
  usePurposeList,
} from "shared/modules/user/onboarding";
import { useUserMe } from "shared/modules/user/user-me";

const StyledContainer = styled(FlexBox)`
  width: 100vw;
  background-color: #17171c;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const StyledOrgContainer = styled(FlexBox)`
  height: 28px;
  padding: 12px 16px;
  border-radius: 10px;
  align-items: center;
  flex: 1;
  background-color: #40404029;
`;

function Onboarding(): JSX.Element {
  const [orgName, setOrgName] = useState("");
  const [designation, setDesignation] = useState<MenuItem | null>(null);
  const [purpose, setPurpose] = useState<MenuItem[]>([]);
  const [isWork, setIsWork] = useState<boolean | null>(null);
  const [userName, setUserName] = useState("");

  const { handleOnboarding, isError, errorText } = useOnboarding();
  const { purposeList } = usePurposeList();
  const { designationList } = useDesignationList();
  const { user, org } = useUserMe();

  // errors
  const [orgError, setOrgError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [preferenceError, setPreferenceError] = useState("");
  const [designationError, setDesignationError] = useState("");
  const [areaOfInterestError, setAreaOfInterestError] = useState("");

  useEffect(() => {
    if (user?.name) {
      setUserName(user?.name);
    }
    if (org?.name) {
      setOrgName(org?.name);
      setIsWork(true);
    }
  }, [user?._id]);

  const handleUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (userNameError) {
      setUserNameError("");
    }
    setUserName(value);
  };

  const handleOrgName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (orgError) {
      setOrgError("");
    }
    setOrgName(value);
  };

  const handleDesignationSelection = (arg: MenuItem) => {
    setDesignation(arg);
    if (designationError) {
      setDesignationError("");
    }
  };

  const handlePurposeSelection = (arg: MenuItem) => {
    if (purpose.some((useCase) => arg.key === useCase.key)) {
      setPurpose((prev) => prev.filter((useCase) => useCase.key !== arg.key));
    } else {
      setPurpose((prev) => [...prev, arg]);
    }
    if (areaOfInterestError) {
      setAreaOfInterestError("");
    }
  };

  const onboardingCallback = () => {
    window.location.href = "/dashboard";
  };

  const handleContinue = () => {
    const purposeString = purpose.map((_p) => _p.key);
    if (isWork === null) {
      setPreferenceError("Please select you account type");
    } else if (isWork) {
      if (!userName) {
        setUserNameError("Please enter your full name");
      } else if (!orgName) {
        setOrgError("Please enter your organisation name");
      } else if (!designation) {
        setDesignationError("Please select you role");
      } else if (!purposeString.length) {
        setAreaOfInterestError("Please select at least 1 option");
      } else {
        setPreferenceError("");
        setOrgError("");
        setUserNameError("");
        setAreaOfInterestError("");
        setDesignationError("");
        const payload = {
          designation: designation.key,
          purpose: purposeString,
          accountType: "WORK",
          orgName,
          userName,
        };
        handleOnboarding(payload, onboardingCallback);
      }
    } else {
      if (!userName) {
        setUserNameError("Please enter your full name");
      } else if (!purposeString.length) {
        setAreaOfInterestError("Please select at least 1 option");
      } else {
        setPreferenceError("");
        setOrgError("");
        setUserNameError("");
        setAreaOfInterestError("");
        setDesignationError("");
        const payload = {
          purpose: purposeString,
          userName,
          designation: "",
          accountType: "PERSONAL",
          orgName: "",
        };
        handleOnboarding(payload, onboardingCallback);
      }
    }
  };

  return (
    <View position="fixed" style={{ marginTop: -64 }}>
      <StyledContainer>
        <FlexBox
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"400px"}
        >
          <FlexBox>
            <Text fontSize={3} fontWeight={"bold"} color={"text1"}>
              {org?.name ? "Setup your account" : "Create an account"}
            </Text>
          </FlexBox>
          <FlexBox flexDirection={"column"} width={"100%"}>
            {!org?.name && (
              <FlexBox mt={8}>
                <FlexBox flexDirection={"column"}>
                  <FlexBox alignItems={"center"}>
                    <FlexBox>
                      <Text fontSize={1} fontWeight={"regular"} color={"text1"}>
                        What will you be using mool for?
                      </Text>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox mt={3}>
                    <FlexBox mr={2}>
                      <TabSelector
                        backgroundColor="#40404029"
                        size="md"
                        key={"work"}
                        label="Work"
                        icon={
                          isWork === true ? (
                            <CheckCircle size={18} color={"#FFE600"} />
                          ) : (
                            <></>
                          )
                        }
                        isActive={isWork === true}
                        onClick={() => {
                          setIsWork(true);
                          setPreferenceError("");
                        }}
                        iconPlacement="left"
                      />
                    </FlexBox>
                    <FlexBox>
                      <TabSelector
                        backgroundColor="#40404029"
                        size="md"
                        key={"personal"}
                        label="Personal"
                        icon={
                          isWork === false ? (
                            <CheckCircle size={18} color={"#FFE600"} />
                          ) : (
                            <></>
                          )
                        }
                        isActive={isWork === false}
                        onClick={() => {
                          setIsWork(false);
                          setDesignation(null);
                          setOrgName("");
                          setPreferenceError("");
                        }}
                        iconPlacement="left"
                      />
                    </FlexBox>
                  </FlexBox>
                  {preferenceError && (
                    <FlexBox mt={1}>
                      <Text color={"error"} fontSize={0}>
                        {preferenceError}
                      </Text>
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>
            )}
            <FlexBox>
              {!user?.name && (
                <FlexBox flex={1} mt={5} flexDirection={"column"}>
                  <FlexBox>
                    <Input
                      value={userName}
                      onChange={handleUserName}
                      placeholderText={"Enter your full name"}
                      sizeVariant={"lg"}
                    />
                  </FlexBox>
                  <FlexBox>
                    {userNameError && (
                      <FlexBox mt={1}>
                        <Text color={"error"} fontSize={0}>
                          {userNameError}
                        </Text>
                      </FlexBox>
                    )}
                  </FlexBox>
                </FlexBox>
              )}
            </FlexBox>
            {isWork === true && (
              <FlexBox mt={5} flexDirection={"column"}>
                <FlexBox>
                  {org && org?.name ? (
                    <StyledOrgContainer>
                      <Text fontSize={1} fontWeight={"bold"} color={"text3"}>
                        {org?.name}
                      </Text>
                    </StyledOrgContainer>
                  ) : (
                    <FlexBox flex={1} flexDirection={"column"}>
                      <FlexBox>
                        <Input
                          value={orgName}
                          onChange={handleOrgName}
                          placeholderText={"Enter company/organisation name"}
                          sizeVariant={"lg"}
                        />
                      </FlexBox>
                      {orgError && (
                        <FlexBox mt={1}>
                          <Text color={"error"} fontSize={0}>
                            {orgError}
                          </Text>
                        </FlexBox>
                      )}
                    </FlexBox>
                  )}
                </FlexBox>
                <FlexBox mt={5} flexDirection={"column"}>
                  <FlexBox>
                    <Dropdown
                      variant={"default"}
                      placeholder={"Your role"}
                      iconColor="#635C1D"
                      menuItems={designationList}
                      selectedItem={designation}
                      onClick={handleDesignationSelection}
                      outline="transparent"
                      maxHeight="300px"
                    />
                  </FlexBox>
                  <FlexBox>
                    {designationError && (
                      <FlexBox mt={1}>
                        <Text color={"error"} fontSize={0}>
                          {designationError}
                        </Text>
                      </FlexBox>
                    )}
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}

            <FlexBox mt={5} flexDirection={"column"}>
              <FlexBox alignItems={"center"}>
                <FlexBox>
                  <Text fontSize={1} fontWeight={"regular"} color={"text1"}>
                    Describe your area of interest.
                  </Text>
                </FlexBox>
                <FlexBox ml={1}>
                  <Text fontSize={1} fontWeight={"regular"} color={"text2"}>
                    (Pick at least 1)
                  </Text>
                </FlexBox>
              </FlexBox>
              <FlexBox>
                {areaOfInterestError && (
                  <FlexBox mt={1}>
                    <Text color={"error"} fontSize={0}>
                      {areaOfInterestError}
                    </Text>
                  </FlexBox>
                )}
              </FlexBox>
              <FlexBox mt={3} flexWrap={"wrap"}>
                {purposeList.map((_purpose) => (
                  <FlexBox key={_purpose.key} m={1}>
                    <TabSelector
                      backgroundColor="#40404029"
                      size="md"
                      key={_purpose.key}
                      label={_purpose.label}
                      icon={
                        purpose.some((item) => item.key === _purpose.key) ? (
                          <CheckCircle size={18} color={"#FFE600"} />
                        ) : (
                          <></>
                        )
                      }
                      isActive={purpose.some(
                        (item) => item.key === _purpose.key
                      )}
                      onClick={() => handlePurposeSelection(_purpose)}
                      iconPlacement="left"
                    />
                  </FlexBox>
                ))}
              </FlexBox>
            </FlexBox>
            <FlexBox mt={8} flexDirection={"column"}>
              <FlexBox flex={1}>
                <Button
                  background="#91851D8A"
                  label="Create my account"
                  onClick={handleContinue}
                  icon={<CaretCircleRight size={18} color={"#ffffff"} />}
                />
              </FlexBox>
              <FlexBox>
                {isError && errorText && (
                  <FlexBox mt={2}>
                    <FlexBox>
                      <WarningCircle
                        size={20}
                        color={"#DF5050"}
                        weight="bold"
                      />
                    </FlexBox>
                    <FlexBox>
                      <Text
                        fontSize={1}
                        fontWeight={"semibold"}
                        color={"error"}
                        textAlign={"center"}
                      >
                        {errorText}
                      </Text>
                    </FlexBox>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </StyledContainer>
    </View>
  );
}

export default Onboarding;
