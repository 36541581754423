import styled from "styled-components";

import MoolAiLogoDark from "theme/assets/images/mool-ai-logo-dark.png";
import { useUserMe } from "shared/modules/user/user-me";
import { useAuth } from "shared/modules/auth-module";
import { useNavigate } from "react-router-dom";
import { FlexBox, OptionWrapper, Text } from "mool-design/src";
import { OptionMenuItem } from "mool-design/src/components/molecules/option-wrapper";

const IconContainerLeft = styled(FlexBox)`
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const IconContainerRight = styled(FlexBox)`
  justify-content: center;
  align-items: center;
`;

const NameIcon = styled(FlexBox)`
  background-color: #ff5c00;
  width: 22px;
  height: 22px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;

const StyledBanner = styled(FlexBox)`
  background-color: #17171c;
  justify-content: space-between;
  padding: 12px 16px;
  position: fixed;
  z-index: 10000;
  width: calc(100% - 32px);
`;

function NavBar(): JSX.Element {
  const { isLoading, fullName, initials, onboarding, isOrgMember } =
    useUserMe();
  const { logoutUser } = useAuth();
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate("/dashboard");
  };

  const handleLogout = () => {
    logoutUser();
  };

  const handleOptionSelection = (item: OptionMenuItem) => {
    if (item.key === "logout") {
      handleLogout();
    } else if (item.key === "account") {
      navigate("/account");
    }
  };

  const DROP_DOWN_OPTIONS = isOrgMember
    ? [
        { key: "account", label: "Account" },
        { key: "logout", label: "Logout" },
      ]
    : [{ key: "logout", label: "Logout" }];

  if ((onboarding && onboarding !== "completed") || isLoading) return <></>;

  return (
    <StyledBanner>
      <IconContainerLeft>
        <img
          src={MoolAiLogoDark}
          style={{ height: 40, marginRight: 8 }}
          alt="MoolAiLogoDark"
          onClick={onClickHandler}
        />
      </IconContainerLeft>
      <IconContainerRight>
        <OptionWrapper
          menuItems={DROP_DOWN_OPTIONS}
          onClick={handleOptionSelection}
        >
          <FlexBox alignItems={"center"}>
            <FlexBox>
              <NameIcon style={{ marginRight: 4 }}>
                <Text fontWeight={"regular"} fontSize={0} color={"text1"}>
                  {initials}
                </Text>
              </NameIcon>
            </FlexBox>
            <FlexBox>
              <Text fontSize={1} fontWeight={"bold"} color={"text1"}>
                {fullName}
              </Text>
            </FlexBox>
          </FlexBox>
        </OptionWrapper>
      </IconContainerRight>
    </StyledBanner>
  );
}

function TopNavBar(): JSX.Element {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <NavBar />;
  }
  return <FlexBox style={{ marginTop: -64 }}></FlexBox>;
}

export default TopNavBar;
