import { useDocId } from "./index";
import { useDocumentChatHistoryApi } from "shared/api/v3/enterprise/document/chat/{id}/get";
import { useModuleState } from "../module-state";
import { useEffect, useRef, useState } from "react";
import { IGraphChat, TEventStreamData } from "shared/types";
import { useDocumentChatStreamApi } from "shared/api/v3/enterprise/chat/stream/get";

export function useDocumentChat() {
  const [userPrompt, setUserPrompt] = useState("");
  const [isThinking, setIsThinking] = useState(false);

  const { docId } = useDocId();
  const [{ chatHistory }, setModuleState] = useModuleState();
  const chatHistoryRef = useRef(chatHistory);

  const [{ data, isLoading }, refreshResponse] =
    useDocumentChatHistoryApi(docId);

  const eventHandler = ({ data, type }: TEventStreamData) => {
    if (type === "done") {
      setUserPrompt("");
      refreshResponse();
      setIsThinking(false);
    } else {
      const lastIndex = chatHistoryRef.current.length - 1;
      const chatHistoryFromState = [...chatHistoryRef.current];
      const lastChat = { ...chatHistoryFromState[lastIndex] };
      const text = data.text;
      lastChat.response = text;
      chatHistoryFromState[lastIndex] = lastChat;
      chatHistoryRef.current = chatHistoryFromState;
      setModuleState({ chatHistory: chatHistoryFromState });
    }
  };

  useDocumentChatStreamApi(eventHandler, {
    docId: docId || "",
    userPrompt,
  });

  useEffect(() => {
    if (data?.data?.chat && Array.isArray(data?.data?.chat)) {
      chatHistoryRef.current = data?.data?.chat;
      setModuleState({ chatHistory: data?.data?.chat });
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    chatHistoryRef.current = chatHistory;
  }, [chatHistory]);

  const addChatInstance = (chatInstance: IGraphChat) => {
    chatHistoryRef.current = [...chatHistoryRef.current, chatInstance];
  };

  const handleDocumentChatStream = ({ userQuery }: { userQuery: string }) => {
    const _id = crypto.randomUUID();
    const chatInstance = {
      _id,
      documentId: docId,
      userPrompt: userQuery,
      response: "",
    } as IGraphChat;
    addChatInstance(chatInstance);
    setIsThinking(true);
    setUserPrompt(userQuery);
  };

  return {
    handleDocumentChatStream,
    isLoading,
    isPosting: isThinking,
    chatHistory: chatHistoryRef.current,
  };
}
