import React, { useState } from "react";

import { Button, FileIcon, FlexBox, Text } from "mool-design/src";
import styled from "styled-components";
import { Eye, Trash } from "@phosphor-icons/react";
import { getFullYearDateFormat } from "shared/utils/date-formatter";

import { IGraphFile } from "shared/types";
import DocViewModal from "./doc-view-modal";

const StyledFileContainer = styled(FlexBox)`
  padding: 8px 10px;
  border-radius: 10px;
  justify-content: space-between;
  border: 1px solid #26262e;
  align-items: center;
`;

function FileViewCard({ file }: { file: IGraphFile }): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <StyledFileContainer key={file.name} my={1}>
      <DocViewModal
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        docs={[
          {
            name: file.name,
            uri: file?.fileUrl || "",
          },
        ]}
      />
      <FlexBox alignItems={"center"} flex={1}>
        <FlexBox mr={1}>
          <FileIcon color={"#FFE600"} size={22} type={file?.type || ""} />
        </FlexBox>
        <FlexBox flexDirection={"column"}>
          <FlexBox>
            <Text fontSize={1} fontWeight={"bold"} color={"text1"}>
              {file.name}
            </Text>
          </FlexBox>
          <FlexBox alignItems={"center"} mt={1}>
            <Text fontSize={0} fontWeight={"regular"} color={"text2"}>
              {getFullYearDateFormat((file.createdAt || "").toString())}
            </Text>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <FlexBox>
        <FlexBox mr={2}>
          <Button
            label={""}
            background="#1E1E24"
            icon={<Eye size={16} color={"#ffffff"} />}
            onClick={openModal}
          />
        </FlexBox>
        <FlexBox>
          <Button
            label={""}
            background="#1E1E24"
            icon={<Trash size={16} color={"#ffffff"} />}
            onClick={() => null}
          />
        </FlexBox>
      </FlexBox>
    </StyledFileContainer>
  );
}

export default FileViewCard;
