import React from "react";
import FlexBox from "../../atoms/flexbox";
import styled from "styled-components";
import Text from "../../atoms/text";
import Button from "../button";

const StyledContainer = styled(FlexBox)`
  width: 560px;
  border-radius: 20px;
  background-color: #17171c;
  padding: 30px;
  flex-direction: column;
  box-shadow: 0px 8px 16px 0px #0000006b;
`;

interface Props {
  title: string;
  subTitle?: string;
  onClose: () => void;
  closeModal: () => void;
}

function ConfirmPopup({
  title,
  subTitle,
  onClose,
  closeModal,
}: Props): JSX.Element {
  return (
    <StyledContainer>
      <FlexBox flexDirection={"column"}>
        <FlexBox style={{ color: "#ffffff" }}>
          <Text fontSize={4} fontWeight={"bold"} color={"text1"}>
            {title}
          </Text>
        </FlexBox>
        <FlexBox style={{ color: "#B9B9C7" }}>
          {subTitle && (
            <Text fontSize={1} fontWeight={"regular"} color={"text2"}>
              {subTitle}
            </Text>
          )}
        </FlexBox>
      </FlexBox>
      <FlexBox
        alignItems={"flex-end"}
        flex={1}
        mt={5}
        style={{ color: "#ffffff" }}
      >
        <FlexBox flex={1} />
        <FlexBox flex={2}>
          <FlexBox flex={1}>
            <FlexBox flex={1} mx={2}>
              <Button label="No" onClick={onClose} background="#111114" />
            </FlexBox>
            <FlexBox flex={1} mx={2}>
              <Button
                label="Yes"
                onClick={() => {
                  closeModal();
                  onClose();
                }}
                background="#1D1293"
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </StyledContainer>
  );
}

export default ConfirmPopup;
