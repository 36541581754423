import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";
import { IGraphDocument } from "shared/types";

const service = "enterprise/document";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface DocumentSaveResponse {
  data: {
    document: IGraphDocument;
  };
}

export function useDocumentDeleteApi(): UseMutationResult<
  DocumentSaveResponse,
  unknown,
  unknown,
  unknown
> {
  return useMutation(({ docId }: { docId: string }) => {
    return api.delete<DocumentSaveResponse>(`${serviceUrl}/${docId}`);
  });
}
