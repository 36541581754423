import React, { useState } from "react";
import Modal from "react-modal";

import { Button, Dropdown, FlexBox, Input, Text } from "mool-design/src";
import styled from "styled-components";
import { MenuItem } from "shared/types";
import { useUserInvitation } from "shared/modules/account-settings/hooks/organization";
import { toastError, toastSuccess } from "../../utils/custom-toast";

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
}

const customStyles = {
  content: {
    top: "25%",
    left: "50%",
    width: "50%",
    maxWidth: "620px",
    height: "30%",
    transform: "translate(-50%, -5%)", // Center the modal horizontally
    padding: 0,
    backgroundColor: "#17171C",
    borderRadius: "20px",
    border: "0px",
    boxShadow: "0px 8px 16px 16px #0000006B",
  },
  overlay: {
    backgroundColor: "transparent",
  },
};

const StyledContainer = styled(FlexBox)`
  flex: 1;
  padding: 30px;
  border-radius: 20px;
  flex-direction: column;
  height: 88%;
`;

function InviteUserModal({ isModalOpen, closeModal }: Props): JSX.Element {
  const [selectedRole, setSelectedRole] = useState<MenuItem | null>({
    key: "member",
    label: "Member",
  });
  const [userEmail, setUserEmail] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const callback = (type: string) => {
    if (type === "success") {
      toastSuccess(`${userEmail} has been invited to your org`);
    }
    if (type === "error") {
      toastError(`Invite failed. Something went wrong`);
    }
  };

  const { handleUserInvitation } = useUserInvitation(callback);

  const handleSetUserEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUserEmail(value);
    if (!isDirty) {
      setIsDirty(true);
    }
    if (!value) {
      setIsDirty(false);
    }
  };

  const handleRoleSelection = (arg: MenuItem) => {
    setSelectedRole(arg);
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const sendUserInvite = () => {
    if (!isDirty) {
      setIsDirty(true);
    }
    if (userEmail && selectedRole) {
      const payload = {
        email: userEmail,
        role: selectedRole?.key,
      };
      handleUserInvitation(payload);
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <StyledContainer>
        <FlexBox>
          <Text fontSize={4} fontWeight={"bold"} color={"text1"}>
            Invite users to your organization
          </Text>
        </FlexBox>
        <FlexBox mt={2}>
          <Text fontSize={1} fontWeight={"regular"} color={"text3"}>
            We will send them an invitation link to the email provided below
          </Text>
        </FlexBox>
        <FlexBox flexDirection={"column"} justifyContent={"center"} flex={1}>
          <FlexBox alignItems={"flex-start"}>
            <FlexBox flex={2} mr={2}>
              <Input
                placeholderText="xyz@email.com"
                value={userEmail}
                onChange={handleSetUserEmail}
                errorText={"Please enter the email"}
                isError={isDirty && !userEmail}
              />
            </FlexBox>
            <FlexBox flex={1}>
              <Dropdown
                variant={"default"}
                placeholder={"Assign role"}
                iconColor="#635C1D"
                menuItems={[
                  { key: "admin", label: "Admin" },
                  { key: "member", label: "Member" },
                ]}
                selectedItem={selectedRole}
                onClick={handleRoleSelection}
                maxHeight="300px"
                size="sm"
              />
            </FlexBox>
          </FlexBox>
          <FlexBox mt={5}>
            <Button
              label="Invite User"
              background="#1D1293"
              onClick={sendUserInvite}
            />
          </FlexBox>
        </FlexBox>
      </StyledContainer>
    </Modal>
  );
}

export default InviteUserModal;
