import React, { useState } from "react";
import { Button, CustomSwitch, FlexBox, Text } from "mool-design/src";
import {
  Globe,
  Database,
  Folders,
  ArrowCircleRight,
} from "@phosphor-icons/react";
import { useDocumentSourceList } from "shared/modules/dashboard/hooks";
import { useFileUploadInput } from "shared/modules/editor/hooks";
import { useDocumentSettings } from "shared/modules/editor/hooks";
import FileUploadModal from "./components/file-upload-modal";
import {
  useDocFileList,
  useDocumentFileUpload,
} from "shared/modules/editor/hooks/doc";

const SettingsIcon = ({
  arg,
  isActive,
}: {
  arg: string;
  isActive: boolean;
}) => {
  if (arg === "web") {
    return <Globe size={16} color={isActive ? "#FFE600" : "#ffffff"} />;
  } else if (arg === "data") {
    return <Database size={16} color={isActive ? "#FFE600" : "#ffffff"} />;
  } else if (arg === "docs") {
    return <Folders size={16} color={isActive ? "#FFE600" : "#ffffff"} />;
  }
  return <></>;
};

function SourceSettings(): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { documentSources } = useDocumentSourceList();
  const { settings, updateSettings } = useDocumentSettings();
  const { selectedFiles } = useFileUploadInput();
  const { files } = useDocFileList();
  const { handleDocumentFileUpload, isLoading } = useDocumentFileUpload();

  const handleSettings = (arg: string) => {
    updateSettings(arg);
  };

  const uploadFiles = () => {
    handleDocumentFileUpload();
    setIsModalOpen(false);
  };

  return (
    <FlexBox flexDirection={"column"}>
      <FileUploadModal
        closeModal={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
        handleUpload={uploadFiles}
      />
      {documentSources.map((source) =>
        source.key === "docs" ? (
          <FlexBox key={source.key} my={1} flex={1}>
            <CustomSwitch
              checked={settings?.includes(source.key)}
              label={source.label}
              onChange={() => handleSettings(source.key)}
              icon={
                <SettingsIcon
                  arg={source.key}
                  isActive={settings?.includes(source.key)}
                />
              }
            >
              {settings?.includes(source.key) ? (
                <FlexBox mt={3} flexDirection={"column"} flex={1}>
                  {isLoading ? (
                    <FlexBox flex={1}>
                      <Text fontSize={0} fontWeight={"bold"} color={"text3"}>
                        {selectedFiles.length} Documents are uploading
                      </Text>
                    </FlexBox>
                  ) : (
                    <FlexBox>
                      <Text
                        fontSize={0}
                        fontWeight={"semibold"}
                        color={"text3"}
                      >
                        You have uploaded
                        <Text
                          fontSize={0}
                          fontWeight={"semibold"}
                          color={"text1"}
                        >
                          {" "}
                          {files.length} Documents{" "}
                        </Text>
                        as reference.
                      </Text>
                    </FlexBox>
                  )}
                  <FlexBox mt={3}>
                    <Button
                      label="View, Edit & Upload Files"
                      onClick={
                        isLoading ? () => null : () => setIsModalOpen(true)
                      }
                      background="#1E1E24"
                      icon={<ArrowCircleRight size={20} color="#ffffff" />}
                    />
                  </FlexBox>
                </FlexBox>
              ) : (
                <></>
              )}
            </CustomSwitch>
          </FlexBox>
        ) : (
          <FlexBox key={source.key} my={1} flex={1}>
            <CustomSwitch
              checked={settings?.includes(source.key)}
              label={source.label}
              onChange={() => handleSettings(source.key)}
              icon={
                <SettingsIcon
                  arg={source.key}
                  isActive={settings?.includes(source.key)}
                />
              }
            />
          </FlexBox>
        )
      )}
    </FlexBox>
  );
}

export default SourceSettings;
