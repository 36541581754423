import React from "react";
import Modal from "react-modal";

import { FlexBox, Text } from "mool-design/src";
import styled, { keyframes } from "styled-components";

interface Props {
  isModalOpen: boolean;
}

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    width: "40%", // Set the width to 80%
    height: "116px",
    transform: "translate(-50%, -5%)", // Center the modal horizontally
    padding: 0,
    backgroundColor: "#17171C",
    borderRadius: "20px",
    border: "0px",
    boxShadow: "0px 8px 16px 16px #0000006B",
  },
  overlay: {
    backgroundColor: "transparent",
  },
};

const StyledContainer = styled(FlexBox)`
  flex: 1;
  padding: 30px;
  border-radius: 20px;
  flex-direction: column;
`;

const progressAnimation = keyframes`
  0% {
    width: 0%;
  }
  90% {
    width: 90%;
  }
  100% {
    width: 90%; /* Remain at 90% */
  }
`;

const StyledProgressBar = styled(FlexBox)`
  width: 0;
  height: 12px;
  background-color: #4caf50;
  border-radius: 6px;
  animation: ${progressAnimation} 3s ease forwards; /* 3 seconds for demo */
`;

const StyledProgressBarContainer = styled(FlexBox)`
  border-radius: 8px;
  background-color: #000000;
  padding: 2px;
`;

function UploadLoaderModal({ isModalOpen }: Props): JSX.Element {
  return (
    <Modal isOpen={isModalOpen} style={customStyles}>
      <StyledContainer>
        <FlexBox>
          <Text fontSize={4} fontWeight={"bold"} color={"text1"}>
            File upload in progress
          </Text>
        </FlexBox>
        <StyledProgressBarContainer mt={4}>
          <StyledProgressBar />
        </StyledProgressBarContainer>
      </StyledContainer>
    </Modal>
  );
}

export default UploadLoaderModal;
