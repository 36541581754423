import { useContext } from "react";
import { MenuItem } from "shared/types";
import { mergeState, createStateContext } from "shared/utils/state-utils";

export const DOCUMENT_SOURCES = [
  { key: "web", label: "The Web" },
  { key: "data", label: "Public Markets Data" },
  { key: "docs", label: "Your Documents" },
];

export type ModuleState = {
  selectedProject: MenuItem | null;
  selectedTemplate: MenuItem | null;
  selectedDocumentSources: MenuItem[];
  files: File[];
};

export const InitialState: ModuleState = {
  selectedProject: null,
  selectedTemplate: null,
  selectedDocumentSources: DOCUMENT_SOURCES,
  files: [],
};

export const ModuleStateContext = createStateContext<ModuleState>(InitialState);

export function useModuleState(): [
  ModuleState,
  (newState: Partial<ModuleState>) => void
] {
  const { state, setState } = useContext(ModuleStateContext);

  const setModuleState = (newState: Partial<ModuleState>): void => {
    setState(mergeState<ModuleState>(state, newState));
  };

  return [state, setModuleState];
}
