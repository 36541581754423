import React from "react";
import { Pulse } from "@phosphor-icons/react";
import { FlexBox, Text } from "mool-design/src";
import styled from "styled-components";

const StyledText = styled(Text)`
  color: #635c1d;
  font-family: Roboto Mono;
  word-break: break-word; // Ensure long words break into a new line
  overflow-wrap: break-word; // Alternative way to wrap long text
  white-space: normal; // Prevent single-line text overflow
`;

function EmptyLogs() {
  return (
    <FlexBox
      alignItems={"center"}
      flex={1}
      justifyContent={"center"}
      flexDirection={"column"}
    >
      <FlexBox>
        <Pulse size={42} color="#4bb676" weight={"duotone"} />
      </FlexBox>

      <FlexBox
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <StyledText fontSize={1} fontWeight={"semibold"}>
          No logs available
        </StyledText>
        <StyledText
          style={{ color: "#6E6E7A" }}
          fontSize={0}
          textAlign={"center"}
        >
          Logs will begin appearing here once available
        </StyledText>
      </FlexBox>
    </FlexBox>
  );
}

export default EmptyLogs;
