import React, { useState } from "react";
import { Button, FlexBox, Input, View } from "mool-design/src";
import { useFirebaseLogin } from "shared/modules/auth-module/hooks";
import { LoadingComponent } from "../../components/loader";
import LoginPageLeft from "./login-page-left";
import {
  DividerText,
  GoogleLoginButton,
  LinkSentButton,
  LoginEmailPasswordButton,
  MagicLinkLoginButton,
  TertiaryLoginButton,
  TitleText,
} from "./login-button";
import { ArrowLeft } from "@phosphor-icons/react/dist/ssr";
import { Eye, EyeSlash } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

type ScreenState =
  | "login_email_password"
  | "signup_email_password"
  | "magic_link"
  | "forgot_password";

function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const [screenState, setScreenState] = useState<ScreenState>(
    "login_email_password"
  );

  const loginSuccessCallback = () => {
    navigate("/dashboard");
  };

  const {
    handleGoogleLogin,
    handleMagicLink,
    handleSignInWithEmailPassword,
    handleCreateUserWithEmailPassword,
    handleForgotPasswordLink,
    isLoading,
    signInErrorState,
    isMagicLinkSent,
    isForgotPasswordEmailSent,
    resetErrorState,
  } = useFirebaseLogin(loginSuccessCallback);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleSetEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetErrorState();
    const value = e.target.value;
    setEmail(value);
  };

  const handleSetPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetErrorState();
    const value = e.target.value;
    setPassword(value);
  };

  const handleSetPassword2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetErrorState();
    const value = e.target.value;
    setPassword2(value);
  };

  const handleContinue = () => {
    if (
      !(screenState === "magic_link" && isMagicLinkSent) &&
      !(screenState === "forgot_password" && isForgotPasswordEmailSent)
    ) {
      resetErrorState();
    }
    if (screenState === "login_email_password") {
      handleSignInWithEmailPassword({
        email,
        password,
      });
    } else if (screenState === "magic_link") {
      handleMagicLink({ email });
    } else if (screenState === "signup_email_password") {
      handleCreateUserWithEmailPassword({
        email,
        password,
      });
    } else if (screenState === "forgot_password") {
      handleForgotPasswordLink({ email });
    }
  };

  if (isLoading) {
    return (
      <FlexBox style={{ marginTop: 64 }}>
        <LoadingComponent />
      </FlexBox>
    );
  }

  return (
    <View position="fixed">
      <FlexBox flex={1} width={"100%"}>
        <LoginPageLeft />
        <FlexBox
          minWidth={"560px"}
          flex={2}
          style={{ backgroundColor: "#17171C" }}
        >
          <FlexBox
            alignItems={"center"}
            justifyContent={"center"}
            flex={1}
            px={31}
          >
            <FlexBox flexDirection={"column"} maxWidth={"306px"} flex={1}>
              {!isMagicLinkSent && !isForgotPasswordEmailSent && (
                <TitleText screenState={screenState} />
              )}
              <FlexBox mt={8}>
                {screenState === "magic_link" && isMagicLinkSent ? (
                  <LinkSentButton
                    email={email}
                    resend={handleContinue}
                    reset={() => {
                      resetErrorState();
                      setScreenState("magic_link");
                    }}
                  />
                ) : screenState === "forgot_password" &&
                  isForgotPasswordEmailSent ? (
                  <LinkSentButton
                    email={email}
                    resend={handleContinue}
                    reset={() => {
                      resetErrorState();
                      setScreenState("forgot_password");
                    }}
                    isForgotPassword
                  />
                ) : (
                  <Input
                    placeholderText="Enter email address"
                    value={email}
                    onChange={handleSetEmail}
                    label="Email Address"
                    type="email"
                    isError={
                      (signInErrorState?.type === "email_password_login" ||
                        signInErrorState?.type === "email_password_signup" ||
                        signInErrorState?.type === "magic_link" ||
                        signInErrorState?.type === "forgot_password") &&
                      signInErrorState?.isError === true
                    }
                    errorText={
                      signInErrorState?.type === "magic_link" ||
                      signInErrorState?.type === "forgot_password"
                        ? signInErrorState?.message
                        : ""
                    }
                  />
                )}
              </FlexBox>
              {(screenState === "login_email_password" ||
                screenState === "signup_email_password") && (
                <FlexBox mt={5}>
                  <Input
                    placeholderText="Enter password"
                    value={password}
                    onChange={handleSetPassword}
                    label="Password"
                    type={showPassword1 ? "text" : "password"}
                    isError={
                      (signInErrorState?.type === "email_password_login" ||
                        signInErrorState?.type === "email_password_signup") &&
                      signInErrorState?.isError === true
                    }
                    errorText={
                      signInErrorState?.type === "email_password_login"
                        ? signInErrorState?.message
                        : ""
                    }
                    icon={
                      showPassword1 ? (
                        <FlexBox
                          onClick={() => setShowPassword1(false)}
                          style={{ cursor: "pointer" }}
                          pr={2}
                        >
                          <Eye size={18} color={"#b9b9c7"} />
                        </FlexBox>
                      ) : (
                        <FlexBox
                          onClick={() => setShowPassword1(true)}
                          style={{ cursor: "pointer" }}
                          pr={2}
                        >
                          <EyeSlash size={18} color={"#b9b9c7"} />
                        </FlexBox>
                      )
                    }
                  />
                </FlexBox>
              )}
              {screenState === "login_email_password" && (
                <FlexBox>
                  <FlexBox>
                    <Button
                      label="Forgot Password?"
                      onClick={() => {
                        resetErrorState();
                        setScreenState("forgot_password");
                      }}
                      iconPlacement="right"
                      variant="tertiary"
                      size="sm"
                    />
                  </FlexBox>
                </FlexBox>
              )}
              {screenState === "signup_email_password" && (
                <FlexBox mt={5}>
                  <Input
                    placeholderText="Enter password again"
                    value={password2}
                    onChange={handleSetPassword2}
                    label="Re-enter Password"
                    type={showPassword2 ? "text" : "password"}
                    isError={
                      signInErrorState?.type === "email_password_signup" &&
                      signInErrorState?.isError === true
                    }
                    errorText={signInErrorState?.message}
                    icon={
                      showPassword2 ? (
                        <FlexBox
                          onClick={() => setShowPassword2(false)}
                          style={{ cursor: "pointer" }}
                          pr={2}
                        >
                          <Eye size={18} color={"#b9b9c7"} />
                        </FlexBox>
                      ) : (
                        <FlexBox
                          onClick={() => setShowPassword2(true)}
                          style={{ cursor: "pointer" }}
                          pr={2}
                        >
                          <EyeSlash size={18} color={"#b9b9c7"} />
                        </FlexBox>
                      )
                    }
                  />
                </FlexBox>
              )}
              {!isMagicLinkSent && !isForgotPasswordEmailSent && (
                <FlexBox mt={4}>
                  <Button
                    label={"Continue"}
                    onClick={handleContinue}
                    background="#635C1D"
                  />
                </FlexBox>
              )}
              {screenState === "forgot_password"
                ? !isForgotPasswordEmailSent && (
                    <FlexBox>
                      <Button
                        label="Back to sign-in"
                        onClick={() => {
                          resetErrorState();
                          setScreenState("login_email_password");
                        }}
                        iconPlacement="left"
                        variant="tertiary"
                        icon={<ArrowLeft color="#ffffff" size={18} />}
                      />
                    </FlexBox>
                  )
                : !isMagicLinkSent && (
                    <FlexBox flexDirection="column">
                      <FlexBox flexDirection={"column"}>
                        <FlexBox mt={8}>
                          <DividerText />
                        </FlexBox>
                        <FlexBox mt={5}>
                          <GoogleLoginButton
                            handleGoogleLogin={handleGoogleLogin}
                          />
                        </FlexBox>
                        <FlexBox>
                          {screenState === "magic_link" ? (
                            <FlexBox mt={5} flex={1}>
                              <LoginEmailPasswordButton
                                handleLoginEmailPassword={() => {
                                  resetErrorState();
                                  setScreenState("login_email_password");
                                }}
                              />
                            </FlexBox>
                          ) : (
                            screenState !== "signup_email_password" && (
                              <FlexBox mt={5} flex={1}>
                                <MagicLinkLoginButton
                                  handleMagicLink={() => {
                                    resetErrorState();
                                    setScreenState("magic_link");
                                  }}
                                />
                              </FlexBox>
                            )
                          )}
                        </FlexBox>
                      </FlexBox>
                      <FlexBox mt={3}>
                        <TertiaryLoginButton
                          screenState={screenState}
                          setScreenState={(arg: ScreenState) => {
                            resetErrorState();
                            setScreenState(arg);
                          }}
                        />
                      </FlexBox>
                    </FlexBox>
                  )}
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </View>
  );
}

export default LoginPage;
