import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";

const service = "auth/authenticate";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface RequestPayload {
  idToken: string;
}

export interface AuthResponse {
  data: {
    tokens: {
      access_token: string;
      refresh_token: string;
      access_token_expires_in: string;
      onboarding: "pending" | "completed";
    };
  };
}

export function useIdTokenAuthenticateApi(): UseMutationResult<
  AuthResponse,
  unknown,
  RequestPayload,
  unknown
> {
  return useMutation((payload: RequestPayload) => {
    return api.post<AuthResponse>(serviceUrl, payload);
  });
}
