export const TEMPLATE_MAP: { [key: string]: string } = {
  "investment-memo": "Investment Memo",
  "credit-proposal-executive-summary": "Credit Proposal Executive Summary",
  "credit-proposal-analyst-summary": "Credit Proposal Analyst Summary",
  "company-report": "Company Report",
  "industry-report": "Industry Report",
  tearsheet: "Tearsheet",
};

export const DROPDOWN_OPTIONS = [
  {
    key: "",
    label: "Select a Template",
  },
  {
    key: "investment-memo",
    label: "Investment Memo",
  },
  {
    key: "credit-proposal-executive-summary",
    label: "Credit Proposal Executive Summary",
  },
  {
    key: "credit-proposal-analyst-summary",
    label: "Credit Proposal Analyst Summary",
  },
  {
    key: "company-report",
    label: "Company Report",
  },
  {
    key: "industry-report",
    label: "Industry Report",
  },
  // {
  //   key: "tearsheet",
  //   label: "Tearsheet",
  // },
];

export const SUPPORTED_FORMATS = {
  "application/pdf": [".pdf"],
  "text/csv": [".csv"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "text/plain": [".txt"],
  "application/json": [".json"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
  "image/gif": [".gif"],
  "image/tiff": [".tiff", ".tif"],
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "image/bmp": [".bmp"],
  "image/webp": [".webp"],
};
