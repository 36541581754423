import React, { useState } from "react";
import { FlexBox, Text, View } from "mool-design/src";
import styled from "styled-components";
import SettingsDashboard from "./settings-dashboard";
import OrganisationMember from "./organisation-members";
import { useUserMe } from "shared/modules/user/user-me";

const StyledContainer = styled(FlexBox)`
  margin-top: 40px;
  min-width: 75vw;
  max-height: calc(100vh - 144px);
`;

const StyledMenuItemContainer = styled(FlexBox)<{ isSelected: boolean }>`
  &:hover {
    background-color: #17171c;
  }
  border-radius: 8px;
  flex: 1;
  justify-content: space-between;
  padding: 12px 16px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? "#17171C" : "transparent"};
`;

const StyledRightContainer = styled(FlexBox)`
  border-radius: 20px;
  padding: 20px;
  background-color: #17171c;
  flex-direction: column;
`;

const LEFT_PANE_OPTIONS = [
  { key: "dashboard", label: "Dashboard" },
  { key: "members", label: "Organisation Members" },
];

function AccountSettings() {
  const { user } = useUserMe();
  const [selectedTab, setSelectedTab] = useState("dashboard");

  const handleOptionClick = (arg: string) => {
    setSelectedTab(arg);
  };

  if (user?.type === "member") {
    return (
      <View position="fixed">
        <StyledContainer>
          <FlexBox flex={1}>
            <StyledRightContainer flex={1}>
              <SettingsDashboard handleOptionClick={handleOptionClick} />
            </StyledRightContainer>
          </FlexBox>
        </StyledContainer>
      </View>
    );
  }

  return (
    <View position="fixed">
      <StyledContainer>
        <FlexBox flexDirection={"column"}>
          <FlexBox>
            <Text fontSize={4} fontWeight={"bold"} color={"text1"}>
              Admin Console
            </Text>
          </FlexBox>
          <FlexBox mt={5} flexDirection={"column"}>
            {LEFT_PANE_OPTIONS.map((opt) => (
              <StyledMenuItemContainer
                isSelected={opt.key === selectedTab}
                key={opt.key}
                onClick={() => handleOptionClick(opt.key)}
                mb={1}
              >
                <Text fontSize={1} fontWeight={"bold"} color={"text1"}>
                  {opt.label}
                </Text>
              </StyledMenuItemContainer>
            ))}
          </FlexBox>
        </FlexBox>
        <FlexBox ml={3} flex={1}>
          <StyledRightContainer flex={1}>
            {selectedTab === "dashboard" && (
              <SettingsDashboard handleOptionClick={handleOptionClick} />
            )}
            {selectedTab === "members" && <OrganisationMember />}
          </StyledRightContainer>
        </FlexBox>
      </StyledContainer>
    </View>
  );
}

export default AccountSettings;
