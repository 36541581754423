import React, { useEffect } from "react";
import { FlexBox, Text } from "mool-design/src";
import { getMonthFullYearFormat } from "shared/utils/date-formatter";
import { CaretCircleRight, UsersThree } from "@phosphor-icons/react";
import { useUserMe } from "shared/modules/user/user-me";
import styled from "styled-components";
import { useOrganizationUsersList } from "shared/modules/account-settings/hooks/organization";

const NameIcon = styled(FlexBox)`
  background-color: #ffffff;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
`;

interface Props {
  handleOptionClick: (arg: string) => void;
}

function SettingsDashboard({ handleOptionClick }: Props) {
  const { org, user, orgInitial } = useUserMe();
  const { totalUsers, refreshOrganizationUsersList } =
    useOrganizationUsersList();

  useEffect(() => {
    refreshOrganizationUsersList();
  }, []);

  if (user?.type === "member") {
    return (
      <FlexBox flexDirection={"column"} flex={1}>
        <FlexBox>
          <Text fontSize={1} fontWeight={"bold"} color={"text1"}>
            Your organisation dashboard
          </Text>
        </FlexBox>
        <FlexBox flexDirection={"column"} mt={5}>
          <FlexBox alignItems={"center"}>
            <FlexBox mr={3}>
              <NameIcon>
                <Text fontWeight={"bold"} fontSize={7} color={"text3"}>
                  {orgInitial}
                </Text>
              </NameIcon>
            </FlexBox>
            <FlexBox flexDirection={"column"}>
              <FlexBox>
                <Text fontSize={4} fontWeight={"bold"} color={"text1"}>
                  {org?.name}
                </Text>
              </FlexBox>
              <FlexBox>
                <Text fontSize={1} fontWeight={"semibold"} color={"text3"}>
                  Member since{" "}
                  {getMonthFullYearFormat(user?.createdAt?.toString() || "")}
                </Text>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    );
  }

  return (
    <FlexBox flexDirection={"column"} flex={1}>
      <FlexBox>
        <Text fontSize={1} fontWeight={"bold"} color={"text1"}>
          Your organisation dashboard
        </Text>
      </FlexBox>
      <FlexBox flexDirection={"column"} mt={5}>
        <FlexBox alignItems={"center"}>
          <FlexBox mr={3}>
            <NameIcon>
              <Text fontWeight={"bold"} fontSize={7} color={"text3"}>
                {orgInitial}
              </Text>
            </NameIcon>
          </FlexBox>
          <FlexBox flexDirection={"column"}>
            <FlexBox>
              <Text fontSize={4} fontWeight={"bold"} color={"text1"}>
                {org?.name}
              </Text>
            </FlexBox>
            <FlexBox>
              <Text fontSize={1} fontWeight={"semibold"} color={"text3"}>
                Member since{" "}
                {getMonthFullYearFormat(user?.createdAt?.toString() || "")}
              </Text>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox flexDirection={"column"} mt={10}>
          <FlexBox alignItems={"center"} justifyContent={"space-between"}>
            <FlexBox alignItems={"center"}>
              <FlexBox mr={2}>
                <UsersThree size={24} color={"#FFE600"} weight="bold" />
              </FlexBox>
              <FlexBox flexDirection={"column"}>
                <FlexBox>
                  <Text fontSize={1} fontWeight={"bold"} color={"text1"}>
                    Members in your organisation
                  </Text>
                </FlexBox>
                <FlexBox>
                  <Text fontSize={1} fontWeight={"regular"} color={"text3"}>
                    Add, remove and change member permissions
                  </Text>
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox flexDirection={"column"}>
              <FlexBox>
                <Text fontSize={1} fontWeight={"bold"} color={"text1"}>
                  {totalUsers}
                </Text>
              </FlexBox>
              <FlexBox>
                <Text fontSize={1} fontWeight={"regular"} color={"text3"}>
                  Total members
                </Text>
              </FlexBox>
            </FlexBox>
            <FlexBox
              style={{ cursor: "pointer" }}
              onClick={() => handleOptionClick("members")}
            >
              <CaretCircleRight size={24} weight={"bold"} color={"#6E6E7A"} />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default SettingsDashboard;
