import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import { confirmAlert } from "react-confirm-alert"; // Import the confirmation dialog
import { Button, ConfirmPopup, FlexBox, Input, Text } from "mool-design/src";
import styled from "styled-components";
import {
  useGenerateDocumentInput,
  useProjectCreate,
  useProjectList,
} from "shared/modules/dashboard/hooks";
import { IGraphProject } from "shared/types";

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
}

const customStyles = {
  content: {
    top: "25%",
    left: "50%",
    width: "50%",
    maxWidth: "720px",
    height: "30%",
    transform: "translate(-50%, -5%)", // Center the modal horizontally
    padding: 0,
    backgroundColor: "#17171C",
    borderRadius: "20px",
    border: "0px",
    boxShadow: "0px 8px 16px 16px #0000006B",
  },
  overlay: {
    backgroundColor: "transparent",
  },
};

const StyledContainer = styled(FlexBox)`
  flex: 1;
  padding: 30px;
  border-radius: 20px;
  flex-direction: column;
  height: 88%;
`;

function ProjectCreateModal({ isModalOpen, closeModal }: Props): JSX.Element {
  const [projectName, setProjectName] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const { refreshProjectList } = useProjectList();
  const { setSelectedProject } = useGenerateDocumentInput();

  const successCallback = (project: IGraphProject) => {
    refreshProjectList();
    const selectedProject = {
      key: project._id,
      label: project.projectName,
    };
    setSelectedProject(selectedProject);
    setProjectName("");
    setIsDirty(false);
    closeModal();
  };

  const { handleCreateProject } = useProjectCreate(successCallback);

  const handleClose = () => {
    if (isDirty) {
      confirmAlert({
        overlayClassName: "custom-confirm-alert",
        customUI: ({ onClose }) => {
          return (
            <ConfirmPopup
              title="Are you sure you want to close the upload modal?"
              subTitle="You will lose the progress so far."
              closeModal={() => {
                setProjectName("");
                closeModal();
              }}
              onClose={onClose}
            />
          );
        },
      });
    } else {
      setProjectName("");
      closeModal();
    }
  };

  useEffect(() => {
    if (projectName) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [projectName]);

  const handleSetProjectName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setProjectName(value);
  };

  const projectCreateHandler = () => {
    handleCreateProject({
      projectName,
    });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      style={customStyles}
    >
      <StyledContainer>
        <FlexBox>
          <Text fontSize={4} fontWeight={"bold"} color={"text1"}>
            Create a new project
          </Text>
        </FlexBox>
        <FlexBox mt={2}>
          <Text fontSize={1} fontWeight={"regular"} color={"text3"}>
            This project will be associated with this document
          </Text>
        </FlexBox>
        <FlexBox flexDirection={"column"} justifyContent={"center"} flex={1}>
          <FlexBox>
            <Input
              placeholderText="Enter project name"
              value={projectName}
              onChange={handleSetProjectName}
            />
          </FlexBox>
          <FlexBox mt={5}>
            <Button
              label="Create Project"
              background="#1D1293"
              onClick={projectCreateHandler}
            />
          </FlexBox>
        </FlexBox>
      </StyledContainer>
    </Modal>
  );
}

export default ProjectCreateModal;
