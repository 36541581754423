import React, { useEffect, useRef, useState } from "react";
import FlexBox from "../../atoms/flexbox";
import Text from "../../atoms/text";
import styled from "styled-components";
import { CaretDown, Check } from "@phosphor-icons/react";
import Ellipsis from "../../atoms/ellipsis";

export interface MenuItem {
  key: string;
  label: string;
}

interface DefaultProps {
  label?: string;
  placeholder: string;
  variant: "default" | "radio";
  iconColor?: string;
  menuItems: MenuItem[];
  onClick: (arg: MenuItem) => void;
  selectedItem: MenuItem | null;
  outline?: string;
  maxHeight?: string;
  size?: "sm" | "md";
}
interface MultiselectProps {
  label?: string;
  placeholder: string;
  variant: "multiselect";
  iconColor?: string;
  menuItems: MenuItem[];
  onClick: (arg: MenuItem) => void;
  selectedItems: MenuItem[];
  outline?: string;
  maxHeight?: string;
  size?: "sm" | "md";
}

type Props = DefaultProps | MultiselectProps;

const StyledContainer = styled(FlexBox)<{
  outline: string;
  size?: "sm" | "md";
}>`
  padding: ${(props) => (props.size === "sm" ? "10px 12px" : "12px 16px")};
  border-radius: 10px;
  border: 1px solid ${(props) => props.outline};
  justify-content: space-between;
  algin-items: center;
  flex: 1;
  background-color: #1e1e24;
  cursor: pointer;
  position: relative;
  align-items: center;
`;

const StyledMenuContainer = styled(FlexBox)`
  z-index: 1000;
  position: absolute;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px #0000006b;
  background-color: #1e1e24;
  cursor: pointer;
  width: 100%; /* Make the dropdown menu full width */
  top: 100%; /* Position the menu directly below the container */
  left: 0; /* Align with the left edge of the container */
`;

const StyledMultiselectMenuContainer = styled(FlexBox)`
  z-index: 1000;
  position: absolute;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px #0000006b;
  background-color: #1e1e24;
  cursor: pointer;
  width: 100%; /* Make the dropdown menu full width */
  top: 100%; /* Position the menu directly below the container */
  left: 0; /* Align with the left edge of the container */
`;

const StyledMenuItemContainer = styled(FlexBox)`
  &:hover {
    background-color: #26262e;
  }
  border-radius: 8px;
  flex: 1;
  justify-content: space-between;
`;

const StyledCheckBox = styled(FlexBox)<{ isChecked: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: ${(props) => (props.isChecked ? "#00BC62" : "#3b3b47")};
  justify-content: center;
  align-items: center;
`;

function Dropdown({
  label,
  placeholder,
  menuItems,
  onClick,
  variant = "default",
  iconColor = "#3b3b47",
  outline = "#3b3b47",
  maxHeight = "100%",
  size = "md",
  ...rest
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleOpen = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <FlexBox flexDirection={"column"} flex={1}>
      {label && (
        <FlexBox mb={1}>
          <Text fontSize={1} color={"text1"} fontWeight={"bold"}>
            {label}
          </Text>
        </FlexBox>
      )}
      <StyledContainer
        onClick={handleOpen}
        outline={outline}
        ref={containerRef}
        size={size}
      >
        <FlexBox flex={1}>
          {variant === "multiselect" ? (
            <Ellipsis
              title={
                (rest as MultiselectProps).selectedItems.length > 0
                  ? `${(rest as MultiselectProps).selectedItems[0].label} ${
                      (rest as MultiselectProps).selectedItems.length > 1
                        ? `+${
                            (rest as MultiselectProps).selectedItems.length - 1
                          }`
                        : ""
                    }`
                  : placeholder
              }
            >
              <Text fontSize={2} color={"text1"} fontWeight={"regular"}>
                {(rest as MultiselectProps).selectedItems.length > 0
                  ? `${(rest as MultiselectProps).selectedItems[0].label} ${
                      (rest as MultiselectProps).selectedItems.length > 1
                        ? `+${
                            (rest as MultiselectProps).selectedItems.length - 1
                          }`
                        : ""
                    }`
                  : placeholder}
              </Text>
            </Ellipsis>
          ) : (
            <Ellipsis
              title={
                (rest as DefaultProps).selectedItem
                  ? (rest as DefaultProps).selectedItem?.label
                  : placeholder
              }
            >
              <Text
                fontSize={size === "md" ? 2 : 0}
                color={"text1"}
                fontWeight={"regular"}
              >
                {(rest as DefaultProps).selectedItem
                  ? (rest as DefaultProps).selectedItem?.label
                  : placeholder}
              </Text>
            </Ellipsis>
          )}
        </FlexBox>
        <FlexBox>
          <CaretDown size={size === "md" ? 24 : 18} color={iconColor} />
        </FlexBox>
        {isOpen &&
          (variant === "multiselect" ? (
            <StyledMultiselectMenuContainer mt={2} flexDirection={"column"}>
              <FlexBox
                flexDirection={"column"}
                style={{
                  maxHeight,
                  overflowY: "scroll",
                }}
              >
                {menuItems.map((item) => (
                  <StyledMenuItemContainer
                    p={3}
                    key={item.key}
                    onClick={() => onClick(item)}
                  >
                    <Ellipsis title={item?.label}>
                      <Text fontSize={2} fontWeight={"regular"} color={"text1"}>
                        {item.label}
                      </Text>
                    </Ellipsis>
                    <StyledCheckBox
                      isChecked={(rest as MultiselectProps).selectedItems.some(
                        (selectedItem) => selectedItem.key === item.key
                      )}
                    >
                      <Check size={14} color="white" />
                    </StyledCheckBox>
                  </StyledMenuItemContainer>
                ))}
              </FlexBox>
            </StyledMultiselectMenuContainer>
          ) : (
            <StyledMenuContainer mt={2} flexDirection={"column"}>
              <FlexBox
                flexDirection={"column"}
                style={{
                  maxHeight,
                  overflowY: "scroll",
                }}
              >
                {menuItems.map((item) => (
                  <StyledMenuItemContainer
                    py={size === "sm" ? 2 : 3}
                    px={3}
                    key={item.key}
                    onClick={() => {
                      onClick(item);
                      setIsOpen(false);
                    }}
                    alignItems={"center"}
                  >
                    <Ellipsis title={item?.label}>
                      <Text
                        fontSize={size === "sm" ? 0 : 2}
                        fontWeight={"regular"}
                        color={"text1"}
                      >
                        {item.label}
                      </Text>
                    </Ellipsis>
                    {(rest as DefaultProps).selectedItem?.key === item.key && (
                      <Check size={size === "sm" ? 12 : 14} color={"#FFE600"} />
                    )}
                  </StyledMenuItemContainer>
                ))}
              </FlexBox>
            </StyledMenuContainer>
          ))}
      </StyledContainer>
    </FlexBox>
  );
}

export default Dropdown;
