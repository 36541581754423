import { useFileListApi } from "shared/api/v3/enterprise/document/files/{id}/get";
import { useModuleState } from "../module-state";
import { useDocId } from "./index";
import { useDocumentFileUploadApi } from "shared/api/v3/enterprise/docgen/upload/post";
import {
  useDocGenOutputEditApi,
  EditRequestPayload,
} from "shared/api/v3/enterprise/docgen/edit/{id}/post";

export function useFileUploadInput() {
  const [{ files }, setModuleState] = useModuleState();

  const handleFileAdd = (arg: File[]): void => {
    if (!arg || arg.length <= 0) return;
    const updatedFiles = [...files, ...arg];
    setModuleState({ files: updatedFiles });
  };

  const handleFileRemove = (fileName: string): void => {
    const updatedFiles = files.filter((file) => file.name !== fileName);
    setModuleState({ files: updatedFiles });
  };

  const removeAllFiles = () => {
    setModuleState({ files: [] });
  };

  return {
    handleFileAdd,
    handleFileRemove,
    selectedFiles: files,
    removeAllFiles,
  };
}

export function useDocFileList() {
  const { docId } = useDocId();
  const [{ data, isLoading }, refreshFileList] = useFileListApi(docId);

  return {
    files: data?.data?.files || [],
    isLoading,
    refreshFileList,
  };
}

export function useDocumentFileUpload() {
  const { docId } = useDocId();
  const { mutate, isLoading } = useDocumentFileUploadApi();
  const { refreshFileList } = useDocFileList();
  const { selectedFiles, removeAllFiles } = useFileUploadInput();

  const handleDocumentFileUpload = () => {
    const payload = {
      docId: docId || "",
      files: selectedFiles,
      source: "enterprise",
    };
    mutate(payload, {
      onSuccess: () => {
        refreshFileList();
        removeAllFiles();
      },
    });
  };

  return {
    handleDocumentFileUpload,
    isLoading,
  };
}

export function useDataRoomOutputEdit(callback?: (text: string) => void) {
  const { mutate, isLoading } = useDocGenOutputEditApi();
  const { docId } = useDocId();

  const handleOutputEdit = (arg: Omit<EditRequestPayload, "docId">) => {
    const payload = {
      ...arg,
      docId: docId || "",
    };
    mutate(payload, {
      onSuccess: (response) => {
        if (callback) {
          callback(response.data.response);
        }
      },
    });
  };

  return { handleOutputEdit, isLoading };
}
