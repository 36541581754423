import React from "react";
import FlexBox from "../../atoms/flexbox";
import styled from "styled-components";
import Text from "../../atoms/text";

const StyledContainer = styled(FlexBox)<{
  borderColor: string;
  size: "sm" | "md" | "lg";
  backgroundColor: string;
}>`
  height: 28px;
  padding: ${(props) =>
    props.size === "sm"
      ? "0px 8px"
      : props.size === "md"
      ? "12px 16px"
      : "0px 8px"};
  border-radius: 10px;
  border: 1px solid ${(props) => props.borderColor};
  align-items: center;
  justify-content: space-between;
  flex: 1;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
`;

interface Props {
  label: string;
  key: string;
  isActive: boolean;
  offOutlineColor?: string;
  onOutlineColor?: string;
  onTextColor?: string;
  offTextColor?: string;
  icon?: React.ReactNode;
  onClick: () => void;
  iconPlacement?: "left" | "right";
  size?: "sm" | "md" | "lg";
  backgroundColor?: string;
}

function TabSelector({
  label,
  key,
  isActive,
  icon,
  onClick,
  offOutlineColor = "#1E1E24",
  onOutlineColor = "#635C1D",
  onTextColor = "text1",
  offTextColor = "text2",
  iconPlacement = "left",
  size = "sm",
  backgroundColor = "transparent",
}: Props): JSX.Element {
  return (
    <StyledContainer
      size={size}
      borderColor={isActive ? onOutlineColor : offOutlineColor}
      onClick={onClick}
      backgroundColor={backgroundColor}
    >
      <FlexBox
        alignItems={"center"}
        flexDirection={iconPlacement === "left" ? "row" : "row-reverse"}
      >
        {icon && (
          <FlexBox
            mr={iconPlacement === "left" ? 1 : 0}
            ml={iconPlacement === "left" ? 0 : 1}
          >
            {icon}
          </FlexBox>
        )}
        <Text
          fontSize={size === "sm" ? 0 : 1}
          fontWeight={isActive ? "bold" : "regular"}
          color={isActive ? onTextColor : offTextColor}
        >
          {label}
        </Text>
      </FlexBox>
    </StyledContainer>
  );
}

export default TabSelector;
