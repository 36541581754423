import React from "react";
import { BookOpenText, FileArrowUp, Sparkle } from "@phosphor-icons/react";
import { FlexBox, Text } from "mool-design/src";
import styled from "styled-components";

const StyledGradientBackground = styled(FlexBox)`
  width: 100vw;
  height: 100vh;
  background: radial-gradient(
      circle at 2% 98%,
      rgba(255, 230, 0, 0.25),
      transparent 40%
    ),
    radial-gradient(circle at 40% 98%, rgba(29, 18, 147, 0.35), transparent 80%);

  position: relative;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    background: transparent;
  }

  &:before {
    background-image: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 1px,
      transparent 1.5px
    );
    background-size: 600px 350px;
    opacity: 0.6;
  }
  &:after {
    background-image: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 1px,
      transparent 1.5px
    );
    background-size: 450px 700px;
    opacity: 0.6;
  }
  @media (max-width: 1100px) {
    display: none;
  }
`;

const StyledIconContainer = styled(FlexBox)`
  background-color: #1e1e24;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

function LoginPageLeft(): JSX.Element {
  return (
    <StyledGradientBackground flex={3}>
      <FlexBox alignItems={"center"} flex={1} px={31} maxWidth={"365px"}>
        <FlexBox flexDirection={"column"} flex={1}>
          <FlexBox alignItems={"center"}>
            <FlexBox>
              <Text fontSize={6} fontWeight={"regular"} color={"text1"}>
                welcome to
              </Text>
            </FlexBox>
            <FlexBox ml={2}>
              <Text fontSize={6} fontWeight={"bold"} color={"text1"}>
                mool
              </Text>
            </FlexBox>
          </FlexBox>
          <FlexBox>
            <Text fontSize={4} fontWeight={"regular"} color={"text2"}>
              Get days of work done in minutes
            </Text>
          </FlexBox>
          <FlexBox mt={6} flexDirection={"column"}>
            <FlexBox my={4} alignItems={"center"}>
              <StyledIconContainer p={2}>
                <BookOpenText size={32} color="#FFE600" />
              </StyledIconContainer>
              <FlexBox ml={5}>
                <Text fontSize={1} fontWeight={"regular"} color={"text2"}>
                  Generate professional documents with ready to use templates
                </Text>
              </FlexBox>
            </FlexBox>
            <FlexBox my={4} alignItems={"center"}>
              <StyledIconContainer p={2}>
                <Sparkle size={32} color="#FFE600" />
              </StyledIconContainer>
              <FlexBox ml={5}>
                <Text fontSize={1} fontWeight={"regular"} color={"text2"}>
                  Use explainable AI to automate research, analysis and editing
                </Text>
              </FlexBox>
            </FlexBox>
            <FlexBox my={4} alignItems={"center"}>
              <StyledIconContainer p={2}>
                <FileArrowUp size={32} color="#FFE600" />
              </StyledIconContainer>
              <FlexBox ml={5}>
                <Text fontSize={1} fontWeight={"regular"} color={"text2"}>
                  Refer to your files, the web and premium databases
                </Text>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </StyledGradientBackground>
  );
}

export default LoginPageLeft;
