import React from "react";
import FlexBox from "../../atoms/flexbox";
import styled from "styled-components";
import Text from "../../atoms/text";
import Switch, { ReactSwitchProps } from "react-switch";

const StyledContainer = styled(FlexBox)`
  padding: 12px 16px;
  border-radius: 10px;
  background-color: #17171c;
  flex: 1;
  flex-direction: column;
`;

const StyledSwitchContainer = styled(FlexBox)`
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

interface Props extends ReactSwitchProps {
  label: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}

function CustomSwitch({
  label,
  onChange,
  checked,
  icon,
  children,
  onColor = "#635C1D",
  offColor = "#26262E",
  offHandleColor = "#565661",
  height = 20,
  width = 36,
  handleDiameter = 18,
}: Props): JSX.Element {
  return (
    <StyledContainer>
      <StyledSwitchContainer>
        <FlexBox alignItems={"center"}>
          {icon && <FlexBox mr={1}>{icon}</FlexBox>}
          <Text fontSize={0} fontWeight={"bold"} color={"text1"}>
            {label}
          </Text>
        </FlexBox>
        <FlexBox>
          <Switch
            onChange={onChange}
            checked={checked}
            onColor={onColor}
            offColor={offColor}
            offHandleColor={offHandleColor}
            uncheckedIcon={false}
            checkedIcon={false}
            height={height}
            width={width}
            handleDiameter={handleDiameter}
          />
        </FlexBox>
      </StyledSwitchContainer>
      <FlexBox>{children && children}</FlexBox>
    </StyledContainer>
  );
}

export default CustomSwitch;
