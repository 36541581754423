import { useOrganizationUsersListApi } from "shared/api/v3/organization/users/get";
import {
  useUserRoleChangeApi,
  RoleChangeRequestPayload,
} from "shared/api/v3/organization/users/change-role/post";
import { useUserRemoveApi } from "shared/api/v3/organization/users/remove/post";
import { useUserBlockApi } from "shared/api/v3/organization/users/block/post";
import { useUserInvitationApi } from "shared/api/v3/organization/invite/post";
import { useUserMe } from "shared/modules/user/user-me";
import { useUserUnblockApi } from "shared/api/v3/organization/users/unblock/post";

export function useOrganizationUsersList() {
  const { isAdmin } = useUserMe();
  const [{ data, isLoading }, refreshOrganizationUsersList] =
    useOrganizationUsersListApi(isAdmin);

  return {
    members: data?.data?.users || [],
    isOwner: data?.data?.isOwner,
    isLoading,
    totalUsers: data?.data?.totalUsers,
    refreshOrganizationUsersList,
    invitations: data?.data?.invitations || [],
  };
}

export function useUserRoleChange(
  callback: (arg: string, text: string) => void
) {
  const { mutate, isLoading } = useUserRoleChangeApi();
  const { refreshOrganizationUsersList } = useOrganizationUsersList();

  const handleRoleChange = (payload: RoleChangeRequestPayload) => {
    mutate(payload, {
      onSuccess: () => {
        refreshOrganizationUsersList();
        callback("success", "Role changed successfully.");
      },
      onError: () => {
        callback("error", "Failed to change role.");
      },
    });
  };
  return {
    handleRoleChange,
    isLoading,
  };
}

export function useUserRemove(callback: (arg: string, text: string) => void) {
  const { mutate, isLoading } = useUserRemoveApi();
  const { refreshOrganizationUsersList } = useOrganizationUsersList();

  const handleUserRemove = (payload: { userIds: string[] }) => {
    mutate(payload, {
      onSuccess: () => {
        refreshOrganizationUsersList();
        callback("success", "User removed from your org permanently.");
      },
      onError: () => {
        callback("error", "Failed to remove user.");
      },
    });
  };
  return {
    handleUserRemove,
    isLoading,
  };
}

export function useUserBlock(callback: (arg: string, text: string) => void) {
  const { mutate, isLoading } = useUserBlockApi();
  const { refreshOrganizationUsersList } = useOrganizationUsersList();

  const handleUserBlock = (payload: { userIds: string[] }) => {
    mutate(payload, {
      onSuccess: () => {
        refreshOrganizationUsersList();
        callback("success", "User has been blocked from your org.");
      },
      onError: () => {
        callback("error", "Failed to block the user.");
      },
    });
  };
  return {
    handleUserBlock,
    isLoading,
  };
}

export function useUserUnblock(callback: (arg: string, text: string) => void) {
  const { mutate, isLoading } = useUserUnblockApi();
  const { refreshOrganizationUsersList } = useOrganizationUsersList();

  const handleUserUnblock = (payload: { userIds: string[] }) => {
    mutate(payload, {
      onSuccess: () => {
        refreshOrganizationUsersList();
        callback("success", "User has been unblocked from your org");
      },
      onError: () => {
        callback("error", "Failed to unblock the user");
      },
    });
  };
  return {
    handleUserUnblock,
    isLoading,
  };
}

export function useUserInvitation(callback: (arg: string) => void) {
  const { mutate, isLoading } = useUserInvitationApi();
  const { refreshOrganizationUsersList } = useOrganizationUsersList();

  const handleUserInvitation = (payload: { email: string; role: string }) => {
    mutate(payload, {
      onSuccess: () => {
        refreshOrganizationUsersList();
        callback("success");
      },
      onError: () => {
        callback("error");
      },
    });
  };
  return {
    handleUserInvitation,
    isLoading,
  };
}
