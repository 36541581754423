import { useEffect } from "react";
import { useUserMeApi } from "shared/api/v3/user/me/get";
import { useGlobalState } from "shared/global-state";

export function useUserMe() {
  const { globalState, setGlobalState } = useGlobalState();

  const [{ data, isLoading }] = useUserMeApi();

  useEffect(() => {
    if (data?.data?.user?._id) {
      setGlobalState({ user: data?.data?.user });
    }
  }, [JSON.stringify(data)]);

  const first_name =
    globalState.user?.name && globalState.user?.name.split(" ").length > 0
      ? globalState.user?.name?.split(" ")[0]
      : "";
  const last_name =
    globalState.user?.name && globalState.user?.name.split(" ").length > 1
      ? globalState.user?.name?.split(" ")[1]
      : "";

  const initials = `${
    first_name.length > 0 ? first_name[0]?.toUpperCase() : "U"
  }${last_name.length > 0 ? last_name[0]?.toUpperCase() : "U"}`;
  const orgInitial = data?.data?.org?.name
    ? data?.data?.org?.name?.charAt(0).toUpperCase()
    : "?";

  const isOrgMember = !!globalState.user?.orgId;
  const isAdmin = globalState?.user?.type === "admin";

  return {
    user: globalState.user,
    isLoading,
    fullName: globalState.user?.name,
    firstName: first_name,
    lastName: last_name,
    initials,
    onboarding: globalState?.user?.onboarding,
    org: data?.data?.org,
    isOrgMember,
    orgInitial,
    isAdmin,
  };
}
