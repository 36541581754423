import { useContext } from "react";
import { IGraphChat, IGraphLog } from "shared/types";
import { mergeState, createStateContext } from "shared/utils/state-utils";

export type ModuleState = {
  docId: string | null;
  settings: string[];
  chatHistory: IGraphChat[];
  files: File[];
  logs: IGraphLog[];
  showLog: boolean;
};

export const InitialState: ModuleState = {
  docId: null,
  settings: [],
  chatHistory: [],
  files: [],
  logs: [],
  showLog: false,
};

export const ModuleStateContext = createStateContext<ModuleState>(InitialState);

export function useModuleState(): [
  ModuleState,
  (newState: Partial<ModuleState>) => void
] {
  const { state, setState } = useContext(ModuleStateContext);

  const setModuleState = (newState: Partial<ModuleState>): void => {
    setState(mergeState<ModuleState>(state, newState));
  };

  return [state, setModuleState];
}
