import { FlexBox, Text } from "mool-design/src";
// import { useTypingEffect } from "shared/modules/editor/hooks";
import { IGraphLog } from "shared/types";
import { getFullDateLocalFormat } from "shared/utils/date-formatter";
import styled from "styled-components";
import LogContent from "./content";
import TokenUsage from "./token-usage";
import LogResponse from "./log-response";

const StyledLogText = styled(Text)`
  color: #4bb676;
  font-size: 11px;
  font-family: Roboto Mono;
  word-break: break-word; // Ensure long words break into a new line
  overflow-wrap: break-word; // Alternative way to wrap long text
  white-space: normal; // Prevent single-line text overflow
`;

const StyledText = styled(Text)`
  font-size: 11px;
  font-family: Roboto Mono;
  word-break: break-word; // Ensure long words break into a new line
  overflow-wrap: break-word; // Alternative way to wrap long text
  white-space: normal; // Prevent single-line text overflow
`;

interface Props {
  log: IGraphLog;
}

function GraphLogBody({ log }: Props): JSX.Element {
  // const taskText = useTypingEffect(log.task || '');
  // const contentText =  useTypingEffect(log.content || '');
  // const tokenUsageText = {
  //   completion: useTypingEffect(String(log?.tokenUsage?.completionTokens) || ''),
  //   prompt: useTypingEffect(String(log?.tokenUsage?.promptTokens) || ''),
  //   total: useTypingEffect(String(log?.tokenUsage?.totalTokens) || ''),
  // };

  if (log?.toolCalls?.length > 0) {
    return (
      <FlexBox flexDirection={"column"} mb={10}>
        <FlexBox flexDirection={"column"}>
          <FlexBox>
            <FlexBox>
              <Text color={"text3"} fontSize={0} fontWeight={"regular"}>
                {getFullDateLocalFormat(log.createdAt)}
              </Text>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox mt={1}>
          <StyledLogText fontWeight={"regular"}>
            <StyledText color={"text2"} fontWeight={"regular"}>
              [TASK]::
            </StyledText>
            {log.task}
          </StyledLogText>
        </FlexBox>
        {log.content && (
          <FlexBox mt={1}>
            <LogContent content={log?.content} />
          </FlexBox>
        )}
        <FlexBox flexDirection={"column"}>
          {log.toolCalls.map((tool) => (
            <FlexBox mt={1} key={tool.id}>
              <StyledLogText fontWeight={"regular"}>
                <StyledText color={"text2"} fontWeight={"regular"}>
                  [TOOL-CALL]::
                </StyledText>
                {tool.name}
                {tool.args.input ? `::${tool.args.input}` : ""}
              </StyledLogText>
            </FlexBox>
          ))}
        </FlexBox>
        <FlexBox mt={1}>
          <TokenUsage tokenUsage={log?.tokenUsage} />
        </FlexBox>
      </FlexBox>
    );
  }
  return (
    <FlexBox flexDirection={"column"} mb={10}>
      <FlexBox flexDirection={"column"}>
        <FlexBox>
          <FlexBox>
            <Text color={"text3"} fontSize={0} fontWeight={"regular"}>
              {getFullDateLocalFormat(log.createdAt)}
            </Text>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <FlexBox mt={1}>
        <StyledLogText fontWeight={"regular"}>
          <StyledText color={"text2"} fontWeight={"regular"}>
            [TASK]::
          </StyledText>
          {log.task}
        </StyledLogText>
      </FlexBox>
      <FlexBox flexDirection={"column"}>
        {log.toolUsage.map((tool) => (
          <FlexBox mt={1} key={tool.id} flexDirection={"column"}>
            <StyledLogText fontWeight={"regular"}>
              <StyledText color={"text2"} fontWeight={"regular"}>
                [TOOL-USAGE]::
              </StyledText>
              {tool.name}
              {tool.args.query ? `::${tool.args.query}` : ""}
            </StyledLogText>
            {tool.response && (
              <FlexBox mt={1}>
                <LogResponse response={tool.response} />
              </FlexBox>
            )}
          </FlexBox>
        ))}
      </FlexBox>
      {log.content && (
        <FlexBox mt={1}>
          <LogContent content={log?.content} />
        </FlexBox>
      )}
      <FlexBox mt={1}>
        <TokenUsage tokenUsage={log?.tokenUsage} />
      </FlexBox>
    </FlexBox>
  );
}

export default GraphLogBody;
