import { API_URL, API_VERSION_V3 } from "shared/network/config";
import { TEventHandlerFunc } from "shared/types";
import { useEffect } from "react";
import { CustomEventSource } from "shared/network/api/event-source";

const service = "enterprise/chat/stream";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface RequestPayload {
  docId: string;
  userPrompt: string;
}

export function useDocumentChatStreamApi(
  eventHandler: TEventHandlerFunc,
  payload: { docId: string; userPrompt: string }
) {
  useEffect(() => {
    // Create a new EventSource instance to connect to the server
    if (payload?.userPrompt) {
      const eventSource = new CustomEventSource(serviceUrl, { payload });

      eventSource.on("steps", (data) => {
        eventHandler(data);
      });

      eventSource.on("done", (data) => {
        eventHandler(data);
        eventSource.close();
      });

      return () => {
        eventSource.close();
        console.log("[CHAT-STREAM]::EventSource connection terminated");
      };
    }
  }, [payload.userPrompt]);
}
