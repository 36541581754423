import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoutes from "./private-routes";

import AuthLoader from "../modules/login/auth-loader";
import Dashboard from "../modules/dashboard";
import Editor from "../modules/editor";
import Onboarding from "../modules/onboarding";
import MagicLinkPage from "../modules/login/magic-link";
import ForgotPassword from "../modules/login/forgot-password";
import AccountSettings from "../modules/account-settings";
import InvitationPage from "../modules/login/invitation";

function AppRoutes(): JSX.Element {
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/onboard" element={<Onboarding />} />
        <Route path="/document/:doc_id" element={<Editor />} />
        <Route path="/account" element={<AccountSettings />} />
      </Route>
      <Route path="/login" element={<AuthLoader />} />
      <Route path="/magic_link" element={<MagicLinkPage />} />
      <Route path="/invitation" element={<InvitationPage />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
    </Routes>
  );
}

export default AppRoutes;
