import { space, width, maxWidth, textAlign } from "styled-system";
import cleanComponentProps from "../../../utils/cleanComponentProps";
import {
  SpaceProps,
  WidthProps,
  MaxWidthProps,
  TextAlignProps,
} from "../../../props";

export interface EllipsisProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    SpaceProps,
    WidthProps,
    MaxWidthProps,
    TextAlignProps {}

const Ellipsis = cleanComponentProps<EllipsisProps>("span", [
  ...(space.propNames ? space.propNames : []),
  ...(maxWidth.propNames ? maxWidth.propNames : []),
  ...(width.propNames ? width.propNames : []),
  ...(textAlign.propNames ? textAlign.propNames : []),
])`
  ${space}
  ${width}
  ${maxWidth}
  ${textAlign}
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  color: white;
`;

Ellipsis.defaultProps = {
  width: "90%",
};

Ellipsis.displayName = "Ellipsis";

export default Ellipsis;
