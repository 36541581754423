import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";
import { IGraphDocument } from "shared/types";

const service = "enterprise/document";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface RequestPayload {
  documentName?: string;
  fileString?: string;
  status?: string;
}

export interface CompressedRequestPayload {
  data: string;
}

export interface DocumentSaveResponse {
  data: {
    documentUpdate: IGraphDocument;
  };
}

export function useDocumentSaveApi(
  docId: string
): UseMutationResult<
  DocumentSaveResponse,
  unknown,
  CompressedRequestPayload,
  unknown
> {
  return useMutation((payload: CompressedRequestPayload) => {
    return api.post<DocumentSaveResponse>(
      `${serviceUrl}/${docId}/save`,
      payload
    );
  });
}
