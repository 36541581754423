import React, { useEffect, useState } from "react";
import { FlexBox, Text } from "mool-design/src";
import styled from "styled-components";

import ProjectCard from "./components/project-card";
import FileCard from "./components/file-card";
import { IGraphProjectDetail } from "shared/types";
import { useProjectList } from "shared/modules/dashboard/hooks";
import EmptyDocumentPlaceholder from "./components/empty-document-placeholder";

const StyledContainer = styled(FlexBox)`
  flex: 1;
  padding: 20px;
  border-radius: 20px;
  background-color: #17171c;
  height: calc(100vh - 395px);
`;

const StyledScrollContainer = styled(FlexBox)`
  max-height: 90%;
`;

const StyledScrollWrapper = styled(FlexBox)`
  flex-direction: column;
  overflow-y: scroll;
`;

function ProjectHistory(): JSX.Element {
  const { projects, refreshProjectList, isLoading } = useProjectList();

  const [selectedProject, setSelectedProject] =
    useState<null | IGraphProjectDetail>(null);

  useEffect(() => {
    if (!selectedProject) {
      const firstProject = projects[0];
      setSelectedProject(firstProject);
    } else {
      const project = projects.filter(
        (p) => p.project._id === selectedProject.project._id
      );
      if (project && project.length > 0) {
        setSelectedProject(project[0]);
      } else {
        const firstProject = projects[0];
        setSelectedProject(firstProject);
      }
    }
  }, [JSON.stringify(projects)]);

  useEffect(() => {
    refreshProjectList();

    const intervalId = setInterval(() => {
      refreshProjectList();
    }, 60000); // 60 seconds

    return () => clearInterval(intervalId);
  }, []);

  if (isLoading) {
    return <StyledContainer flexDirection={"column"} />;
  }

  if (projects?.length === 0) {
    return (
      <StyledContainer flexDirection={"column"}>
        <FlexBox alignItems={"center"} justifyContent={"center"} flex={1}>
          <EmptyDocumentPlaceholder
            text={
              "Your documents and projects will appear here once you create them."
            }
          />
        </FlexBox>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer flexDirection={"column"}>
      <FlexBox>
        <Text fontSize={4} fontWeight={"bold"} color={"text1"}>
          Your Files
        </Text>
      </FlexBox>
      <StyledScrollContainer height={"100%"} mt={5}>
        <StyledScrollWrapper className={"hide-scroll-bar"} flex={1} mr={5}>
          {projects?.map((projectDetail: IGraphProjectDetail) => (
            <FlexBox
              key={projectDetail.project?._id}
              onClick={() => setSelectedProject(projectDetail)}
            >
              <ProjectCard
                title={projectDetail.project?.projectName}
                subTitle={`${projectDetail.documentList?.length} ${
                  projectDetail.documentList?.length > 1
                    ? "Documents"
                    : "Document"
                }`}
                isSelected={
                  selectedProject?.project?._id === projectDetail?.project?._id
                }
              />
            </FlexBox>
          ))}
        </StyledScrollWrapper>
        <StyledScrollWrapper className={"hide-scroll-bar"} flex={4}>
          {selectedProject?.documentList &&
          selectedProject?.documentList?.length > 0 ? (
            selectedProject?.documentList?.map((document) => (
              <FileCard document={document} key={document._id} />
            ))
          ) : (
            <FlexBox alignItems={"center"} justifyContent={"center"} flex={1}>
              <EmptyDocumentPlaceholder
                text={"Your documents will appear here once you create them."}
              />
            </FlexBox>
          )}
        </StyledScrollWrapper>
      </StyledScrollContainer>
    </StyledContainer>
  );
}

export default ProjectHistory;
