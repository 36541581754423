import React, { useEffect, useState } from "react";

import { useAuth } from "shared/modules/auth-module";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { ApiResponse, JwtTokenData } from "shared/types";
import LoginPage from "./login-page";
import api from "shared/network/api";
import { LoadingComponent } from "../../components/loader";

const EXCLUDE_FROM_401 = ["logout"];

function getApiEndpoint(response: ApiResponse) {
  let endpoint = "";
  try {
    endpoint = response?.url?.split("/").reverse()[0];
  } catch (err) {
    //
  }
  return endpoint;
}

function AuthLoader(): JSX.Element {
  const navigate = useNavigate();

  const { setIsLoggedIn, logoutUser } = useAuth();
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  const handleLogout = () => {
    logoutUser();
    setIsAuthLoading(false);
  };

  const loginUser = () => {
    setIsLoggedIn(true);
    setIsAuthLoading(false);
    navigate("/dashboard");
  };

  const authInit = () => {
    api.registerInterceptor((response) => {
      if (response.status === 401) {
        const endpoint = getApiEndpoint(response);
        if (!EXCLUDE_FROM_401.includes(endpoint)) {
          handleLogout();
        }
      }
      return response;
    });
    const access_token_from_storage = localStorage.getItem("access_token");
    const isMigrated = localStorage.getItem("isMigrated");
    if (access_token_from_storage) {
      if (isMigrated !== "true") {
        handleLogout();
        localStorage.setItem("isMigrated", "true");
      } else {
        const decoded: JwtTokenData = jwt_decode(access_token_from_storage);
        const CURRENT_TIMESTAMP = Date.now() / 1000;
        const isTokenValid = decoded.exp - CURRENT_TIMESTAMP >= 60;
        if (isTokenValid) {
          loginUser();
        } else {
          // refresh token
          handleLogout();
        }
      }
    } else {
      localStorage.setItem("isMigrated", "true");
      setIsAuthLoading(false);
    }
  };

  useEffect(() => {
    authInit();
  }, []);

  const showLoader = isAuthLoading;

  return showLoader ? <LoadingComponent /> : <LoginPage />;
}

export default AuthLoader;
