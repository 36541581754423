import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";
import { IOrg, IUser } from "shared/types";

const service = "user/onboarding";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface RequestPayload {
  designation: string;
  purpose: string[];
  accountType: string;
  orgName: string;
  userName?: string;
}

export interface AuthResponse {
  data: {
    user: IUser;
    org: IOrg;
  };
}
export interface AuthErrorResponse {
  data: {
    error: {
      text: string;
    };
  };
}

export function useOnboardingApi(): UseMutationResult<
  AuthResponse,
  AuthErrorResponse,
  RequestPayload,
  unknown
> {
  return useMutation((payload: RequestPayload) => {
    return api.post<AuthResponse>(serviceUrl, payload);
  });
}
