import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";
import { IGraphProject } from "shared/types";

const service = "enterprise/project/create";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface RequestPayload {
  projectName: string;
}

export interface ProjectCreateResponse {
  data: {
    project: IGraphProject;
  };
}

export function useProjectCreateApi(): UseMutationResult<
  ProjectCreateResponse,
  unknown,
  RequestPayload,
  unknown
> {
  return useMutation((payload: RequestPayload) => {
    return api.post<ProjectCreateResponse>(serviceUrl, payload);
  });
}
