import React from "react";
import { Button, FlexBox, Text } from "mool-design/src";
import GoogleLogo from "theme/assets/images/google-logo-colored.png";
import AppleLogo from "theme/assets/images/apple-logo-white.png";
import EnvelopStar from "theme/assets/images/envelop-star.png";
import {
  MagicWand,
  Link,
  EnvelopeOpen,
  ArrowLeft,
} from "@phosphor-icons/react";
import styled from "styled-components";

const DividerContainer = styled(FlexBox)`
  display: flex;
  align-items: center;
  text-align: center;
`;

const Line = styled(FlexBox)`
  flex-grow: 1;
  height: 1px;
  background-color: #6e6e7a;
`;

export function GoogleLoginButton({
  handleGoogleLogin,
}: {
  handleGoogleLogin: () => void;
}): JSX.Element {
  return (
    <Button
      label="Continue with Google"
      onClick={handleGoogleLogin}
      background="#1D1293"
      icon={
        <img
          src={GoogleLogo}
          style={{
            height: 16,
            marginRight: 4,
          }}
          alt="Google"
        />
      }
      iconPlacement="left"
    />
  );
}

export function AppleLoginButton({
  handleAppleLogin,
}: {
  handleAppleLogin: () => void;
}): JSX.Element {
  return (
    <Button
      label="Continue with Apple"
      onClick={handleAppleLogin}
      background="#1D1293"
      icon={
        <img
          src={AppleLogo}
          style={{
            height: 16,
            marginRight: 4,
          }}
          alt="Apple"
        />
      }
      iconPlacement="left"
    />
  );
}

export function MagicLinkLoginButton({
  handleMagicLink,
}: {
  handleMagicLink: () => void;
}): JSX.Element {
  return (
    <Button
      label="Login with Magic Link"
      onClick={handleMagicLink}
      background="#1D1293"
      icon={
        <MagicWand
          size={18}
          weight="fill"
          color="#ffffff"
          style={{ marginRight: "4px" }}
        />
      }
      iconPlacement="left"
    />
  );
}

export function LoginEmailPasswordButton({
  handleLoginEmailPassword,
}: {
  handleLoginEmailPassword: () => void;
}): JSX.Element {
  return (
    <Button
      label="Login with password"
      onClick={handleLoginEmailPassword}
      background="#1D1293"
      iconPlacement="left"
      icon={
        <FlexBox mr={1}>
          <ArrowLeft size={16} color={"#ffffff"} weight="bold" />
        </FlexBox>
      }
    />
  );
}

export function TitleText({
  screenState,
}: {
  screenState: string;
}): JSX.Element {
  return (
    <FlexBox>
      {screenState === "forgot_password" ? (
        <FlexBox>
          <Text fontSize={4} fontWeight={"regular"} color={"text1"}>
            Forgot{" "}
            <Text fontSize={4} fontWeight={"regular"} color={"brand"}>
              password
            </Text>
          </Text>
        </FlexBox>
      ) : screenState === "magic_link" ? (
        <FlexBox>
          <Text fontSize={4} fontWeight={"regular"} color={"text1"}>
            Sign in with{" "}
            <Text fontSize={4} fontWeight={"regular"} color={"brand"}>
              Magic Link
            </Text>
          </Text>
        </FlexBox>
      ) : screenState === "signup_email_password" ? (
        <FlexBox>
          <Text fontSize={4} fontWeight={"regular"} color={"text1"}>
            Lets get you{" "}
            <Text fontSize={4} fontWeight={"regular"} color={"brand"}>
              started
            </Text>
          </Text>
        </FlexBox>
      ) : (
        <FlexBox>
          <Text fontSize={4} fontWeight={"regular"} color={"text1"}>
            Sign in to{" "}
            <Text fontSize={4} fontWeight={"regular"} color={"brand"}>
              mool
            </Text>
          </Text>
        </FlexBox>
      )}
    </FlexBox>
  );
}

export function TertiaryLoginButton({
  screenState,
  setScreenState,
}: {
  screenState: string;
  setScreenState: (arg: string) => void;
}): JSX.Element {
  return (
    <FlexBox flex={1} alignItems={"center"} justifyContent={"center"}>
      <FlexBox mr={1}>
        {screenState === "signup_email_password" ? (
          <Text fontSize={1} fontWeight={"regular"} color={"text1"}>
            Already have an account?
          </Text>
        ) : (
          <Text fontSize={1} fontWeight={"regular"} color={"text1"}>
            Don't have an account?
          </Text>
        )}
      </FlexBox>
      <FlexBox>
        {screenState === "signup_email_password" ? (
          <Button
            label="Login now"
            onClick={() => setScreenState("login_email_password")}
            iconPlacement="right"
            variant="tertiary"
          />
        ) : (
          <Button
            label="Sign up"
            onClick={() => setScreenState("signup_email_password")}
            iconPlacement="right"
            variant="tertiary"
          />
        )}
      </FlexBox>
    </FlexBox>
  );
}

export function DividerText(): JSX.Element {
  return (
    <DividerContainer flex={1}>
      <Line />
      <FlexBox mx={1}>
        <Text fontSize={0} color={"text2"}>
          OR
        </Text>
      </FlexBox>
      <Line />
    </DividerContainer>
  );
}

export function LinkSentButton({
  email,
  resend,
  reset,
  isForgotPassword,
}: {
  email: string;
  resend: () => void;
  reset: () => void;
  isForgotPassword?: boolean;
}): JSX.Element {
  return (
    <FlexBox
      flex={1}
      alignItems={"center"}
      justifyContent={"center"}
      p={2}
      flexDirection={"column"}
    >
      <FlexBox>
        {isForgotPassword ? (
          <EnvelopeOpen size={56} color="#ffffff" />
        ) : (
          <img
            src={EnvelopStar}
            style={{
              height: 56,
            }}
            alt="EnvelopStar"
          />
        )}
      </FlexBox>
      <FlexBox mt={5}>
        <Text
          fontSize={4}
          fontWeight={"bold"}
          color={"text1"}
          textAlign={"center"}
        >
          {isForgotPassword
            ? "We have emailed your password reset link."
            : "We have emailed your magic login link."}
        </Text>
      </FlexBox>
      <FlexBox mt={5}>
        <Text
          fontSize={1}
          fontWeight={"semibold"}
          color={"text1"}
          textAlign={"center"}
        >
          Check your email at {email}. Still not received it?
        </Text>
      </FlexBox>
      <FlexBox mt={10}>
        <Button
          label={"Send link again"}
          onClick={resend}
          background="#635C1D"
          icon={<Link size={18} color="#ffffff" />}
        />
      </FlexBox>

      <FlexBox mt={10}>
        <FlexBox flex={1} alignItems={"center"} justifyContent={"center"}>
          <FlexBox>
            <Text
              fontSize={1}
              fontWeight={"semibold"}
              color={"text2"}
              textAlign={"center"}
            >
              <Text
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={reset}
              >
                Click here
              </Text>{" "}
              {isForgotPassword
                ? "to change email address."
                : "to change email address or login with socials."}
            </Text>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}
