import React from "react";
import { CaretDown, DotsThreeVertical } from "@phosphor-icons/react";
import { FlexBox, OptionWrapper, Text } from "mool-design/src";
import styled from "styled-components";
import {
  useOrganizationUsersList,
  useUserBlock,
  useUserRemove,
  useUserRoleChange,
  useUserUnblock,
} from "shared/modules/account-settings/hooks/organization";
import { getFullDateLocalFormatWithTime } from "shared/utils/date-formatter";
import { IUser } from "shared/types";
import { toastError, toastSuccess } from "../../utils/custom-toast";

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.tr`
  text-align: left;
  th {
    background-color: transparent;
    border: 0px;
  }
  &:hover {
    background-color: transparent;
  }
`;

const TableRow = styled.tr`
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    box-shadow: 4px 4px 16px 0px #0000006b;
  }
  td {
    background-color: transparent;
    border: 0px;
  }
`;

const ROLE_MAP = {
  member: "Member",
  admin: "Admin",
};

function MemberTable() {
  const { members } = useOrganizationUsersList();

  const callback = (type: string, text: string) => {
    if (type === "success") {
      toastSuccess(text);
    }
    if (type === "error") {
      toastError(text);
    }
  };

  const { handleUserBlock } = useUserBlock(callback);
  const { handleUserUnblock } = useUserUnblock(callback);
  const { handleUserRemove } = useUserRemove(callback);
  const { handleRoleChange } = useUserRoleChange(callback);

  const handlePermissionUpdate = (member: IUser, permission: string) => {
    const payload = {
      userId: member._id,
      role: permission,
    };
    handleRoleChange(payload);
  };

  const handleUserAction = (member: IUser, action: string) => {
    const payload = {
      userIds: [member._id],
    };
    if (action === "block") {
      handleUserBlock(payload);
    } else if (action === "remove") {
      handleUserRemove(payload);
    } else if (action === "unblock") {
      handleUserUnblock(payload);
    }
  };

  return (
    <StyledTable>
      <thead>
        <TableHeader>
          <th>
            <Text fontSize={0} fontWeight={"regular"} color={"text3"}>
              MEMBER NAME
            </Text>
          </th>
          <th>
            <Text fontSize={0} fontWeight={"regular"} color={"text3"}>
              MEMBER SINCE
            </Text>
          </th>
          <th>
            <Text fontSize={0} fontWeight={"regular"} color={"text3"}>
              ROLE
            </Text>
          </th>
          <th>
            <Text fontSize={0} fontWeight={"regular"} color={"text3"}>
              STATUS
            </Text>
          </th>
          <th></th>
        </TableHeader>
      </thead>
      <tbody>
        {members.map((member) => (
          <TableRow key={member._id}>
            <td>
              <FlexBox flexDirection={"column"}>
                <FlexBox>
                  <Text
                    fontSize={1}
                    fontWeight={"semibold"}
                    color={member?.isActive ? "text1" : "text3"}
                  >
                    {member.name}
                  </Text>
                </FlexBox>
                <FlexBox>
                  <Text fontSize={0} fontWeight={"regular"} color={"text3"}>
                    {member.email}
                  </Text>
                </FlexBox>
              </FlexBox>
            </td>
            <td>
              <FlexBox>
                <Text
                  color={member?.isActive ? "text1" : "text3"}
                  fontSize={0}
                  fontWeight={"regular"}
                >
                  {getFullDateLocalFormatWithTime(member.createdAt.toString())}
                </Text>
              </FlexBox>
            </td>
            <td>
              <FlexBox>
                <OptionWrapper
                  menuItems={
                    member?.isActive
                      ? [
                          { key: "member", label: "Member" },
                          { key: "admin", label: "Admin" },
                        ]
                      : []
                  }
                  onClick={(arg) => handlePermissionUpdate(member, arg.key)}
                  size={"sm"}
                >
                  <FlexBox alignItems={"center"}>
                    <FlexBox mr={1}>
                      <Text
                        color={member?.isActive ? "text1" : "text3"}
                        fontSize={0}
                        fontWeight={"regular"}
                      >
                        {ROLE_MAP[member.type] || member.type}
                      </Text>
                    </FlexBox>
                    <FlexBox>
                      {member?.isActive && (
                        <CaretDown size={14} color={"#635C1D"} />
                      )}
                    </FlexBox>
                  </FlexBox>
                </OptionWrapper>
              </FlexBox>
            </td>
            <td>
              <FlexBox>
                {member?.isActive ? (
                  <FlexBox>
                    <Text color="text1" fontSize={0} fontWeight={"regular"}>
                      Active
                    </Text>
                  </FlexBox>
                ) : (
                  <FlexBox>
                    <Text color="error" fontSize={0} fontWeight={"regular"}>
                      Blocked
                    </Text>
                  </FlexBox>
                )}
              </FlexBox>
            </td>
            <td>
              <FlexBox>
                <OptionWrapper
                  menuItems={
                    member?.isActive
                      ? [
                          { key: "block", label: "Block User" },
                          { key: "remove", label: "Remove User" },
                        ]
                      : [
                          { key: "unblock", label: "Unblock User" },
                          { key: "remove", label: "Remove User" },
                        ]
                  }
                  onClick={(arg) => handleUserAction(member, arg.key)}
                  size={"sm"}
                >
                  <DotsThreeVertical color="#ffffff" size={20} />
                </OptionWrapper>
              </FlexBox>
            </td>
          </TableRow>
        ))}
      </tbody>
    </StyledTable>
  );
}

export default MemberTable;
