import React, { useEffect, useRef, useState } from "react";
import { Button, FlexBox, Spinner, Text } from "mool-design/src";
import styled, { keyframes } from "styled-components";
import {
  ArrowLineRight,
  ArrowUp,
  Code,
  Broadcast,
} from "@phosphor-icons/react";
import {
  useGraphLogs,
  useGraphLogsStream,
  useShowLog,
} from "shared/modules/editor/hooks/logs";
import GraphLogBody from "./log-body";
import Switch from "react-switch";
import EmptyLogs from "./empty-logs";

const StyledWrapper = styled(FlexBox)<{ width?: string }>`
  height: calc(100vh - 100px);
  align-self: flex-end;
  max-width: 320px;
`;

const StyledContainer = styled(FlexBox)<{ display: string }>`
  border-radius: 10px 0px 0px 10px;
  background-color: #17171c;
  flex-direction: column;
  padding: 12px 4px 12px 20px;
  display: ${(props) => props.display || "flex"};
  height: 100%;
  overflow: scroll;
`;

// Define keyframes for the pulsing shadow effect
const heartbeatAnimation = keyframes`
  0% {
    box-shadow: 0 0 1px 1px rgba(255, 255, 0, 0.7); // Start with no shadow
  }
  50% {
    box-shadow: 0 0 4px 4px rgba(255, 255, 0, 0.5); // Expand the shadow
  }
  100% {
    box-shadow: 0 0 1px 1px rgba(255, 255, 0, 0.7); // Shrink back to original
  }
`;

// StyledIconContainer with animated shadow
const StyledIconContainer = styled(FlexBox)`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; // Ensure the container is circular if desired
  animation: ${heartbeatAnimation} 1.8s ease-in-out infinite; // Adjust timing for heartbeat effect
`;

function LogsUi(): JSX.Element {
  const { showLog, toggleShowLog } = useShowLog();
  const [isLiveMode, setIsLiveMode] = useState(true);
  const logsContainerRef = useRef<HTMLDivElement>(null);
  const { logs, hasNextPage, fetchNextPage, isFetchingNextPage, refreshLogs } =
    useGraphLogs();
  const { logStream } = useGraphLogsStream();

  const toggleLiveMode = () => {
    if (isLiveMode) {
      refreshLogs();
    }
    setIsLiveMode((prev) => !prev);
  };

  useEffect(() => {
    if (showLog && logsContainerRef.current && isLiveMode) {
      logsContainerRef.current.scrollTo({
        top: logsContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [logs, showLog, isLiveMode]);

  useEffect(() => {
    if (showLog && logsContainerRef.current && !isLiveMode) {
      logsContainerRef.current.scrollTo({
        top: logsContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [showLog, isLiveMode]);

  return (
    <StyledWrapper flexDirection={"column"}>
      <FlexBox
        style={{
          backgroundColor: "#1D1293",
          minWidth: "80px",
          borderRadius: "10px 0px 0px 10px",
        }}
        justifyContent={"space-between"}
      >
        <FlexBox>
          {showLog ? (
            <Button
              label={"Close"}
              onClick={toggleShowLog}
              icon={<ArrowLineRight size={16} color="#ffffff" weight="bold" />}
              background="#1D1293"
              iconPlacement="right"
              size={"sm"}
            />
          ) : (
            <Button
              label={"Logs"}
              onClick={toggleShowLog}
              icon={<Code size={16} color="#ffffff" weight="bold" />}
              background="#1D1293"
              iconPlacement="left"
              size={"sm"}
            />
          )}
        </FlexBox>
        {showLog && (
          <FlexBox
            mr={2}
            style={{ cursor: "pointer" }}
            onClick={toggleLiveMode}
          >
            <FlexBox alignItems={"center"}>
              {isLiveMode ? (
                <StyledIconContainer mr={2}>
                  <Broadcast color={"#FFE600"} size={16} weight={"fill"} />
                </StyledIconContainer>
              ) : (
                <FlexBox mr={2}>
                  <Broadcast color={"#ffffff"} size={16} weight={"regular"} />
                </FlexBox>
              )}
              <Text
                fontSize={0}
                fontWeight={"semibold"}
                color={isLiveMode ? "brand" : "text1"}
              >
                Live mode
              </Text>
              <FlexBox ml={2}>
                <Switch
                  onChange={() => null}
                  checked={isLiveMode}
                  onColor="#635C1D"
                  offColor="#26262E"
                  offHandleColor="#B9B9C7"
                  onHandleColor="#FFE600"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={12}
                  width={28}
                  handleDiameter={12}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        )}
      </FlexBox>
      <StyledContainer
        ref={logsContainerRef}
        className={"hide-scroll-bar"}
        flex={1}
        mt={2}
        display={showLog ? "flex" : "none"}
      >
        {isLiveMode ? (
          <FlexBox flexDirection={"column"} flex={1}>
            {logStream.length > 0 ? (
              logStream.map((log) => (
                <FlexBox key={log._id}>
                  <GraphLogBody log={log} />
                </FlexBox>
              ))
            ) : (
              <FlexBox flex={1}>
                <EmptyLogs />
              </FlexBox>
            )}
          </FlexBox>
        ) : (
          <FlexBox flexDirection={"column"} flex={1}>
            {hasNextPage && (
              <FlexBox style={{ marginTop: -16 }}>
                <Button
                  label={"Load more logs"}
                  onClick={isFetchingNextPage ? () => null : fetchNextPage}
                  size={"sm"}
                  variant="tertiary"
                  textColor="text2"
                  icon={
                    isFetchingNextPage ? (
                      <FlexBox>
                        <Spinner
                          size={10}
                          color={"#00BC62"}
                          spinColor={"#3B3B47"}
                          borderSize={2}
                        />
                      </FlexBox>
                    ) : (
                      <ArrowUp size={14} color={"#B9B9C7"} weight="bold" />
                    )
                  }
                  iconPlacement="left"
                />
              </FlexBox>
            )}
            {logs.length > 0 ? (
              logs.map((log) => (
                <FlexBox key={log._id}>
                  <GraphLogBody log={log} />
                </FlexBox>
              ))
            ) : (
              <FlexBox flex={1}>
                <EmptyLogs />
              </FlexBox>
            )}
          </FlexBox>
        )}
      </StyledContainer>
    </StyledWrapper>
  );
}

export default LogsUi;
