import React from "react";
import FlexBox from "../../atoms/flexbox";
import styled from "styled-components";
import Text from "../../atoms/text";

interface Props {
  label: string;
  variant?: "primary" | "secondary" | "tertiary";
  onClick: () => void;
  background?: string;
  textColor?: string;
  icon?: React.ReactNode;
  iconPlacement?: "left" | "right";
  size?: "md" | "sm" | "lg";
}
const StyledGradientContainer = styled(FlexBox)`
  padding: 12px 24px;
  border-radius: 10px;
  background: linear-gradient(93.26deg, #1d1293 0%, #736b1d 99.68%);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 1;
`;

const StyledPrimaryContainer = styled(FlexBox)<{
  background: string;
  size?: "sm" | "md" | "lg";
}>`
  padding: ${(props) => (props.size === "sm" ? "6px 8px" : "12px 24px")};
  border-radius: 10px;
  background-color: ${(props) => props.background};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 1;
`;

const StyledTertiaryContainer = styled(FlexBox)`
  padding: 12px 0px;
  border-radius: 10px;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 1;
`;

function Button({
  label,
  background = "transparent",
  onClick,
  textColor = "text1",
  icon,
  iconPlacement = "right",
  variant = "primary",
  size = "md",
}: Props): JSX.Element {
  if (variant === "primary") {
    if (background === "gradient") {
      return (
        <StyledGradientContainer onClick={onClick}>
          <Text fontSize={1} fontWeight={"bold"} color={textColor}>
            {label}
          </Text>
        </StyledGradientContainer>
      );
    }
    if (size === "sm") {
      return (
        <StyledPrimaryContainer
          size={size}
          background={background}
          onClick={onClick}
        >
          <FlexBox
            alignItems={"center"}
            flexDirection={iconPlacement === "left" ? "row" : "row-reverse"}
          >
            {icon && (
              <FlexBox
                mr={label ? (iconPlacement === "left" ? 1 : 0) : 0}
                ml={label ? (iconPlacement === "left" ? 0 : 1) : 0}
              >
                {icon}
              </FlexBox>
            )}
            <Text fontSize={0} fontWeight={"bold"} color={textColor}>
              {label}
            </Text>
          </FlexBox>
        </StyledPrimaryContainer>
      );
    }
    return (
      <StyledPrimaryContainer background={background} onClick={onClick}>
        <FlexBox
          alignItems={"center"}
          flexDirection={iconPlacement === "left" ? "row" : "row-reverse"}
        >
          {icon && (
            <FlexBox
              mr={label ? (iconPlacement === "left" ? 1 : 0) : 0}
              ml={label ? (iconPlacement === "left" ? 0 : 1) : 0}
            >
              {icon}
            </FlexBox>
          )}
          <Text fontSize={1} fontWeight={"bold"} color={textColor}>
            {label}
          </Text>
        </FlexBox>
      </StyledPrimaryContainer>
    );
  } else if (variant === "tertiary") {
    if (size === "sm") {
      return (
        <StyledTertiaryContainer onClick={onClick}>
          <FlexBox
            alignItems={"center"}
            flexDirection={iconPlacement === "left" ? "row" : "row-reverse"}
          >
            {icon && (
              <FlexBox
                mr={label ? (iconPlacement === "left" ? 1 : 0) : 0}
                ml={label ? (iconPlacement === "left" ? 0 : 1) : 0}
              >
                {icon}
              </FlexBox>
            )}
            <Text fontSize={1} fontWeight={"regular"} color={textColor}>
              {label}
            </Text>
          </FlexBox>
        </StyledTertiaryContainer>
      );
    }
    return (
      <StyledTertiaryContainer onClick={onClick}>
        <FlexBox
          alignItems={"center"}
          flexDirection={iconPlacement === "left" ? "row" : "row-reverse"}
        >
          {icon && (
            <FlexBox
              mr={label ? (iconPlacement === "left" ? 1 : 0) : 0}
              ml={label ? (iconPlacement === "left" ? 0 : 1) : 0}
            >
              {icon}
            </FlexBox>
          )}
          <Text fontSize={1} fontWeight={"bold"} color={textColor}>
            {label}
          </Text>
        </FlexBox>
      </StyledTertiaryContainer>
    );
  }

  return (
    <StyledPrimaryContainer background={background} onClick={onClick}>
      <FlexBox
        alignItems={"center"}
        flexDirection={iconPlacement === "left" ? "row" : "row-reverse"}
      >
        {icon && (
          <FlexBox
            mr={label ? (iconPlacement === "left" ? 1 : 0) : 0}
            ml={label ? (iconPlacement === "left" ? 0 : 1) : 0}
          >
            {icon}
          </FlexBox>
        )}
        <Text fontSize={1} fontWeight={"bold"} color={textColor}>
          {label}
        </Text>
      </FlexBox>
    </StyledPrimaryContainer>
  );
}

export default Button;
