import React, { useState } from "react";
import { Button, FlexBox, Text } from "mool-design/src";
import MemberTable from "./member-table";
import styled from "styled-components";
import { UserCirclePlus } from "@phosphor-icons/react";
import InviteUserModal from "./invite-user-modal";
import InvitationTable from "./invitation-table";

const StyledTableContainer = styled(FlexBox)`
  margin-top: 20px;
  max-height: 75vh;
  overflow: scroll;
`;

const StyledTabSelector = styled(FlexBox)`
  border-radius: 12px;
  padding: 4px;
  background-color: #000000;
  flex: 1;
`;

function OrganisationMember() {
  const [activeTab, setActiveTab] = useState("existing");
  const [showInviteModal, setShowInviteModal] = useState(false);

  const openModal = () => {
    setShowInviteModal(true);
  };
  const closeModal = () => {
    setShowInviteModal(false);
  };

  return (
    <FlexBox flexDirection={"column"} flex={1}>
      <InviteUserModal closeModal={closeModal} isModalOpen={showInviteModal} />
      <FlexBox justifyContent={"space-between"} alignItems={"center"}>
        <FlexBox>
          <Text fontSize={1} fontWeight={"bold"} color={"text1"}>
            Organisation Members
          </Text>
        </FlexBox>
        <FlexBox>
          <Button
            label="Invite User"
            background="#1D1293"
            onClick={openModal}
            icon={
              <FlexBox>
                <UserCirclePlus size={22} color="#ffffff" />
              </FlexBox>
            }
          />
        </FlexBox>
      </FlexBox>

      <FlexBox flexDirection={"column"}>
        <FlexBox mt={5}>
          <StyledTabSelector>
            <FlexBox flex={1} mr={1}>
              <Button
                background={activeTab === "existing" ? "#111114" : "#000000"}
                label="Existing Members"
                onClick={() => setActiveTab("existing")}
              />
            </FlexBox>
            <FlexBox flex={1} ml={1}>
              <Button
                background={activeTab === "invitation" ? "#111114" : "#000000"}
                label="Invitations"
                onClick={() => setActiveTab("invitation")}
              />
            </FlexBox>
          </StyledTabSelector>
        </FlexBox>
        <StyledTableContainer>
          {activeTab === "existing" && <MemberTable />}
          {activeTab === "invitation" && <InvitationTable />}
        </StyledTableContainer>
      </FlexBox>
    </FlexBox>
  );
}

export default OrganisationMember;
