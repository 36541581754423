import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import api from "shared/network/api";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import { IUser, IUserInvitation } from "shared/types";

const service = "organization/users";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

interface OrganizationUsersResponse {
  data: {
    isOwner: boolean;
    users: IUser[];
    invitations: IUserInvitation[];
    totalUsers: number;
  };
}

export function useOrganizationUsersListApi(
  isAdmin: boolean
): [UseQueryResult<OrganizationUsersResponse, Error>, () => void] {
  const queryKey = "organization-users";
  const response = useQuery<OrganizationUsersResponse, Error>(
    queryKey,
    async () => (await api.get)<OrganizationUsersResponse>(`${serviceUrl}`),
    { enabled: isAdmin }
  );

  const client = useQueryClient();
  const refreshResponse = () => {
    client.invalidateQueries(queryKey);
  };

  return [response, refreshResponse];
}
