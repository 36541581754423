import React from "react";
import Modal from "react-modal";

import { Button, FlexBox, Text } from "mool-design/src";
import styled from "styled-components";

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  deleteDocument: () => void;
  docName: string;
}

const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    width: "50%",
    maxWidth: "520px",
    height: "30%",
    maxHeight: "300px",
    transform: "translate(-50%, -5%)", // Center the modal horizontally
    padding: 0,
    backgroundColor: "#17171C",
    borderRadius: "20px",
    border: "0px",
    boxShadow: "0px 8px 16px 16px #0000006B",
  },
  overlay: {
    backgroundColor: "transparent",
  },
};

const StyledContainer = styled(FlexBox)`
  padding: 30px;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75%;
`;

const StyledText = styled(Text)`
  word-break: break-word; // Ensure long words break into a new line
  overflow-wrap: break-word; // Alternative way to wrap long text
  white-space: normal; // Prevent single-line text overflow
`;

function ConfirmDeleteModal({
  isModalOpen,
  closeModal,
  deleteDocument,
  docName,
}: Props): JSX.Element {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <StyledContainer>
        <FlexBox>
          <StyledText
            fontSize={4}
            fontWeight={"bold"}
            color={"text1"}
            textAlign={"center"}
          >
            Delete '{docName}'
          </StyledText>
        </FlexBox>
        <FlexBox mt={5}>
          <Text
            fontSize={2}
            fontWeight={"semibold"}
            color={"text2"}
            textAlign={"center"}
          >
            Are you sure you want to delete this document permanently?
          </Text>
        </FlexBox>
        <FlexBox mt={8} justifyContent={"space-between"} width={"100%"}>
          <FlexBox mx={3} flex={1}>
            <Button label="No" onClick={closeModal} background="#1E1E24" />
          </FlexBox>
          <FlexBox mx={3} flex={1}>
            <Button
              label="Delete"
              onClick={deleteDocument}
              background="#DF5050"
            />
          </FlexBox>
        </FlexBox>
      </StyledContainer>
    </Modal>
  );
}

export default ConfirmDeleteModal;
