import React, { useState } from "react";
import { FlexBox, TabSelector } from "mool-design/src";
import styled from "styled-components";
import { ChatCircleDots, GitDiff } from "@phosphor-icons/react";
import SourceSettings from "../source-settings";
import ChatInterface from "./chat-interface";

const StyledContainer = styled(FlexBox)`
  height: calc(100vh - 100px);
`;

type TabOptions = "chat" | "document-sources";

function QnAModule(): JSX.Element {
  const [selectedTab, setSelectedTab] = useState<TabOptions>("chat");

  return (
    <FlexBox width={320} flexDirection={"column"} flex={1}>
      <StyledContainer ml={2} flexDirection={"column"}>
        <FlexBox mb={2}>
          <FlexBox mr={2}>
            <TabSelector
              key={"chat"}
              label="Chat"
              icon={
                <ChatCircleDots
                  size={16}
                  color={selectedTab === "chat" ? "#FFE600" : "#ffffff"}
                />
              }
              isActive={selectedTab === "chat"}
              onClick={() => setSelectedTab("chat")}
            />
          </FlexBox>
          <FlexBox>
            <TabSelector
              key={"document-sources"}
              label="Document Sources"
              icon={
                <GitDiff
                  size={16}
                  color={
                    selectedTab === "document-sources" ? "#FFE600" : "#ffffff"
                  }
                />
              }
              isActive={selectedTab === "document-sources"}
              onClick={() => setSelectedTab("document-sources")}
            />
          </FlexBox>
        </FlexBox>
        {selectedTab === "chat" && <ChatInterface />}
        {selectedTab === "document-sources" && <SourceSettings />}
      </StyledContainer>
    </FlexBox>
  );
}

export default QnAModule;
