import { useIdTokenAuthenticateApi } from "shared/api/v3/auth/authenticate/post";

export function useIdTokenAuthenticate() {
  const { mutate, data, isLoading, isError } = useIdTokenAuthenticateApi();

  const handleAuthentication = (idToken: string, callback?: () => void) => {
    mutate(
      { idToken },
      {
        onSuccess: () => {
          if (callback) {
            callback();
          }
        },
      }
    );
  };

  return {
    handleAuthentication,
    isLoading,
    access_token: data?.data?.tokens?.access_token || "",
    refresh_token: data?.data?.tokens?.refresh_token || "",
    onboarding: data?.data?.tokens?.onboarding || "",
    isError,
  };
}
