import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";

const service = "enterprise/document/export";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface DocumentExportResponse {
  data: {
    output: { uri: string };
  };
}

export function useDocumentExportApi(
  docId: string
): UseMutationResult<DocumentExportResponse, unknown, unknown, unknown> {
  return useMutation(() => {
    return api.post<DocumentExportResponse>(`${serviceUrl}/${docId}`);
  });
}
