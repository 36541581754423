import React from "react";
import { useComponentsContext } from "@blocknote/react";
// import "@blocknote/mantine/style.css";

import { MagicWand } from "@phosphor-icons/react";

interface Props {
  onClickHandler: () => void;
}

function InstructionButton({ onClickHandler }: Props) {
  const Components = useComponentsContext()!;

  return (
    <Components.FormattingToolbar.Button
      mainTooltip={"Instruction for LLM"}
      onClick={() => {
        onClickHandler();
      }}
    >
      <MagicWand size={24} color="#FFE600" weight={"fill"} />
    </Components.FormattingToolbar.Button>
  );
}

export default InstructionButton;
