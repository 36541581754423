import React, { useEffect, useRef, useState } from "react";
import FlexBox from "../../atoms/flexbox";
import Text from "../../atoms/text";
import styled from "styled-components";

export interface OptionMenuItem {
  key: string;
  label: string;
}

interface Props {
  menuItems: OptionMenuItem[];
  onClick: (arg: OptionMenuItem) => void;
  children: React.ReactNode;
  size?: "sm" | "md";
}

const StyledContainer = styled(FlexBox)`
  align-items: center;
  cursor: pointer;
  position: relative;
`;

const StyledMenuContainer = styled(FlexBox)<{ leftOffset: number }>`
  z-index: 1000;
  position: absolute;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px #0000006b;
  background-color: #1e1e24;
  cursor: pointer;
  top: 100%;
  left: ${({ leftOffset }) => `${leftOffset}px`}; /* Adjust left position */
  white-space: nowrap; /* Prevent text from wrapping */
  min-width: 100%;
`;

const StyledMenuItemContainer = styled(FlexBox)`
  &:hover {
    background-color: #26262e;
  }
  border-radius: 8px;
  padding: 8px 16px;
  justify-content: space-between;
`;

function OptionWrapper({
  menuItems,
  onClick,
  children,
  size = "md",
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [leftOffset, setLeftOffset] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const adjustMenuPosition = () => {
    if (containerRef.current && menuRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const menuRect = menuRef.current.getBoundingClientRect();

      // Calculate the overflow relative to the parent container, not the screen
      if (menuRect.right > containerRect.right) {
        const overflow = menuRect.right - containerRect.right;
        setLeftOffset(-overflow);
      } else {
        setLeftOffset(0);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      adjustMenuPosition();
      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener("resize", adjustMenuPosition);
    } else {
      setLeftOffset(0);
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", adjustMenuPosition);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", adjustMenuPosition);
    };
  }, [isOpen]);

  return (
    <FlexBox flexDirection={"column"} ref={containerRef}>
      <StyledContainer onClick={handleOpen}>
        {children}
        {isOpen && (
          <StyledMenuContainer
            ref={menuRef}
            leftOffset={leftOffset}
            mt={2}
            flexDirection={"column"}
          >
            {menuItems.map((item) => (
              <StyledMenuItemContainer
                key={item.key}
                onClick={() => onClick(item)}
              >
                <Text
                  fontSize={size === "md" ? 2 : 0}
                  fontWeight={"regular"}
                  color={"text1"}
                >
                  {item.label}
                </Text>
              </StyledMenuItemContainer>
            ))}
          </StyledMenuContainer>
        )}
      </StyledContainer>
    </FlexBox>
  );
}

export default OptionWrapper;
