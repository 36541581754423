import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";

const service = "organization/users/unblock";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface RequestPayload {
  userIds: string[];
}

export interface UserUnblockResponse {
  data: {
    blockedUser: string[];
  };
}

export function useUserUnblockApi(): UseMutationResult<
  UserUnblockResponse,
  unknown,
  RequestPayload,
  unknown
> {
  return useMutation((payload: RequestPayload) => {
    return api.post<UserUnblockResponse>(serviceUrl, payload);
  });
}
