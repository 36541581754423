import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import { confirmAlert } from "react-confirm-alert";
import { Button, ConfirmPopup, FlexBox } from "mool-design/src";
import styled from "styled-components";

import { useFileUploadInput } from "shared/modules/editor/hooks";
import UploadNewFiles from "./upload-new-files";
import ExistingFiles from "./existing-files";

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  handleUpload: () => void;
}

const customStyles = {
  content: {
    top: "25%",
    left: "50%",
    width: "50%",
    maxWidth: "720px",
    height: "60%",
    transform: "translate(-50%, -5%)", // Center the modal horizontally
    padding: 0,
    backgroundColor: "#17171C",
    borderRadius: "20px",
    border: "0px",
    boxShadow: "0px 8px 16px 16px #0000006B",
  },
  overlay: {
    backgroundColor: "transparent",
  },
};

const StyledContainer = styled(FlexBox)`
  flex: 1;
  padding: 20px 30px;
  border-radius: 20px;
  flex-direction: column;
  height: calc(100% - 40px);
`;

const StyledTabSelector = styled(FlexBox)`
  border-radius: 12px;
  padding: 4px;
  background-color: #000000;
`;

function FileUploadModal({
  isModalOpen,
  closeModal,
  handleUpload,
}: Props): JSX.Element {
  const [activeTab, setActiveTab] = useState("view");

  const [isDirty, setIsDirty] = useState(false);

  const { selectedFiles, removeAllFiles } = useFileUploadInput();

  const handleClose = () => {
    if (isDirty) {
      confirmAlert({
        overlayClassName: "custom-confirm-alert",
        customUI: ({ onClose }) => {
          return (
            <ConfirmPopup
              title="Are you sure you want to close the upload modal?"
              subTitle="You will lose the progress so far."
              closeModal={() => {
                removeAllFiles();
                closeModal();
              }}
              onClose={onClose}
            />
          );
        },
      });
    } else {
      removeAllFiles();
      closeModal();
    }
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [selectedFiles.length]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      style={customStyles}
    >
      <StyledContainer>
        <StyledTabSelector>
          <FlexBox flex={1} mr={1}>
            <Button
              background={activeTab === "view" ? "#111114" : "#000000"}
              label="Existing Files"
              onClick={() => setActiveTab("view")}
            />
          </FlexBox>
          <FlexBox flex={1} ml={1}>
            <Button
              background={activeTab === "upload_new" ? "#111114" : "#000000"}
              label="Upload New"
              onClick={() => setActiveTab("upload_new")}
            />
          </FlexBox>
        </StyledTabSelector>
        {activeTab === "view" ? (
          <ExistingFiles />
        ) : (
          <UploadNewFiles handleUpload={handleUpload} />
        )}
      </StyledContainer>
    </Modal>
  );
}

export default FileUploadModal;
