import React, { useState, useEffect, useRef } from "react";
import { FlexBox, Textarea } from "mool-design/src";
import styled from "styled-components";
import { useDocumentChat } from "shared/modules/editor/hooks";
import EmptyChatPlaceholder from "./empty-chat-placeholder";
import ChatBox from "../components/chat-box";
import { PaperPlaneRight } from "@phosphor-icons/react";

const StyledChatContainer = styled(FlexBox)`
  border-radius: 10px;
  position: relative;
  background: radial-gradient(
      circle at 0% 90%,
      rgba(255, 230, 0, 0.15),
      transparent 60%
    ),
    radial-gradient(
      circle at 60% 98%,
      rgba(29, 18, 147, 0.35),
      transparent 100%
    );
  overflow: hidden;
  padding: 12px;
  flex-direction: column;
  min-width: 200px;
`;

function ChatInterface(): JSX.Element {
  const [userPrompt, setUserPrompt] = useState("");

  const {
    chatHistory,
    handleDocumentChatStream,
    isPosting,
    isLoading: isFirstLoading,
  } = useDocumentChat();
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const handleUserPrompt = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setUserPrompt(value);
  };

  const handleSubmit = () => {
    if (userPrompt) {
      handleDocumentChatStream({ userQuery: userPrompt });
      setUserPrompt("");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevents the default behavior of inserting a newline
      handleSubmit(); // Call the submit function
    }
  };

  // Scroll to the bottom when `isPosting` is true or chat history changes
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [isPosting, chatHistory]);

  return (
    <StyledChatContainer flex={1}>
      <FlexBox
        ref={chatContainerRef}
        flex={1}
        flexDirection={"column"}
        style={{ overflowY: "scroll" }}
        className={"hide-scroll-bar"}
      >
        {!isFirstLoading && (!chatHistory || !chatHistory.length) ? (
          <FlexBox flex={1} justifyContent={"center"}>
            <EmptyChatPlaceholder />
          </FlexBox>
        ) : (
          chatHistory?.map((chat, index, arr) => (
            <FlexBox key={chat._id} flexDirection={"column"} mb={5}>
              <FlexBox mb={5}>
                <ChatBox label="You" content={chat.userPrompt} />
              </FlexBox>
              {isPosting && index === arr.length - 1 ? (
                <FlexBox>
                  <ChatBox isLoading label="Mool AI" content={chat.response} />
                </FlexBox>
              ) : (
                <FlexBox>
                  <ChatBox label="Mool AI" content={chat.response} />
                </FlexBox>
              )}
            </FlexBox>
          ))
        )}
      </FlexBox>
      <FlexBox width={"100%"} alignSelf={"flex-end"}>
        <Textarea
          value={userPrompt}
          onChange={handleUserPrompt}
          placeholderText={"Ask Mool AI anything..."}
          onKeyDown={handleKeyDown}
          icon={
            <FlexBox p={2} style={{ cursor: "pointer" }} onClick={handleSubmit}>
              <PaperPlaneRight size={22} color={"#b9b9c7"} />
            </FlexBox>
          }
          shadow
          rows={1}
        />
      </FlexBox>
    </StyledChatContainer>
  );
}

export default ChatInterface;
