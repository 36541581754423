import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import api from "shared/network/api";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import { IOrg, IUser } from "shared/types";

const service = "user/me";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

interface UserResponse {
  data: {
    user: IUser;
    org: IOrg;
  };
}

export function useUserMeApi(): [
  UseQueryResult<UserResponse, Error>,
  () => void
] {
  const queryKey = "user-me";
  const response = useQuery<UserResponse, Error>(queryKey, async () =>
    (await api.get)<UserResponse>(`${serviceUrl}`)
  );

  const client = useQueryClient();
  const refreshResponse = () => {
    client.invalidateQueries(queryKey);
  };

  return [response, refreshResponse];
}
