import { FileDashed } from "@phosphor-icons/react/dist/ssr";
import { FlexBox, Text } from "mool-design/src";
import React from "react";

function EmptyDocumentPlaceholder({ text }: { text: string }) {
  return (
    <FlexBox
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"300px"}
    >
      <FlexBox>
        <FileDashed color={"#635C1D"} size={42} />
      </FlexBox>
      <FlexBox>
        <Text
          fontSize={1}
          fontWeight={"regular"}
          color={"text3"}
          textAlign={"center"}
        >
          {text}
        </Text>
      </FlexBox>
    </FlexBox>
  );
}

export default EmptyDocumentPlaceholder;
