import React from "react";
import { FlexBox, Text } from "mool-design/src";
import styled from "styled-components";

const StyledProjectCard = styled(FlexBox)<{ isSelected?: boolean }>`
  padding: 12px 16px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.isSelected === true ? "#1e1e24" : "transparent"};
  flex-direction: column;
  cursor: pointer;
  &:hover {
    background-color: #26262e;
  }
  justify-content: center;
  flex: 1;
`;

const StyledText = styled(Text)`
  word-break: break-word; // Ensure long words break into a new line
  overflow-wrap: break-word; // Alternative way to wrap long text
  white-space: normal; // Prevent single-line text overflow
`;

interface Props {
  title: string;
  subTitle: string;
  isSelected: boolean;
}

function ProjectCard({ title, subTitle, isSelected }: Props): JSX.Element {
  return (
    <StyledProjectCard isSelected={isSelected}>
      <FlexBox flex={1}>
        <StyledText
          fontSize={1}
          fontWeight={isSelected ? "bold" : "regular"}
          color={"text1"}
        >
          {title}
        </StyledText>
      </FlexBox>
      <FlexBox mt={1}>
        <Text fontSize={0} fontWeight={"regular"} color={"text2"}>
          {subTitle}
        </Text>
      </FlexBox>
    </StyledProjectCard>
  );
}

export default ProjectCard;
