import React, { useEffect, useState } from "react";
import { FlexBox, View } from "mool-design/src";
import { useUserMe } from "shared/modules/user/user-me";
import styled from "styled-components";
import ProjectHistory from "./project-history";
import GenerateDocument from "./generate-document";
import { useNavigate } from "react-router-dom";
import {
  ModuleStateContext,
  InitialState,
} from "shared/modules/dashboard/module-state";
import { LoadingComponent } from "../../components/loader";

const StyledContainer = styled(FlexBox)`
  align-items: center;
  flex-direction: column;
  width: 100vw;
`;

// const StyledGradientContainer = styled(FlexBox)`
//   position: relative;
//   background: radial-gradient(
//       circle at 45% 90%,
//       rgba(255, 230, 0, 0.15),
//       transparent 40%
//     ),
//     radial-gradient(circle at 60% 98%, rgba(29, 18, 147, 0.3), transparent 30%);

//   overflow: hidden;
//   align-items: center;
//   justify-content: center;
//   padding: 40px 0px;
//   width: 100vw;
// `;

function Dashboard(): JSX.Element {
  const [moduleState, setModuleState] = useState(InitialState);

  const navigate = useNavigate();

  const { onboarding, isLoading } = useUserMe();

  useEffect(() => {
    if (onboarding && onboarding !== "completed") {
      navigate("/onboard");
    }
  }, [onboarding]);

  if (isLoading) return <LoadingComponent />;

  return (
    <ModuleStateContext.Provider
      value={{ state: moduleState, setState: setModuleState }}
    >
      <View position="fixed">
        <StyledContainer>
          {/* <StyledGradientContainer>
            <FlexBox>
              <FlexBox>
                <Text fontSize={6} color={"text2"} fontWeight={"regular"}>
                  Welcome back,
                </Text>
              </FlexBox>
              <FlexBox ml={2}>
                <Text fontSize={6} fontWeight={"bold"} color={"text1"}>
                  {firstName}
                </Text>
              </FlexBox>
            </FlexBox>
          </StyledGradientContainer> */}
          <FlexBox
            flexDirection={"column"}
            style={{ minWidth: "75vw" }}
            mt={10}
          >
            <FlexBox>
              <GenerateDocument />
            </FlexBox>
            <FlexBox mt={5}>
              <ProjectHistory />
            </FlexBox>
          </FlexBox>
        </StyledContainer>
      </View>
    </ModuleStateContext.Provider>
  );
}

export default Dashboard;
