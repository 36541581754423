import {
  useInvitationAcceptApi,
  RequestPayload,
} from "shared/api/v3/organization/accept/post";

export function useInvitationAccept(callback: (arg: string) => void) {
  const { mutate, isLoading } = useInvitationAcceptApi();

  const handleInvitationAccept = ({ invitationId }: RequestPayload) => {
    mutate(
      { invitationId },
      {
        onSuccess: () => {
          callback("success");
        },
        onError: () => {
          callback("error");
        },
      }
    );
  };

  return {
    handleInvitationAccept,
    isLoading,
  };
}
