import { useDocumentGenerateApi } from "shared/api/v3/enterprise/docgen/generate/post";
import { useProjectCreateApi } from "shared/api/v3/enterprise/project/create/post";
import { useProjectListApi } from "shared/api/v3/enterprise/project/get";
import { useTemplateListApi } from "shared/api/v3/enterprise/templates/get";
import { DOCUMENT_SOURCES, useModuleState } from "../module-state";
import { IGraphProject, MenuItem } from "shared/types";

export function useProjectList() {
  const [{ data, isLoading }, refreshProjectList] = useProjectListApi();

  const projects = data?.data?.projects || [];
  const projectMenuItems = projects.map((proj) => ({
    key: proj.project?._id,
    label: proj.project?.projectName,
  }));

  return {
    isLoading,
    projects,
    projectMenuItems,
    refreshProjectList,
  };
}

export function useTemplateList() {
  const [{ data, isLoading }] = useTemplateListApi();

  const templatesFromServer = data?.data?.templates || [];
  const templates = templatesFromServer.map((template) => ({
    key: template.name,
    label: template.label,
  }));

  return {
    isLoading,
    templates,
  };
}

export function useDocumentSourceList() {
  return {
    documentSources: DOCUMENT_SOURCES,
  };
}

export function useProjectCreate(callback?: (project: IGraphProject) => void) {
  const { mutate, data, isLoading } = useProjectCreateApi();

  const handleCreateProject = ({ projectName }: { projectName: string }) => {
    mutate(
      { projectName },
      {
        onSuccess: (response) => {
          if (callback) {
            callback(response.data?.project);
          }
        },
      }
    );
  };

  return {
    handleCreateProject,
    data,
    isLoading,
  };
}

export function useDocumentGenerate(callback?: (arg: string) => void) {
  const { mutate, isLoading, data } = useDocumentGenerateApi();

  const handleDocumentGenerate = (payload: any) => {
    mutate(payload, {
      onSuccess: (response) => {
        if (callback) {
          callback(response?.data?.docId);
        }
      },
    });
  };

  return {
    handleDocumentGenerate,
    isLoading,
    data,
  };
}

export function useGenerateDocumentInput() {
  const [
    { selectedDocumentSources, selectedProject, selectedTemplate },
    setModuleState,
  ] = useModuleState();

  const setSelectedProject = (item: MenuItem | null) => {
    setModuleState({
      selectedProject: item,
    });
  };

  const setSelectedTemplate = (item: MenuItem | null) => {
    setModuleState({
      selectedTemplate: item,
    });
  };

  const setSelectedDocumentSources = (item: MenuItem | null) => {
    if (item === null) {
      setModuleState({
        selectedDocumentSources: DOCUMENT_SOURCES,
      });
    } else {
      const alreadySelected = selectedDocumentSources.some(
        (source) => source.key === item.key
      );
      if (alreadySelected) {
        const updatedSources = selectedDocumentSources.filter(
          (source) => source.key !== item.key
        );
        setModuleState({
          selectedDocumentSources: updatedSources,
        });
      } else {
        setModuleState({
          selectedDocumentSources: [...selectedDocumentSources, item],
        });
      }
    }
  };

  return {
    selectedDocumentSources,
    selectedProject,
    selectedTemplate,
    setSelectedProject,
    setSelectedTemplate,
    setSelectedDocumentSources,
  };
}

export function useFileUploadInput() {
  const [{ files }, setModuleState] = useModuleState();

  const handleFileAdd = (arg: File[]): void => {
    if (!arg || arg.length <= 0) return;
    const updatedFiles = [...files, ...arg];
    setModuleState({ files: updatedFiles });
  };

  const handleFileRemove = (fileName: string): void => {
    const updatedFiles = files.filter((file) => file.name !== fileName);
    setModuleState({ files: updatedFiles });
  };

  const removeAllFiles = () => {
    setModuleState({ files: [] });
  };

  return {
    handleFileAdd,
    handleFileRemove,
    selectedFiles: files,
    removeAllFiles,
  };
}
