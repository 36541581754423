import React from "react";
import { FlexBox, Text } from "mool-design/src";
import styled from "styled-components";

const StyledLogText = styled(Text)`
  color: #4bb676;
  font-size: 11px;
  font-family: Roboto Mono;
  word-break: break-word; // Ensure long words break into a new line
  overflow-wrap: break-word; // Alternative way to wrap long text
  white-space: normal; // Prevent single-line text overflow
`;

const StyledText = styled(Text)`
  font-size: 11px;
  font-family: Roboto Mono;
  word-break: break-word; // Ensure long words break into a new line
  overflow-wrap: break-word; // Alternative way to wrap long text
  white-space: normal; // Prevent single-line text overflow
`;

function TokenUsage({
  tokenUsage,
}: {
  tokenUsage: {
    completionTokens: number;
    promptTokens: number;
    totalTokens: number;
  };
}) {
  return (
    <FlexBox flexDirection={"column"}>
      <StyledText color={"text2"} fontWeight={"regular"}>
        [TOKEN-USAGE]::
      </StyledText>
      <StyledLogText style={{ color: "#CB00FE" }} fontWeight={"regular"}>
        Completion Tokens : {tokenUsage?.completionTokens}
      </StyledLogText>
      <StyledLogText style={{ color: "#CB00FE" }} fontWeight={"regular"}>
        Prompt Tokens : {tokenUsage?.promptTokens}
      </StyledLogText>
      <StyledLogText style={{ color: "#CB00FE" }} fontWeight={"regular"}>
        Total Tokens : {tokenUsage?.totalTokens}
      </StyledLogText>
    </FlexBox>
  );
}

export default TokenUsage;
