import React from "react";
import { FlexBox, Text } from "mool-design/src";
import MoolAiBrandLogo from "theme/assets/images/mool-ai-brand-logo.png";

function EmptyChatPlaceholder() {
  return (
    <FlexBox
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <FlexBox>
        <img
          src={MoolAiBrandLogo}
          style={{
            height: 28,
          }}
          alt="MoolAiBrandLogo"
        />
      </FlexBox>
      <FlexBox mt={1}>
        <Text
          fontSize={0}
          fontWeight={"regular"}
          color={"text3"}
          textAlign={"center"}
        >
          Was Zomato profitable this quarter? Show me their financials
        </Text>
      </FlexBox>
    </FlexBox>
  );
}

export default EmptyChatPlaceholder;
