import React from "react";
import ReactDOM from "react-dom/client";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./index.css";
import "@blocknote/mantine/style.css";
import "@blocknote/core/fonts/inter.css";

import App from "./entry-points/App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
