import React from "react";

import { FlexBox, Text } from "mool-design/src";
import styled from "styled-components";
import { FileDashed } from "@phosphor-icons/react";

import { useDocFileList } from "shared/modules/editor/hooks/doc";
import FileViewCard from "./file-view-card";

const StyledUploadAreaContainer = styled(FlexBox)<{ isDragActive: boolean }>`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  border: 1px dashed
    ${(props) => (props.isDragActive ? "#635C1D" : "transparent")};
  background-color: ${(props) =>
    props.isDragActive ? "#111114" : "transparent"};
  border-radius: 10px;
`;

const StyledUploadFilesContainer = styled(FlexBox)`
  flex-direction: column;
  border-radius: 10px;
  overflow-y: scroll;
  height: 300px;
`;

function ExistingFiles(): JSX.Element {
  const { files } = useDocFileList();

  return (
    <FlexBox flexDirection={"column"} flex={1} justifyContent={"space-between"}>
      <FlexBox flexDirection={"column"} flex={1}>
        <FlexBox mt={5}>
          <Text fontSize={4} fontWeight={"bold"} color={"text1"}>
            Previously uploaded documents
          </Text>
        </FlexBox>
        <FlexBox mt={2}>
          <Text fontSize={1} fontWeight={"regular"} color={"text3"}>
            You can remove documents from here to remove it from the context
          </Text>
        </FlexBox>
        {files.length > 0 ? (
          <StyledUploadFilesContainer className={"hide-scroll-bar"} mt={4}>
            {files.map((file) => (
              <FileViewCard key={file._id} file={file} />
            ))}
          </StyledUploadFilesContainer>
        ) : (
          <StyledUploadAreaContainer isDragActive={false}>
            <FlexBox>
              <FileDashed size={42} color="#635C1D" />
            </FlexBox>
            <FlexBox mt={2}>
              <Text
                fontSize={1}
                fontWeight={"regular"}
                color={"text3"}
                textAlign={"center"}
              >
                You have no uploaded files
              </Text>
            </FlexBox>
          </StyledUploadAreaContainer>
        )}
      </FlexBox>
    </FlexBox>
  );
}

export default ExistingFiles;
