import { useDocumentDetailApi } from "shared/api/v3/enterprise/document/{id}/get";
import { useModuleState } from "../module-state";
import { useDocumentSettingsApi } from "shared/api/v3/enterprise/document/{id}/settings/get";
import {
  useDocumentSettingsUpdateApi,
  RequestPayload,
} from "shared/api/v3/enterprise/document/{id}/settings/post";
import { IGraphDocument, TEventStreamData } from "shared/types";
import { useEffect, useState } from "react";
import {
  useDocumentSaveApi,
  RequestPayload as RequestPayloadDocSave,
} from "shared/api/v3/enterprise/document/{id}/save/post";
import pako from "pako";
import { useDocumentExportApi } from "shared/api/v3/enterprise/document/export/{id}/post";
import { handleDownload } from "shared/utils/gcs-download";
import { useDocumentDeleteApi } from "shared/api/v3/enterprise/document/{id}/delete";
import { useProjectList } from "shared/modules/dashboard/hooks";
import { useDocumentStatusStreamApi } from "shared/api/v3/enterprise/document/status/stream/{id}/get";

export { useDocumentChat } from "./chat";
export { useFileUploadInput } from "./doc";

export function useDocId() {
  const [{ docId }, setModuleState] = useModuleState();

  const setDocId = (id: string | null) => {
    setModuleState({ docId: id });
  };

  return { docId, setDocId };
}

const parseMd = (text: string) => {
  const fixedString = text.replace(/\\n+/g, "\n").replace(/"/g, "");
  return fixedString;
};

export function useDocumentDetail() {
  const { docId } = useDocId();
  const [{ data, isLoading }, refreshDocumentDetail] =
    useDocumentDetailApi(docId);

  const documentFromServer = (data?.data?.document || {}) as IGraphDocument;

  const document = documentFromServer.fileString
    ? {
        ...documentFromServer,
        // Fix for : the fileString includes double quotes ("") which is causing editor to ignore the markdown
        fileString: parseMd(documentFromServer?.fileString),
      }
    : documentFromServer;

  return {
    isLoading,
    document,
    refreshDocumentDetail,
  };
}

export function useDocumentSettingsUpdate(callback?: () => void) {
  const { mutate, isLoading, data } = useDocumentSettingsUpdateApi();

  const handleDocumentSettings = (payload: RequestPayload) => {
    mutate(payload, {
      onSuccess: () => {
        if (callback) {
          callback();
        }
      },
    });
  };

  return {
    handleDocumentSettings,
    isLoading,
    data,
  };
}

export function useDocumentSettings() {
  const [{ settings }, setModuleState] = useModuleState();

  const { docId } = useDocId();
  const [{ data, isLoading }, refreshSettings] = useDocumentSettingsApi(docId);
  const { handleDocumentSettings } = useDocumentSettingsUpdate(refreshSettings);

  useEffect(() => {
    const settingsFromServer = data?.data?.settings?.tools || [];
    setModuleState({ settings: settingsFromServer });
  }, [JSON.stringify(data?.data?.settings?.tools)]);

  const updateSettings = (arg: string) => {
    let updatedSettings = [];
    if (settings.includes(arg)) {
      updatedSettings = settings.filter((set) => set !== arg);
    } else {
      updatedSettings = [...settings, arg];
    }
    setModuleState({
      settings: updatedSettings,
    });
    handleDocumentSettings({
      docId: docId || "",
      tools: updatedSettings,
    });
  };

  return {
    isLoading,
    settings,
    updateSettings,
  };
}

export function useDocumentSave() {
  const { docId } = useDocId();
  const { mutate, isLoading, data } = useDocumentSaveApi(docId || "");
  const { refreshDocumentDetail } = useDocumentDetail();

  const handleDocumentSave = ({
    documentName,
    fileString,
    status,
  }: RequestPayloadDocSave) => {
    const payload = {
      documentName,
      fileString,
      status,
    };

    const compressedData = pako.deflate(JSON.stringify(payload));

    // Convert compressed data to Base64
    const base64Data = btoa(
      String.fromCharCode.apply(null, new Uint8Array(compressedData))
    );

    const compressedPayload = { data: base64Data };

    mutate(compressedPayload, {
      onSuccess: () => {
        refreshDocumentDetail();
      },
    });
  };

  return {
    handleDocumentSave,
    isLoading,
    data,
  };
}

export function useDocumentExport() {
  const { docId } = useDocId();
  const { mutate, isLoading } = useDocumentExportApi(docId || "");

  const exportDocument = () => {
    mutate(
      {},
      {
        onSuccess: (response) => {
          const fileName = `${docId}.docx`;
          const uri = response?.data?.output?.uri || "";
          handleDownload(uri, fileName);
        },
      }
    );
  };

  return {
    exportDocument,
    isLoading,
  };
}

export function useDocumentDelete() {
  const { mutate, isLoading } = useDocumentDeleteApi();
  const { refreshProjectList } = useProjectList();

  const handleDocumentDelete = ({ docId }: { docId: string }) => {
    mutate(
      {
        docId,
      },
      {
        onSuccess: () => {
          // refresh
          refreshProjectList();
        },
      }
    );
  };

  return {
    handleDocumentDelete,
    isLoading,
  };
}

export function useDocumentStatusStream() {
  const [event, setEvent] = useState<TEventStreamData | null>(null);
  const { docId } = useDocId();
  const { refreshDocumentDetail } = useDocumentDetail();

  const eventHandler = (arg: TEventStreamData) => {
    if (arg && arg.type === "done") {
      refreshDocumentDetail();
    } else {
      setEvent(arg);
    }
  };

  useDocumentStatusStreamApi(docId, eventHandler);

  return {
    event,
  };
}

export function useTypingEffect(text: string, speed: number = 50): string {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      setDisplayedText((prev) => prev + text[currentIndex]);
      currentIndex++;
      if (currentIndex === text.length) {
        clearInterval(intervalId);
      }
    }, speed);

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, [text, speed]);

  return displayedText;
}
