import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "../routes";
import { QueryClientProvider } from "react-query";
import { GlobalStateContext, InitialState } from "shared/global-state";
import queryClient from "shared/network";
import ThemeProvider from "theme/src/provider";
import { Worker } from "@react-pdf-viewer/core";
import styled from "styled-components";

import { ToastContainer } from "react-toastify";

import TopNavBar from "../components/top-nav-bar";

import "react-toastify/dist/ReactToastify.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const ContentContainer = styled.div`
  padding-top: 64px;
`;

function App(): JSX.Element {
  const [globalState, setGlobalState] = useState(InitialState);
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <React.StrictMode>
        <GlobalStateContext.Provider
          value={{
            state: globalState,
            setState: setGlobalState,
          }}
        >
          <ThemeProvider defaultMode={"dark"}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <ToastContainer />
                <TopNavBar />
                <ContentContainer>
                  <AppRoutes />
                </ContentContainer>
              </BrowserRouter>
            </QueryClientProvider>
          </ThemeProvider>
        </GlobalStateContext.Provider>
      </React.StrictMode>
    </Worker>
  );
}

export default App;
