import React, { useState } from "react";
import styled from "styled-components";

import { Button, FlexBox, Text, Textarea } from "mool-design/src";
import ChatBox from "../components/chat-box";

const Container = styled(FlexBox)`
  position: absolute;
  z-index: 1000;
  flex-direction: column;
  padding: 16px;
  border-radius: 10px;
  top: 10;
  right: 0;
  background-color: #17171c;
  box-shadow: 4px 4px 16px 0px #0000006b;
  width: 65%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  onSubmit: (text: string) => void;
  isLoading?: boolean;
  instruction: string;
}

const FloatingCommentBox = ({ onSubmit, isLoading, instruction }: Props) => {
  const [comment, setComment] = useState("");

  const handleSubmit = () => {
    onSubmit(comment);
  };

  const handleComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setComment(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      // Check if Enter is pressed and Shift is not held down
      event.preventDefault(); // Prevents the default behavior of inserting a newline
      handleSubmit(); // Call the submit function
    }
  };

  return (
    <Container mt={8}>
      <FlexBox>
        <Text fontSize={1} fontWeight={"bold"} color={"text1"}>
          Re-write
        </Text>
      </FlexBox>
      {isLoading ? (
        <FlexBox mt={5}>
          <ChatBox content={instruction} label="You" />
        </FlexBox>
      ) : (
        <FlexBox flex={1} my={3}>
          <Textarea
            placeholderText="How would you like to re-write this text?"
            value={comment}
            onChange={handleComment}
            onKeyDown={handleKeyDown}
            type="gradient"
            rows={3}
          />
        </FlexBox>
      )}
      {isLoading ? (
        <FlexBox mt={5}>
          <ChatBox isLoading content="thinking" label="Mool AI" />
        </FlexBox>
      ) : (
        <ButtonContainer>
          <Button
            label="Generate Now"
            variant="primary"
            onClick={handleSubmit}
            background={"#91851D8A"}
          />
        </ButtonContainer>
      )}
    </Container>
  );
};

export default FloatingCommentBox;
