import {
  RequestPayload,
  GraphLogsInfiniteResponse,
  useGraphLogsInfiniteApi,
} from "shared/api/v3/enterprise/document/graph-logs/{id}/get";
import { useDocId } from "./index";
import { useRef, useState } from "react";
import { useGraphLogsStreamApi } from "shared/api/v3/enterprise/document/graph-logs/stream/{id}/get";
import { IGraphLog, TEventStreamData } from "shared/types";
import { useModuleState } from "../module-state";

export function useShowLog() {
  const [{ showLog }, setModuleState] = useModuleState();

  const toggleShowLog = () => {
    setModuleState({ showLog: !showLog });
  };

  return {
    showLog,
    toggleShowLog,
  };
}

export function useGraphLogsInfinite(payload: RequestPayload) {
  const [
    { fetchNextPage, isLoading, isFetchingNextPage, data, hasNextPage },
    refreshLogs,
  ] = useGraphLogsInfiniteApi(payload);

  let flatList = [];

  if (data) {
    flatList = data?.pages?.reduce<GraphLogsInfiniteResponse["data"]["logs"]>(
      (acc, page) => [...page.data.logs, ...acc],
      []
    );
    return {
      fetchNextPage,
      isLoading,
      isFetchingNextPage,
      data: flatList,
      hasNextPage,
      refreshLogs,
    };
  } else {
    return {
      fetchNextPage,
      isLoading,
      isFetchingNextPage,
      data: [],
      hasNextPage,
      refreshLogs,
    };
  }
}

export function useGraphLogsStream() {
  const { docId } = useDocId();

  const [streamLogs, setStreamLogs] = useState<IGraphLog[]>([]);

  const streamLogsRef = useRef(streamLogs);

  const eventHandler = ({ data }: TEventStreamData) => {
    const parsedData = data as unknown as { text: string; log: IGraphLog };
    const parsedLog = parsedData.log;
    if (!streamLogsRef.current.some((l) => l._id === parsedLog._id)) {
      const logsFromState = [...streamLogsRef.current, parsedLog];
      streamLogsRef.current = logsFromState;
      setStreamLogs(streamLogsRef.current);
    }
  };

  useGraphLogsStreamApi(docId, eventHandler);

  return {
    logStream: streamLogsRef.current,
  };
}

export function useGraphLogs() {
  const { docId } = useDocId();
  const {
    data,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    refreshLogs,
  } = useGraphLogsInfinite({ docId });

  return {
    logs: data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refreshLogs,
  };
}
