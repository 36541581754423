import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FlexBox from "../../atoms/flexbox";

const StyledTexAreaContainer = styled(FlexBox)<{
  backgroundColor: string;
  isError?: boolean;
  paddingRight?: string;
  isFocused?: boolean;
  shadow?: boolean;
}>`
  padding: 0px ${(props) => (props.paddingRight ? props.paddingRight : "12px")}
    0px 12px;
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor};
  flex: 1;
  border: 0.5px solid
    ${(props) =>
      props.isError ? "#D81846" : props.isFocused ? "#b9b9c7" : "transparent"};
  outline: none;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${(props) =>
    props.shadow ? "0px 0px 16px 8px #0000006b" : "none"};
`;

const StyledTextarea = styled.textarea<{ backgroundColor: string }>`
  background-color: transparent;
  outline: none;
  border: 0px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: DM Sans;
  line-height: 20px;
  width: 100%;
  height: 100%;
  resize: none;
  display: flex;
  align-items: center; /* Align items vertically */
  &::placeholder {
    color: #b9b9c7;
  }
`;

const StyledGradientBackground = styled(FlexBox)`
  flex: 1;
  border: 1px solid transparent;
  background-image: linear-gradient(#1e1e24, #1e1e24),
    linear-gradient(89.7deg, #635c1d 0.14%, #1d1293 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 10px;
`;

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  placeholderText: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  type?: "default" | "gradient";
  backgroundColor?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  icon?: React.ReactNode;
  shadow?: boolean;
}

function Textarea({
  placeholderText,
  value,
  onChange,
  onKeyDown,
  type = "default",
  backgroundColor = "#1e1e24",
  icon,
  shadow = false,
  ...rest
}: Props): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to auto
      textarea.style.height = `${Math.min(textarea.scrollHeight, 60)}px`; // Set height to scrollHeight or max height
    }
  }, [value]);

  if (type === "gradient") {
    return (
      <FlexBox flex={1} alignItems={"center"}>
        <StyledGradientBackground>
          <StyledTexAreaContainer
            shadow={shadow}
            backgroundColor={backgroundColor}
            paddingRight={icon ? "0px" : "12px"}
            isFocused={isFocused}
          >
            <StyledTextarea
              ref={textareaRef}
              backgroundColor={backgroundColor}
              placeholder={placeholderText}
              onChange={onChange}
              value={value}
              onKeyDown={onKeyDown}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              {...rest}
            />
          </StyledTexAreaContainer>
        </StyledGradientBackground>
      </FlexBox>
    );
  }
  return (
    <FlexBox flex={1} alignItems={"center"}>
      <StyledTexAreaContainer
        shadow={shadow}
        backgroundColor={backgroundColor}
        paddingRight={icon ? "0px" : "12px"}
        isFocused={isFocused}
      >
        <StyledTextarea
          ref={textareaRef}
          backgroundColor={backgroundColor}
          placeholder={placeholderText}
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...rest}
        />

        {icon && icon}
      </StyledTexAreaContainer>
    </FlexBox>
  );
}

export default Textarea;
