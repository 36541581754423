import React from "react";
import styled from "styled-components";
import FlexBox from "../flexbox";

const StyledView = styled(FlexBox)<{
  position?: "fixed" | "relative" | "absolute";
}>`
  align-items: center;
  flex: 1;
  background-color: #000000;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
  position: ${(props) => props?.position || "relative"};
`;

export default StyledView;
