import React from "react";
import { FlexBox, Text } from "mool-design/src";
import styled from "styled-components";
import { useOrganizationUsersList } from "shared/modules/account-settings/hooks/organization";
import { getFullDateLocalFormatWithTime } from "shared/utils/date-formatter";

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.tr`
  text-align: left;
  th {
    background-color: transparent;
    border: 0px;
  }
  &:hover {
    background-color: transparent;
  }
`;

const TableRow = styled.tr`
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    box-shadow: 4px 4px 16px 0px #0000006b;
  }
  td {
    background-color: transparent;
    border: 0px;
  }
`;

const ROLE_MAP: { [key: string]: string } = {
  member: "Member",
  admin: "Admin",
};

function InvitationTable() {
  const { invitations } = useOrganizationUsersList();

  return (
    <StyledTable>
      <thead>
        <TableHeader>
          <th>
            <Text fontSize={0} fontWeight={"regular"} color={"text3"}>
              NAME
            </Text>
          </th>
          <th>
            <Text fontSize={0} fontWeight={"regular"} color={"text3"}>
              ROLE
            </Text>
          </th>
          <th>
            <Text fontSize={0} fontWeight={"regular"} color={"text3"}>
              DAT & TIME
            </Text>
          </th>
          <th>
            <Text fontSize={0} fontWeight={"regular"} color={"text3"}>
              STATUS
            </Text>
          </th>
        </TableHeader>
      </thead>
      <tbody>
        {invitations.map((invitation) => (
          <TableRow key={invitation._id}>
            <td>
              <FlexBox>
                <Text fontSize={1} fontWeight={"semibold"} color={"text1"}>
                  {invitation.email}
                </Text>
              </FlexBox>
            </td>
            <td>
              <FlexBox>
                <Text fontSize={1} fontWeight={"semibold"} color={"text1"}>
                  {ROLE_MAP[invitation.role] || invitation.role}
                </Text>
              </FlexBox>
            </td>
            <td>
              <FlexBox>
                <Text color={"text1"} fontSize={0} fontWeight={"regular"}>
                  {getFullDateLocalFormatWithTime(
                    invitation.createdAt.toString()
                  )}
                </Text>
              </FlexBox>
            </td>
            <td>
              <FlexBox>
                <Text fontSize={1} fontWeight={"semibold"} color={"text1"}>
                  {invitation.status}
                </Text>
              </FlexBox>
            </td>
          </TableRow>
        ))}
      </tbody>
    </StyledTable>
  );
}

export default InvitationTable;
