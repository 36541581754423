import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";
import { IGraphDocument } from "shared/types";

const service = "enterprise/document";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

interface DocumentDetailResponse {
  data: {
    document: IGraphDocument;
  };
}

export function useDocumentDetailApi(
  documentId: string | null
): [UseQueryResult<DocumentDetailResponse, Error>, () => void] {
  const queryKey = `document-detail-${documentId}`;
  const response = useQuery<DocumentDetailResponse, Error>(
    queryKey,
    async () =>
      (await api.get)<DocumentDetailResponse>(`${serviceUrl}/${documentId}`),
    { enabled: !!documentId }
  );

  const client = useQueryClient();
  const refreshResponse = () => {
    client.invalidateQueries(queryKey);
  };
  return [response, refreshResponse];
}
