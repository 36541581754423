import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";
import { IGraphLog } from "shared/types";
const service = "enterprise/document/graph-logs";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

const PAGE_SIZE = 10;

interface GraphLogsResponse {
  data: { logs: IGraphLog[] };
}

export interface GraphLogsInfiniteResponse {
  data: {
    logs: IGraphLog[];
    pagination: {
      page: number;
      size: number;
      filtered: number;
      total: number;
    };
  };
}

export interface RequestPayload {
  docId: string | null;
}

export function useGraphLogsApi(
  documentId: string | null
): [UseQueryResult<GraphLogsResponse, Error>, () => void] {
  const queryKey = `document-graph-logs-${documentId}`;
  const response = useQuery<GraphLogsResponse, Error>(
    queryKey,
    async () =>
      (await api.get)<GraphLogsResponse>(`${serviceUrl}/${documentId}`),
    { enabled: !!documentId }
  );

  const client = useQueryClient();
  const refreshResponse = () => {
    client.invalidateQueries(queryKey);
  };
  return [response, refreshResponse];
}

export function useGraphLogsInfiniteApi({
  docId,
}: RequestPayload): [
  UseInfiniteQueryResult<GraphLogsInfiniteResponse, Error>,
  () => Promise<void>
] {
  const queryKey = `document-graph-logs-infinite-${docId}`;
  const response = useInfiniteQuery<GraphLogsInfiniteResponse, Error>(
    queryKey,
    async ({ pageParam = 1 }) => {
      const payload = {
        pageNumber: pageParam,
        pageSize: PAGE_SIZE,
      };

      return (await api.get)<GraphLogsInfiniteResponse>(
        `${serviceUrl}/${docId}`,
        payload
      );
    },

    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.data?.logs && lastPage?.data?.logs?.length >= PAGE_SIZE
            ? allPages?.length + 1
            : undefined;
        return nextPage;
      },
      enabled: !!docId,
    }
  );

  const queryClient = useQueryClient();

  const resetInfiniteQueryPagination = async (): Promise<void> => {
    queryClient.setQueryData<
      InfiniteData<GraphLogsInfiniteResponse> | undefined
    >(queryKey, (oldData) => {
      if (!oldData) return undefined;
      return {
        ...oldData,
        pages: oldData.pages.slice(0, 1), // Keep only the first page
        pageParams: oldData.pageParams.slice(0, 1), // Reset pageParams to the first page
      };
    });

    await queryClient.invalidateQueries(queryKey); // Refetch the first page
  };

  return [response, resetInfiniteQueryPagination];
}
