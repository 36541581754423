import React from "react";
import { FlexBox, Text } from "mool-design/src";
import styled, { keyframes } from "styled-components";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const dotFlashing = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ThinkingText = styled(Text)`
  &:after {
    content: "...";
    display: inline-block;
    animation: ${dotFlashing} 1.5s infinite linear;
    font-size: 24px;
  }
`;

const StyledMarkdownContainer = styled(FlexBox)`
  flex-direction: column;
  color: #ffffff;
  font-size: 14px;
`;

function ChatBox({
  label,
  content,
  isLoading,
}: {
  label: string;
  content: string;
  isLoading?: boolean;
}): JSX.Element {
  const customRenderers = {
    h1: ({ children }: { children: React.ReactNode }) => (
      <Text color={"text1"} fontSize={1}>
        {children}
      </Text>
    ),
    h2: ({ children }: { children: React.ReactNode }) => (
      <Text color={"text1"} fontSize={1}>
        {children}
      </Text>
    ),
    h3: ({ children }: { children: React.ReactNode }) => (
      <Text color={"text1"} fontSize={1}>
        {children}
      </Text>
    ),
    h4: ({ children }: { children: React.ReactNode }) => (
      <Text color={"text1"} fontSize={1}>
        {children}
      </Text>
    ),
    h5: ({ children }: { children: React.ReactNode }) => (
      <Text color={"text1"} fontSize={1}>
        {children}
      </Text>
    ),
    h6: ({ children }: { children: React.ReactNode }) => (
      <Text color={"text1"} fontSize={1}>
        {children}
      </Text>
    ),
    strong: ({ children }: { children: React.ReactNode }) => (
      <Text fontWeight={"bold"} color={"text1"} fontSize={1}>
        {children}
      </Text>
    ),
    p: ({ children }: { children: React.ReactNode }) => (
      <Text color={"text1"} fontSize={1}>
        {children}
      </Text>
    ),
    a: ({ href, children }: { href: string; children: React.ReactNode }) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={href}
        style={{ color: "yellow", textDecoration: "underline" }}
      >
        {children}
      </a>
    ),
  };
  if (isLoading) {
    return (
      <FlexBox flexDirection={"column"}>
        <FlexBox>
          <Text fontSize={1} fontWeight={"bold"} color={"text3"}>
            {label}
          </Text>
        </FlexBox>
        <FlexBox>
          <ThinkingText color={"brand"}>{content}</ThinkingText>
        </FlexBox>
      </FlexBox>
    );
  }
  return (
    <FlexBox flexDirection={"column"}>
      <FlexBox>
        <Text fontSize={1} fontWeight={"bold"} color={"text3"}>
          {label}
        </Text>
      </FlexBox>
      <StyledMarkdownContainer>
        <Markdown remarkPlugins={[remarkGfm]} components={customRenderers}>
          {content}
        </Markdown>
      </StyledMarkdownContainer>
    </FlexBox>
  );
}

export default ChatBox;
