import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";
import { IUserInvitation } from "shared/types";

const service = "organization/invite";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface RequestPayload {
  email: string;
  role: string;
}

export interface UserInvitationResponse {
  data: {
    invitation: IUserInvitation;
  };
}

export function useUserInvitationApi(): UseMutationResult<
  UserInvitationResponse,
  unknown,
  RequestPayload,
  unknown
> {
  return useMutation((payload: RequestPayload) => {
    return api.post<UserInvitationResponse>(serviceUrl, payload);
  });
}
