import { usePurposeListApi } from "shared/api/v3/listing/designation/get";
import { useDesignationListApi } from "shared/api/v3/listing/purpose/get";
import {
  useOnboardingApi,
  RequestPayload,
} from "shared/api/v3/user/onboarding/post";

export function useOnboarding() {
  const { mutate, isLoading, data, isError, error } = useOnboardingApi();

  const handleOnboarding = (payload: RequestPayload, callback: () => void) => {
    mutate(payload, {
      onSuccess: () => {
        callback();
      },
    });
  };

  return {
    handleOnboarding,
    isLoading,
    data,
    isError,
    errorText: error?.data?.error?.text || "",
  };
}

export function usePurposeList() {
  const [{ data, isLoading }] = usePurposeListApi();

  const purposeRes =
    data?.data?.purpose && data?.data?.purpose?.length > 0
      ? data?.data?.purpose
      : [];
  const purposeList = purposeRes.map((p) => ({ key: p.key, label: p.value }));

  return {
    purposeList,
    isLoading,
  };
}

export function useDesignationList() {
  const [{ data, isLoading }] = useDesignationListApi();

  const designationRes =
    data?.data?.designation && data?.data?.designation?.length > 0
      ? data?.data?.designation
      : [];
  const designationList = designationRes.map((d) => ({
    key: d.key,
    label: d.value,
  }));

  return {
    designationList,
    isLoading,
  };
}
