import React, { useState } from "react";
import { Button, FlexBox, Input, Text, View } from "mool-design/src";
import { useFirebaseLogin } from "shared/modules/auth-module/hooks";
import { useSearchParams } from "react-router-dom";
import { Eye, EyeSlash } from "@phosphor-icons/react";

function ForgotPassword(): JSX.Element {
  const [searchParams] = useSearchParams();

  const { handlePasswordReset } = useFirebaseLogin();

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [isErrorPassword1, setIsErrorPassword1] = useState(false);
  const [isErrorPassword2, setIsErrorPassword2] = useState(false);
  const [errorText1, setErrorText1] = useState("");
  const [errorText2, setErrorText2] = useState("");

  const resetError = () => {
    setErrorText1("");
    setErrorText2("");
    setIsErrorPassword1(false);
    setIsErrorPassword2(false);
  };

  const handleSetPassword1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetError();
    const value = e.target.value;
    setPassword1(value);
  };

  const handleSetPassword2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetError();
    const value = e.target.value;
    setPassword2(value);
  };

  const callback = () => {
    window.location.href = "/login";
  };

  const handleResetPassword = () => {
    const queryParams = new URLSearchParams(searchParams);
    const obCode = queryParams.get("obCode") || "";

    resetError();
    handlePasswordReset({
      password: password1,
      obCode,
      callback,
    });
  };

  return (
    <View position="fixed">
      <FlexBox
        flex={1}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
        width={"400px"}
      >
        <FlexBox width={"100%"} flexDirection={"column"}>
          <FlexBox mb={8}>
            <Text fontSize={4} fontWeight={"regular"} color={"text1"}>
              Reset your{" "}
              <Text fontSize={4} fontWeight={"regular"} color={"brand"}>
                password
              </Text>
            </Text>
          </FlexBox>
          <FlexBox mb={5}>
            <Input
              placeholderText="Enter your password"
              value={password1}
              onChange={handleSetPassword1}
              type={showPassword1 ? "text" : "password"}
              errorText={errorText1}
              isError={isErrorPassword1}
              icon={
                showPassword1 ? (
                  <FlexBox
                    onClick={() => setShowPassword1(false)}
                    style={{ cursor: "pointer" }}
                    pr={2}
                  >
                    <Eye size={18} color={"#b9b9c7"} />
                  </FlexBox>
                ) : (
                  <FlexBox
                    onClick={() => setShowPassword1(true)}
                    style={{ cursor: "pointer" }}
                    pr={2}
                  >
                    <EyeSlash size={18} color={"#b9b9c7"} />
                  </FlexBox>
                )
              }
            />
          </FlexBox>
          <FlexBox mb={5}>
            <Input
              placeholderText="Re-enter your password"
              value={password2}
              onChange={handleSetPassword2}
              type={showPassword2 ? "text" : "password"}
              errorText={errorText2}
              isError={isErrorPassword2}
              icon={
                showPassword2 ? (
                  <FlexBox
                    onClick={() => setShowPassword2(false)}
                    style={{ cursor: "pointer" }}
                    pr={2}
                  >
                    <Eye size={18} color={"#b9b9c7"} />
                  </FlexBox>
                ) : (
                  <FlexBox
                    onClick={() => setShowPassword2(true)}
                    style={{ cursor: "pointer" }}
                    pr={2}
                  >
                    <EyeSlash size={18} color={"#b9b9c7"} />
                  </FlexBox>
                )
              }
            />
          </FlexBox>
          <FlexBox>
            <Button
              label="Continue"
              onClick={handleResetPassword}
              background="#635C1D"
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </View>
  );
}

export default ForgotPassword;
