import React from "react";

import { Button, FileIcon, FlexBox, Text } from "mool-design/src";
import styled from "styled-components";
import {
  FileDashed,
  PlusCircle,
  Trash,
  UploadSimple,
} from "@phosphor-icons/react";
import { getFullYearDateFormat } from "shared/utils/date-formatter";

import { useDropzone } from "react-dropzone";
import { SUPPORTED_FORMATS } from "../../../constants";
import { useFileUploadInput } from "shared/modules/editor/hooks";
import { formatFileSize } from "shared/utils/size-formatter";

const LABEL_INDICATORS = [
  ".pdf",
  ".csv",
  ".docx",
  ".text",
  ".json",
  ".pptx",
  ".png",
];

const StyledFormatContainer = styled(FlexBox)`
  padding: 4px 8px;
  margin: 0px 4px;
  background-color: #1e1e24;
  border-radius: 4px;
`;

const StyledUploadAreaContainer = styled(FlexBox)<{ isDragActive: boolean }>`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  border: 1px dashed
    ${(props) => (props.isDragActive ? "#635C1D" : "transparent")};
  background-color: ${(props) =>
    props.isDragActive ? "#111114" : "transparent"};
  border-radius: 10px;
`;

const StyledUploadFilesContainer = styled(FlexBox)`
  flex-direction: column;
  border-radius: 10px;
  overflow-y: scroll;
  height: 300px;
`;

const StyledFileContainer = styled(FlexBox)`
  padding: 8px 10px;
  border-radius: 10px;
  justify-content: space-between;
  border: 1px solid #26262e;
  align-items: center;
`;

const Separator = styled.span`
  margin: 0 4px;
  &::before {
    content: "•";
    font-size: 16px;
    color: #b9b9c7;
  }
`;

interface Props {
  handleUpload: () => void;
}

function FormatLabel({ label }: { label: string }): JSX.Element {
  return (
    <StyledFormatContainer>
      <Text fontSize={0} fontWeight={"bold"} color={"text1"}>
        {label}
      </Text>
    </StyledFormatContainer>
  );
}

function UploadNewFiles({ handleUpload }: Props): JSX.Element {
  const { selectedFiles, handleFileAdd, handleFileRemove } =
    useFileUploadInput();

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) handleFileAdd(acceptedFiles);
    return null;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    noDragEventsBubbling: true,
    accept: SUPPORTED_FORMATS,
  });

  return (
    <FlexBox flexDirection={"column"} flex={1} justifyContent={"space-between"}>
      <FlexBox flexDirection={"column"} flex={1}>
        <FlexBox mt={5}>
          <Text fontSize={4} fontWeight={"bold"} color={"text1"}>
            Upload Documents to Reference
          </Text>
        </FlexBox>
        <FlexBox mt={2}>
          <Text fontSize={1} fontWeight={"regular"} color={"text3"}>
            Uploaded files will be encrypted and vectorised for use within this
            document only.
          </Text>
        </FlexBox>
        <FlexBox mt={2} alignItems={"center"}>
          <FlexBox>
            <Text fontSize={1} fontWeight={"regular"} color={"text3"}>
              Accepted formats
            </Text>
          </FlexBox>
          <FlexBox>
            {LABEL_INDICATORS.map((label) => (
              <FormatLabel label={label} key={label} />
            ))}
          </FlexBox>
        </FlexBox>
        {selectedFiles.length > 0 ? (
          <StyledUploadFilesContainer className={"hide-scroll-bar"} mt={4}>
            {selectedFiles.map((file) => (
              <StyledFileContainer key={file.name} my={1}>
                <FlexBox alignItems={"center"} flex={1}>
                  <FlexBox mr={1}>
                    <FileIcon color={"#FFE600"} size={22} type={file.type} />
                  </FlexBox>
                  <FlexBox flexDirection={"column"}>
                    <FlexBox>
                      <Text fontSize={1} fontWeight={"bold"} color={"text1"}>
                        {file.name}
                      </Text>
                    </FlexBox>
                    <FlexBox alignItems={"center"}>
                      <Text fontSize={0} fontWeight={"regular"} color={"text2"}>
                        {formatFileSize(file.size)}
                      </Text>
                      <Separator />
                      <Text fontSize={0} fontWeight={"regular"} color={"text2"}>
                        {getFullYearDateFormat(file.lastModified)}
                      </Text>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
                <FlexBox flex={1} maxWidth={"200px"}>
                  <Button
                    label={"Remove"}
                    background="#1E1E24"
                    icon={<Trash size={16} color={"#ffffff"} />}
                    onClick={() => handleFileRemove(file.name)}
                  />
                </FlexBox>
              </StyledFileContainer>
            ))}
          </StyledUploadFilesContainer>
        ) : (
          <StyledUploadAreaContainer
            mt={2}
            isDragActive={isDragActive}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <FlexBox>
              <FileDashed size={32} color="#635C1D" />
            </FlexBox>
            <FlexBox width={"45%"}>
              <Text
                fontSize={1}
                fontWeight={"regular"}
                color={"text3"}
                textAlign={"center"}
              >
                Drag & drop files from your system or use the button below to
                upload documents
              </Text>
            </FlexBox>
            <FlexBox mt={2}>
              <Button
                label={"Upload from Computer"}
                background="#635C1D"
                icon={<UploadSimple size={16} color={"#ffffff"} />}
                onClick={() => null}
              />
            </FlexBox>
          </StyledUploadAreaContainer>
        )}
      </FlexBox>
      <FlexBox justifyContent={"space-between"} mt={4}>
        <FlexBox>
          {selectedFiles.length > 0 && (
            <FlexBox {...getRootProps()}>
              <Button
                label={"Add more files"}
                background="#635C1D"
                icon={<PlusCircle size={16} color={"#ffffff"} />}
                onClick={() => null}
              />
            </FlexBox>
          )}
        </FlexBox>
        <FlexBox>
          <Button
            label={"Upload Now"}
            background={selectedFiles.length > 0 ? "#1D1293" : "#878794"}
            onClick={selectedFiles.length > 0 ? handleUpload : () => null}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default UploadNewFiles;
