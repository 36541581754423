import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import { confirmAlert } from "react-confirm-alert"; // Import the confirmation dialog
import { Button, ConfirmPopup, FileIcon, FlexBox, Text } from "mool-design/src";
import styled from "styled-components";
import {
  FileDashed,
  PlusCircle,
  Trash,
  UploadSimple,
} from "@phosphor-icons/react";
import { useDropzone } from "react-dropzone";
import { SUPPORTED_FORMATS } from "../../../constants";
import { getFullYearDateFormat } from "shared/utils/date-formatter";
import { useFileUploadInput } from "shared/modules/dashboard/hooks";
import { formatFileSize } from "shared/utils/size-formatter";

const LABEL_INDICATORS = [
  ".pdf",
  ".csv",
  ".docx",
  ".text",
  ".json",
  ".pptx",
  ".png",
];

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
}

const customStyles = {
  content: {
    top: "25%",
    left: "50%",
    width: "50%",
    maxWidth: "720px",
    height: "60%",
    transform: "translate(-50%, -5%)", // Center the modal horizontally
    padding: 0,
    backgroundColor: "#17171C",
    borderRadius: "20px",
    border: "0px",
    boxShadow: "0px 8px 16px 16px #0000006B",
  },
  overlay: {
    backgroundColor: "transparent",
  },
};

const StyledContainer = styled(FlexBox)`
  flex: 1;
  padding: 30px;
  border-radius: 20px;
  flex-direction: column;
  height: 88%;
`;

const StyledFormatContainer = styled(FlexBox)`
  padding: 4px 8px;
  margin: 0px 4px;
  background-color: #1e1e24;
  border-radius: 4px;
`;

const StyledUploadAreaContainer = styled(FlexBox)<{ isDragActive: boolean }>`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  border: 1px dashed
    ${(props) => (props.isDragActive ? "#635C1D" : "transparent")};
  background-color: ${(props) =>
    props.isDragActive ? "#111114" : "transparent"};
  border-radius: 10px;
`;

const StyledUploadFilesContainer = styled(FlexBox)`
  flex-direction: column;
  flex: 1;
  border-radius: 10px;
  overflow-y: scroll;
`;

const StyledFileContainer = styled(FlexBox)`
  padding: 8px 10px;
  border-radius: 10px;
  justify-content: space-between;
  border: 1px solid #26262e;
  align-items: center;
`;

const Separator = styled.span`
  margin: 0 4px;
  &::before {
    content: "•";
    font-size: 16px;
    color: #b9b9c7;
  }
`;

function FormatLabel({ label }: { label: string }): JSX.Element {
  return (
    <StyledFormatContainer>
      <Text fontSize={0} fontWeight={"bold"} color={"text1"}>
        {label}
      </Text>
    </StyledFormatContainer>
  );
}

function FileUploadModal({ isModalOpen, closeModal }: Props): JSX.Element {
  const [isDirty, setIsDirty] = useState(false);

  const { selectedFiles, handleFileAdd, handleFileRemove, removeAllFiles } =
    useFileUploadInput();

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) handleFileAdd(acceptedFiles);
    return null;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    noDragEventsBubbling: true,
    accept: SUPPORTED_FORMATS,
  });

  const handleClose = () => {
    if (isDirty) {
      confirmAlert({
        overlayClassName: "custom-confirm-alert",
        customUI: ({ onClose }) => {
          return (
            <ConfirmPopup
              title="Are you sure you want to close the upload modal?"
              subTitle="You will lose the progress so far."
              closeModal={() => {
                removeAllFiles();
                closeModal();
              }}
              onClose={onClose}
            />
          );
        },
      });
    } else {
      removeAllFiles();
      closeModal();
    }
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [selectedFiles.length]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      style={customStyles}
    >
      <StyledContainer>
        <FlexBox>
          <Text fontSize={4} fontWeight={"bold"} color={"text1"}>
            Upload Documents to Reference
          </Text>
        </FlexBox>
        <FlexBox mt={2}>
          <Text fontSize={1} fontWeight={"regular"} color={"text3"}>
            Uploaded files will be encrypted and vectorised for use within this
            document only.
          </Text>
        </FlexBox>
        <FlexBox mt={2} alignItems={"center"}>
          <FlexBox>
            <Text fontSize={1} fontWeight={"regular"} color={"text3"}>
              Accepted formats
            </Text>
          </FlexBox>
          <FlexBox>
            {LABEL_INDICATORS.map((label) => (
              <FormatLabel label={label} key={label} />
            ))}
          </FlexBox>
        </FlexBox>
        {selectedFiles.length > 0 ? (
          <StyledUploadFilesContainer className={"hide-scroll-bar"} mt={4}>
            {selectedFiles.map((file) => (
              <StyledFileContainer key={file.name} my={1}>
                <FlexBox alignItems={"center"}>
                  <FlexBox mr={1}>
                    <FileIcon color={"#FFE600"} size={22} type={file.type} />
                  </FlexBox>
                  <FlexBox flexDirection={"column"}>
                    <Text fontSize={1} fontWeight={"bold"} color={"text1"}>
                      {file.name}
                    </Text>
                    <FlexBox alignItems={"center"}>
                      <Text fontSize={0} fontWeight={"regular"} color={"text2"}>
                        {formatFileSize(file.size)}
                      </Text>
                      <Separator />
                      <Text fontSize={0} fontWeight={"regular"} color={"text2"}>
                        {getFullYearDateFormat(file.lastModified)}
                      </Text>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
                <FlexBox>
                  <Button
                    label={"Remove"}
                    background="#1E1E24"
                    icon={<Trash size={16} color={"#ffffff"} />}
                    onClick={() => handleFileRemove(file.name)}
                  />
                </FlexBox>
              </StyledFileContainer>
            ))}
          </StyledUploadFilesContainer>
        ) : (
          <StyledUploadAreaContainer
            mt={2}
            isDragActive={isDragActive}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <FlexBox>
              <FileDashed size={32} color="#635C1D" />
            </FlexBox>
            <FlexBox width={"45%"}>
              <Text
                fontSize={1}
                fontWeight={"regular"}
                color={"text3"}
                textAlign={"center"}
              >
                Drag & drop files from your system or use the button below to
                upload documents
              </Text>
            </FlexBox>
            <FlexBox mt={2}>
              <Button
                label={"Upload from Computer"}
                background="#635C1D"
                icon={<UploadSimple size={16} color={"#ffffff"} />}
                onClick={() => null}
              />
            </FlexBox>
          </StyledUploadAreaContainer>
        )}

        <FlexBox justifyContent={"space-between"} mt={2}>
          <FlexBox>
            {selectedFiles.length > 0 && (
              <FlexBox {...getRootProps()}>
                <Button
                  label={"Add more files"}
                  background="#635C1D"
                  icon={<PlusCircle size={16} color={"#ffffff"} />}
                  onClick={() => null}
                />
              </FlexBox>
            )}
          </FlexBox>
          <FlexBox>
            <Button
              label={"Done"}
              background={selectedFiles.length > 0 ? "#1D1293" : "#878794"}
              onClick={selectedFiles.length > 0 ? closeModal : () => null}
            />
          </FlexBox>
        </FlexBox>
      </StyledContainer>
    </Modal>
  );
}

export default FileUploadModal;
