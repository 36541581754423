import {
  Colors,
  TextColors,
  IconColors,
  AllColors,
  //   OutlineColors,
  //   SurfaceColors,
} from "./types";

const palette: Colors = {
  "neutral-grey": {
    0: "#111114",
    50: "#17171C",
    100: "#1E1E24",
    200: "#26262E",
    300: "#3B3B47",
    400: "#565661",
    500: "#6E6E7A",
    600: "#878794",
    700: "#B9B9C7",
    800: "#D3D3E0",
    900: "#EDEDFA",
    1000: "#FAFAFF",
  },
  "brand-primary": {
    0: "#111114",
    50: "#17171C",
    100: "#1E1E24",
    200: "#26262E",
    300: "#1D1293",
    400: "#565661",
    500: "#1D1293",
    600: "#878794",
    700: "#B9B9C7",
    800: "#392CC1",
    900: "#EDEDFA",
    1000: "#FAFAFF",
  },
  "brand-secondary": {
    0: "#111114",
    50: "#17171C",
    100: "#1E1E24",
    200: "#635C1D",
    300: "#958A24",
    400: "#565661",
    500: "#FFE600",
    600: "#878794",
    700: "#B9B9C7",
    800: "#392CC1",
    900: "#EDEDFA",
    1000: "#FAFAFF",
  },
  error: {
    0: "#111114",
    50: "#17171C",
    100: "#1E1E24",
    200: "#26262E",
    300: "#FFE600",
    400: "#565661",
    500: "#DF5050",
    600: "#878794",
    700: "#B9B9C7",
    800: "#392CC1",
    900: "#EDEDFA",
    1000: "#FAFAFF",
  },
  warning: {
    0: "#111114",
    50: "#17171C",
    100: "#1E1E24",
    200: "#26262E",
    300: "#FFE600",
    400: "#565661",
    500: "#FFE600",
    600: "#878794",
    700: "#B9B9C7",
    800: "#392CC1",
    900: "#EDEDFA",
    1000: "#FAFAFF",
  },
  success: {
    0: "#111114",
    50: "#17171C",
    100: "#1E1E24",
    200: "#26262E",
    300: "#958A24",
    400: "#565661",
    500: "#00BC62",
    600: "#878794",
    700: "#B9B9C7",
    800: "#392CC1",
    900: "#EDEDFA",
    1000: "#FAFAFF",
  },
  white: "#FFFFFF",
  black: "#000000",
};

const textLight: TextColors = {
  text1: palette.black,
  text2: palette["neutral-grey"][500],
  text3: palette["neutral-grey"][500],
  brand: palette["brand-primary"][500],
  brand2: palette["brand-primary"][300],
  error: palette.error[500],
  warning: palette.warning[500],
  success: palette.success[500],
};

const textDark: TextColors = {
  text1: palette.white,
  text2: palette["neutral-grey"][700],
  text3: palette["neutral-grey"][500],
  brand: palette["brand-secondary"][500],
  brand2: palette["brand-primary"][300],
  error: palette.error[500],
  warning: palette.warning[500],
  success: palette.success[500],
};

const iconLight: IconColors = {
  brand: palette["brand-primary"][500],
  error: palette.error[500],
  warning: palette.warning[500],
  success: palette.success[500],
};

const iconDark: IconColors = {
  brand: palette["brand-primary"][500],
  error: palette.error[500],
  warning: palette.warning[500],
  success: palette.success[500],
};

// const outlineLight: OutlineColors = {
//   outline1: palette["cool-grey"][400],
//   outline2: palette["cool-grey"][300],
//   outline3: palette["cool-grey"][200],
//   brand: palette.brand[500],
//   error: palette.error[600],
//   warning: palette.warning[600],
//   success: palette.success[600],
// };

// const outlineDark: OutlineColors = {
//   outline1: palette["warm-grey"][500],
//   outline2: palette["warm-grey"][600],
//   outline3: palette["warm-grey"][700],
//   brand: palette.brand[400],
//   error: palette.error[400],
//   warning: palette.warning[400],
//   success: palette.success[400],
// };

// const surfaceLight: SurfaceColors = {
//   surface1: palette["cool-grey"][100],
//   surface2: palette.white,
//   surface3: palette["cool-grey"][200],
//   surface4: palette["cool-grey"][900],
// };

// const surfaceDark: SurfaceColors = {
//   surface1: "#080A02",
//   surface2: palette["warm-grey"][800],
//   surface3: palette["warm-grey"][700],
//   surface4: palette["warm-grey"][25],
// };

const colors: AllColors = {
  ...palette,
  text: {
    light: textLight,
    dark: textDark,
  },
  icon: {
    light: iconLight,
    dark: iconDark,
  },
  //   outline: {
  //     light: outlineLight,
  //     dark: outlineDark,
  //   },
  //   surface: {
  //     light: surfaceLight,
  //     dark: surfaceDark,
  //   },
};

export default colors;

export const colorPalette = palette;
