import React from "react";
import {
  BasicTextStyleButton,
  BlockTypeSelect,
  ColorStyleButton,
  CreateLinkButton,
  FileCaptionButton,
  FileReplaceButton,
  FormattingToolbar,
  FormattingToolbarController,
  NestBlockButton,
  TextAlignButton,
  UnnestBlockButton,
} from "@blocknote/react";

import InstructionButton from "./instruction-button";
import FloatingCommentBox from "./comment-box";
import { FlexBox } from "mool-design/src";

interface Props {
  showCommentBoxHandler: () => void;
  handleCommentSubmit: (arg: string) => void;
  showCommentBox: boolean;
  isLoading?: boolean;
  instruction: string;
}

function FloatingToolbar({
  showCommentBoxHandler,
  handleCommentSubmit,
  showCommentBox,
  isLoading,
  instruction,
}: Props): JSX.Element {
  return (
    <FormattingToolbarController
      formattingToolbar={() => (
        <FlexBox flexDirection={"column"}>
          <FlexBox>
            <FormattingToolbar>
              <InstructionButton
                key={"instructionButton"}
                onClickHandler={showCommentBoxHandler}
              />
              <BlockTypeSelect key={"blockTypeSelect"} />

              <FileCaptionButton key={"fileCaptionButton"} />
              <FileReplaceButton key={"replaceFileButton"} />

              <BasicTextStyleButton
                basicTextStyle={"bold"}
                key={"boldStyleButton"}
              />
              <BasicTextStyleButton
                basicTextStyle={"italic"}
                key={"italicStyleButton"}
              />
              <BasicTextStyleButton
                basicTextStyle={"underline"}
                key={"underlineStyleButton"}
              />
              <BasicTextStyleButton
                basicTextStyle={"strike"}
                key={"strikeStyleButton"}
              />
              {/* Extra button to toggle code styles */}
              <BasicTextStyleButton
                key={"codeStyleButton"}
                basicTextStyle={"code"}
              />

              <TextAlignButton
                textAlignment={"left"}
                key={"textAlignLeftButton"}
              />
              <TextAlignButton
                textAlignment={"center"}
                key={"textAlignCenterButton"}
              />
              <TextAlignButton
                textAlignment={"right"}
                key={"textAlignRightButton"}
              />

              <ColorStyleButton key={"colorStyleButton"} />

              <NestBlockButton key={"nestBlockButton"} />
              <UnnestBlockButton key={"unnestBlockButton"} />

              <CreateLinkButton key={"createLinkButton"} />
            </FormattingToolbar>
          </FlexBox>
          <FlexBox>
            {showCommentBox && (
              <FloatingCommentBox
                onSubmit={handleCommentSubmit}
                isLoading={isLoading}
                instruction={instruction}
              />
            )}
          </FlexBox>
        </FlexBox>
      )}
    />
  );
}

export default FloatingToolbar;
