import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";
import { IUser } from "shared/types";

const service = "organization/users/change-role";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface RoleChangeRequestPayload {
  userId: string;
  role: string;
}

export interface UserRoleChangeResponse {
  data: {
    updatedUser: IUser;
  };
}

export function useUserRoleChangeApi(): UseMutationResult<
  UserRoleChangeResponse,
  unknown,
  RoleChangeRequestPayload,
  unknown
> {
  return useMutation((payload: RoleChangeRequestPayload) => {
    return api.post<UserRoleChangeResponse>(serviceUrl, payload);
  });
}
