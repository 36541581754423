import React, { useState } from "react";
import { FlexBox, OptionWrapper, Text } from "mool-design/src";
import styled from "styled-components";

import { DotsThreeVertical } from "@phosphor-icons/react";
import { OptionMenuItem } from "mool-design/src/components/molecules/option-wrapper";
import { useDocumentDelete } from "shared/modules/editor/hooks";
import { useNavigate } from "react-router-dom";
import { IGraphDocument } from "shared/types";
import { formatDateOrTimeDifference } from "shared/utils/date-formatter";
import ConfirmDeleteModal from "./confirm-delete-modal";

const StyledContainer = styled(FlexBox)`
  border-bottom: 1px solid #1e1e24;
  &:hover {
    background-color: #26262e;
  }
  cursor: pointer;
  border-radius: 10px;
`;

const StyledStatusTag = styled(FlexBox)<{ isComplete?: boolean }>`
  padding: 4px 6px;
  border-radius: 4px;
  background-color: ${(props) => (props.isComplete ? "#195F3D" : "#804F21")};
`;

interface Props {
  document: IGraphDocument;
}

function StatusTag({ isComplete }: { isComplete?: boolean }): JSX.Element {
  return (
    <StyledStatusTag isComplete={isComplete}>
      {isComplete ? (
        <Text fontSize={0} fontWeight={"regular"} color={"text1"}>
          Complete
        </Text>
      ) : (
        <Text fontSize={0} fontWeight={"regular"} color={"text1"}>
          In Progress
        </Text>
      )}
    </StyledStatusTag>
  );
}

function FileCard({ document }: Props): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleFileNavigation = () => {
    navigate(`/document/${document._id}`);
  };

  const { handleDocumentDelete } = useDocumentDelete();

  const deleteDocument = () => {
    setIsModalOpen(false);
    handleDocumentDelete({ docId: document._id });
  };

  const deleteDocumentConfirm = () => {
    setIsModalOpen(true);
  };

  const handleOptionSelection = (item: OptionMenuItem) => {
    if (item.key === "view") {
      handleFileNavigation();
    } else if (item.key === "delete") {
      deleteDocumentConfirm();
    }
  };

  return (
    <StyledContainer
      justifyContent={"space-between"}
      alignItems={"center"}
      p={2}
      mb={2}
    >
      <ConfirmDeleteModal
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        deleteDocument={deleteDocument}
        docName={document.documentName}
      />
      <FlexBox flexDirection={"column"} onClick={handleFileNavigation} flex={1}>
        <Text fontSize={1} fontWeight={"bold"} color={"text1"}>
          {document.documentName}
        </Text>
        <Text fontSize={0} fontWeight={"regular"} color={"text2"}>
          {`Last edited ${formatDateOrTimeDifference(
            (document.updatedAt || "")?.toString()
          )}`}
        </Text>
      </FlexBox>
      <FlexBox>
        <FlexBox mr={4}>
          <StatusTag isComplete={document?.status === "completed"} />
        </FlexBox>
        <OptionWrapper
          menuItems={[
            { key: "view", label: "View" },
            { key: "delete", label: "Delete" },
          ]}
          onClick={handleOptionSelection}
        >
          <DotsThreeVertical color="white" size={24} />
        </OptionWrapper>
      </FlexBox>
    </StyledContainer>
  );
}

export default FileCard;
