import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import api from "shared/network/api";
import { API_URL, API_VERSION_V3 } from "shared/network/config";

const service = "listing/purpose";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

interface PurposeListResponse {
  data: {
    purpose: { key: string; value: string }[];
  };
}

export function usePurposeListApi(): [
  UseQueryResult<PurposeListResponse, Error>,
  () => void
] {
  const queryKey = "purpose-list";
  const response = useQuery<PurposeListResponse, Error>(queryKey, async () =>
    (await api.get)<PurposeListResponse>(`${serviceUrl}`)
  );

  const client = useQueryClient();
  const refreshResponse = () => {
    client.invalidateQueries(queryKey);
  };

  return [response, refreshResponse];
}
