import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";
import { IGraphDocumentSettings } from "shared/types/editor";

const service = "enterprise/document";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface RequestPayload {
  docId: string;
  template?: string;
  tools?: string[];
}

export interface DocumentSettingsUpdateResponse {
  data: {
    settings: IGraphDocumentSettings;
  };
}

export function useDocumentSettingsUpdateApi(): UseMutationResult<
  DocumentSettingsUpdateResponse,
  unknown,
  RequestPayload,
  unknown
> {
  return useMutation((payload: RequestPayload) => {
    return api.post<DocumentSettingsUpdateResponse>(
      `${serviceUrl}/${payload.docId}/settings`,
      payload
    );
  });
}
