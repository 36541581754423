import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Modal from "react-modal";

interface Props {
  docs: { uri: string; name: string }[];
  isModalOpen: boolean;
  closeModal: () => void;
}

const customStyles = {
  content: {
    top: "15%",
    left: "50%",
    width: "60%", // Set the width to 80%
    height: "85%",
    transform: "translate(-50%, -5%)", // Center the modal horizontally
    padding: 0,
    backgroundColor: "#17171C",
    borderRadius: "20px",
    border: "0px",
    boxShadow: "0px 8px 16px 16px #0000006B",
  },
  overlay: {
    backgroundColor: "transparent",
  },
};

function DocViewModal({ docs, isModalOpen, closeModal }: Props): JSX.Element {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <DocViewer
        prefetchMethod="GET"
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        theme={{
          primary: "black",
          secondary: "black",
          tertiary: "black",
          textPrimary: "white",
          textSecondary: "white",
          textTertiary: "white",
        }}
      />
    </Modal>
  );
}

export default DocViewModal;
