import { useGlobalState } from "shared/global-state";
import { useUserLogout } from "../user/logout";

export function useAuth() {
  const { globalState, setGlobalState } = useGlobalState();
  const { handleLogout } = useUserLogout();

  const setIsLoggedIn = (value: boolean) => {
    setGlobalState({ isLoggedIn: value });
  };

  const logoutCallback = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    window.location.href = "/login";
  };

  const logoutUser = async () => {
    await handleLogout(logoutCallback);
  };

  const setTokenToLocalStorage = ({
    access_token,
    refresh_token,
  }: {
    access_token: string;
    refresh_token: string;
  }) => {
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("refresh_token", refresh_token);
  };

  return {
    isLoggedIn: globalState.isLoggedIn,
    setIsLoggedIn,
    logoutUser,
    setTokenToLocalStorage,
  };
}
