import React, { useEffect, useRef, useState } from "react";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import styled from "styled-components";
import FloatingToolbar from "./floating-toolbar";
import { useDataRoomOutputEdit } from "shared/modules/editor/hooks/doc";
import {
  Button,
  FlexBox,
  Input,
  SkeletonLoader,
  Spinner,
  Text,
} from "mool-design/src";
import { formatDateOrTimeDifference } from "shared/utils/date-formatter";
import {
  CheckCircle,
  CloudArrowUp,
  Download,
  Pencil,
} from "@phosphor-icons/react";
import {
  useDocumentDetail,
  useDocumentExport,
  useDocumentSave,
  useDocumentStatusStream,
} from "shared/modules/editor/hooks";
import MoolAiBrandLogo from "theme/assets/images/mool-ai-brand-logo.png";
import { useShowLog } from "shared/modules/editor/hooks/logs";

const StyledContainer = styled(FlexBox)`
  flex-direction: column;
  flex: 1;
`;

const StyledNavbar = styled(FlexBox)`
  background-color: transparent;
  padding: 20px 0px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  flex: 1;
`;

const EditorWrapper = styled(FlexBox)<{ showLog: boolean }>`
  height: calc(100vh - 188px);
  border-radius: 8px;
  width: 100%; /* Ensure it takes 100% of its parent's width */
  overflow-x: scroll; /* Prevents horizontal scroll */
  overflow-wrap: break-word; /* Ensures long content wraps correctly */
  box-sizing: border-box; /* Ensures padding doesn't add to the width */
  background-color: #1f1f1f;
  flex-direction: column;
  max-width: ${(props) =>
    props.showLog ? "calc(100vw - 720px)" : "calc(100vw - 480px)"};
`;

function MarkdownEditor() {
  const { showLog } = useShowLog();
  const editor = useCreateBlockNote();
  const { event } = useDocumentStatusStream();
  const containerRef = useRef<HTMLDivElement>(null);

  const [showCommentBox, setShowCommentBox] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [comment, setComment] = useState("");

  const [originalDocName, setOriginalDocName] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [isDocNameEditable, setIsDocNameEditable] = useState(false);

  const {
    document: documentDetail,
    isLoading,
    refreshDocumentDetail,
  } = useDocumentDetail();
  const { handleDocumentSave } = useDocumentSave();
  const { exportDocument, isLoading: isExporting } = useDocumentExport();

  const updateEditorInline = (text: string) => {
    editor.insertInlineContent([
      selectedText,
      {
        type: "text",
        text,
        styles: {}, //bold: true, textColor: "yellow"
      },
    ]);
    setComment("");
    setShowCommentBox(false);
  };
  const { handleOutputEdit, isLoading: isLlmLoading } =
    useDataRoomOutputEdit(updateEditorInline);

  async function loadInitialHTML() {
    const fileString = documentDetail?.fileString || "";
    const blocks = await editor.tryParseMarkdownToBlocks(fileString);
    editor.replaceBlocks(editor.document, blocks);
    setIsDirty(false);
  }

  useEffect(() => {
    if (documentDetail?._id) {
      setDocumentName(documentDetail?.documentName);
      setOriginalDocName(documentDetail?.documentName);
      loadInitialHTML();
    }
  }, [isLoading, documentDetail?._id, documentDetail?.fileString]);

  const onSaveHandler = async () => {
    const markdown = await editor.blocksToMarkdownLossy(editor.document);
    handleDocumentSave({ fileString: markdown });
    setIsDirty(false);
  };

  const handleCommentSubmit = (instruction: string) => {
    setComment(instruction);
    handleOutputEdit({
      inputText: selectedText,
      instruction,
    });
  };

  const handleShowCommentBox = () => {
    if (showCommentBox) {
      setSelectedText("");
      setShowCommentBox(false);
    } else {
      const text = editor.getSelectedText();
      setSelectedText(text);
      setShowCommentBox(true);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "s") {
        event.preventDefault(); // Prevent the default browser save dialog
        onSaveHandler(); // Your save function
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timer | null = null;
    if (documentDetail?.status !== "completed") {
      interval = setInterval(() => {
        refreshDocumentDetail();
      }, 60000);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }
    // Cleanup function to clear the interval on component unmount
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [documentDetail?.status]);

  const handleDownload = () => {
    exportDocument();
  };

  const handleDocumentName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDocumentName(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevents the default behavior of inserting a newline
      if (documentName) {
        setIsDocNameEditable(false);
        handleDocumentSave({ documentName });
      }
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setDocumentName(originalDocName);
      setIsDocNameEditable(false);
    }
  };

  useEffect(() => {
    if (isDocNameEditable) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDocNameEditable]);

  return (
    <FlexBox flex={1}>
      <StyledContainer>
        <StyledNavbar>
          <FlexBox flexDirection={"column"} width={"350px"}>
            {isDocNameEditable ? (
              <FlexBox flex={1} alignItems={"center"} ref={containerRef}>
                <FlexBox flex={1}>
                  <Input
                    value={documentName}
                    onChange={handleDocumentName}
                    placeholderText={"Enter document name"}
                    onKeyDown={handleKeyDown}
                    isError={!documentName}
                    icon={
                      <FlexBox
                        p={2}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsDocNameEditable(false);
                          handleDocumentSave({ documentName });
                        }}
                      >
                        <CheckCircle
                          weight="duotone"
                          size={22}
                          color={"#b9b9c7"}
                        />
                      </FlexBox>
                    }
                  />
                </FlexBox>
              </FlexBox>
            ) : (
              <FlexBox
                alignItems={"center"}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setIsDocNameEditable(true)}
                // height={'42px'}
              >
                <FlexBox>
                  <Text fontSize={1} fontWeight={"bold"} color={"text1"}>
                    {documentName}
                  </Text>
                </FlexBox>
                <FlexBox ml={1}>
                  <Pencil size={16} color={"#B9B9C7"} />
                </FlexBox>
              </FlexBox>
            )}
            <FlexBox mt={1}>
              {formatDateOrTimeDifference(
                (documentDetail?.createdAt || "").toString()
              ).includes("ago") ? (
                <Text fontSize={0} fontWeight={"regular"} color={"text2"}>
                  Created{" "}
                  {formatDateOrTimeDifference(
                    (documentDetail?.createdAt || "").toString()
                  )}
                </Text>
              ) : (
                <Text fontSize={0} fontWeight={"regular"} color={"text2"}>
                  Created on{" "}
                  {formatDateOrTimeDifference(
                    (documentDetail?.createdAt || "").toString()
                  )}
                </Text>
              )}
            </FlexBox>
          </FlexBox>
          {!isLoading && documentDetail?.status === "completed" && (
            <FlexBox>
              <FlexBox mr={2}>
                <Button
                  label={"Save"}
                  onClick={onSaveHandler}
                  background="#1E1E24"
                  icon={
                    isDirty ? (
                      <CloudArrowUp size={16} color="#FFE600" />
                    ) : (
                      <CloudArrowUp size={16} color="#ffffff" />
                    )
                  }
                  textColor={isDirty ? "brand" : "text1"}
                />
              </FlexBox>
              <FlexBox>
                <Button
                  label={"Download"}
                  onClick={handleDownload}
                  background="#1E1E24"
                  icon={
                    isExporting ? (
                      <Spinner size={10} />
                    ) : (
                      <Download size={16} color="#ffffff" />
                    )
                  }
                />
              </FlexBox>
            </FlexBox>
          )}
        </StyledNavbar>
        <EditorWrapper showLog={showLog}>
          {documentDetail?.status === "completed" ? (
            <BlockNoteView
              editor={editor}
              formattingToolbar={false}
              theme={"dark"}
              onChange={() => {
                setIsDirty(true);
              }}
              style={{
                width: "100%",
                padding: "20px 0px",
              }}
            >
              <FloatingToolbar
                showCommentBox={showCommentBox}
                showCommentBoxHandler={handleShowCommentBox}
                handleCommentSubmit={handleCommentSubmit}
                isLoading={isLlmLoading}
                instruction={comment}
              />
            </BlockNoteView>
          ) : (
            <FlexBox p={8} flexDirection={"column"}>
              <FlexBox
                p={3}
                style={{ borderRadius: "10px", backgroundColor: "#635C1D33" }}
                alignItems={"center"}
              >
                <FlexBox mr={3}>
                  <img
                    src={MoolAiBrandLogo}
                    style={{
                      height: 18,
                    }}
                    alt="MoolAiBrandLogo"
                  />
                </FlexBox>
                <FlexBox>
                  <Text fontSize={1} color={"text1"}>
                    {documentDetail?.startPrompt}
                  </Text>
                </FlexBox>
              </FlexBox>
              {event?.data?.text && (
                <FlexBox
                  style={{ backgroundColor: "#26262E", borderRadius: "10px" }}
                  py={2}
                  px={3}
                  flex={1}
                  mt={4}
                >
                  <FlexBox mr={2}>
                    <Spinner
                      size={18}
                      color={"#00BC62"}
                      spinColor={"#3B3B47"}
                      borderSize={3}
                    />
                  </FlexBox>
                  <FlexBox>
                    <Text fontSize={0} fontWeight={"regular"} color={"text3"}>
                      {event?.data?.text}
                    </Text>
                  </FlexBox>
                </FlexBox>
              )}
              {!event?.data?.text && (
                <FlexBox mt={5}>
                  <SkeletonLoader />
                </FlexBox>
              )}
            </FlexBox>
          )}
        </EditorWrapper>
      </StyledContainer>
    </FlexBox>
  );
}

export default MarkdownEditor;
