import { useEffect, useState } from "react";
import {
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  sendSignInLinkToEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  sendPasswordResetEmail,
  confirmPasswordReset,
  sendEmailVerification,
} from "firebase/auth";
import { FirebaseAuthInstance } from "shared/modules/auth-module/firebase-auth"; // Firebase instance
import { useIdTokenAuthenticate } from "./authenticate";
import { useAuth } from "../index";
import { FIREBASE_REDIRECT_URL } from "shared/config";

export const ERROR_CODE_MAP: { [key: string]: string } = {
  "auth/invalid-email": "Incorrect email or password",
  "auth/invalid-credential": "Incorrect email or password",
  "auth/email-already-in-use": "Email already in use",
  "auth/unauthorized-continue-uri": "Something went wrong",
  "auth/operation-not-allowed": "Something went wrong",
};

export interface SignInError {
  isError: boolean;
  type:
    | "email_password_login"
    | "email_password_signup"
    | "magic_link"
    | "login_with_google"
    | "login_with_apple"
    | "forgot_password";
  message: string;
}

export function useFirebaseLogin(successCallback?: () => void) {
  const { setIsLoggedIn, setTokenToLocalStorage } = useAuth();
  const { handleAuthentication, isLoading, access_token, refresh_token } =
    useIdTokenAuthenticate();

  const [signInErrorState, setSignInErrorState] = useState<SignInError | null>(
    null
  );
  const [isMagicLinkSent, setIsMagicLinkSent] = useState(false);
  const [isForgotPasswordEmailSent, setIsForgotPasswordEmailSent] =
    useState(false);

  const resetErrorState = () => {
    if (signInErrorState !== null) {
      setSignInErrorState(null);
    }
    if (isMagicLinkSent) {
      setIsMagicLinkSent(false);
    }
    if (isForgotPasswordEmailSent) {
      setIsForgotPasswordEmailSent(false);
    }
  };

  useEffect(() => {
    if (access_token && refresh_token) {
      setIsLoggedIn(true);
      setTokenToLocalStorage({ access_token, refresh_token });
      successCallback && successCallback();
    }
  }, [access_token, refresh_token]);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(FirebaseAuthInstance, provider);
      if (result?.user) {
        const idToken = await result.user.getIdToken();
        handleAuthentication(idToken);
      }
    } catch (error) {
      const code = error?.code;
      setSignInErrorState({
        isError: true,
        type: "login_with_google",
        message: ERROR_CODE_MAP[code] || "Something went wrong",
      });
      console.error("Google Sign-In Error:", error);
    }
  };

  const signInWithApple = async () => {
    const provider = new OAuthProvider("apple.com");
    try {
      const result = await signInWithPopup(FirebaseAuthInstance, provider);
      if (result?.user) {
        const idToken = await result.user.getIdToken();
        handleAuthentication(idToken);
      }
    } catch (error) {
      const code = error?.code;
      setSignInErrorState({
        isError: true,
        type: "login_with_apple",
        message: ERROR_CODE_MAP[code] || "Something went wrong",
      });
      console.error("Apple Sign-In Error:", error);
    }
  };

  const signInWithMagicLinkHandler = async (
    email: string,
    callback: () => void
  ) => {
    try {
      const result = await signInWithEmailLink(
        FirebaseAuthInstance,
        email,
        window.location.href
      );
      if (result?.user) {
        const idToken = await result.user.getIdToken();
        handleAuthentication(idToken);
      } else {
        callback();
      }
    } catch (error) {
      console.error("Apple Sign-In Error:", error);
      callback();
    }
  };

  const signInWithMagicLink = async ({ email }: { email: string }) => {
    const actionCodeSettings = {
      url: `${FIREBASE_REDIRECT_URL}/magic_link?email=${email}`,
      handleCodeInApp: true,
    };
    try {
      await sendSignInLinkToEmail(
        FirebaseAuthInstance,
        email,
        actionCodeSettings
      );
      // If it the execution is here then email has been sent
      setIsMagicLinkSent(true);
    } catch (error) {
      const code = error?.code;
      setSignInErrorState({
        isError: true,
        type: "magic_link",
        message: ERROR_CODE_MAP[code] || "Something went wrong",
      });
      console.error("Magic link error:", error);
    }
  };

  const signupWithEmailPassword = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      const result = await createUserWithEmailAndPassword(
        FirebaseAuthInstance,
        email,
        password
      );
      if (result?.user) {
        const idToken = await result.user.getIdToken();
        handleAuthentication(idToken, () => {
          sendEmailVerification(result?.user);
        });
      }
    } catch (error) {
      const code = error?.code;
      setSignInErrorState({
        isError: true,
        type: "email_password_signup",
        message: ERROR_CODE_MAP[code] || "Something went wrong",
      });
      console.error("Sign up with email password error:", error);
    }
  };

  const signInWithEmailPassword = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      const result = await signInWithEmailAndPassword(
        FirebaseAuthInstance,
        email,
        password
      );
      if (result?.user) {
        const idToken = await result.user.getIdToken();
        handleAuthentication(idToken);
      }
    } catch (error) {
      const code = error?.code;
      setSignInErrorState({
        isError: true,
        type: "email_password_login",
        message: ERROR_CODE_MAP[code] || "Something went wrong",
      });
      console.error("Sign ip with email password error:", error);
    }
  };

  const sendPasswordResetEmailLink = async ({ email }: { email: string }) => {
    const actionCodeSettings = {
      // since its being handled by firebase
      url: `${FIREBASE_REDIRECT_URL}/login`,
      handleCodeInApp: true,
    };
    try {
      await sendPasswordResetEmail(
        FirebaseAuthInstance,
        email,
        actionCodeSettings
      );
      setIsForgotPasswordEmailSent(true);
    } catch (error) {
      const code = error?.code;
      setSignInErrorState({
        isError: true,
        type: "forgot_password",
        message: ERROR_CODE_MAP[code] || "Something went wrong",
      });
      console.error("Forgot password error:", error);
    }
  };

  const confirmPasswordResetHandler = async ({
    password,
    obCode,
    callback,
  }: {
    password: string;
    obCode: string;
    callback: () => void;
  }) => {
    try {
      await confirmPasswordReset(FirebaseAuthInstance, obCode, password);
      //  if the execution reaches here then its successful
      callback();
    } catch (error) {
      const code = error?.code;
      setSignInErrorState({
        isError: true,
        type: "forgot_password",
        message: ERROR_CODE_MAP[code] || "Something went wrong",
      });
      console.error("Forgot password reset error:", error);
    }
  };

  const handleGoogleLogin = () => {
    signInWithGoogle();
  };

  const handleAppleLogin = () => {
    signInWithApple();
  };

  const handleMagicLink = ({ email }: { email: string }) => {
    signInWithMagicLink({ email });
  };

  const handleForgotPasswordLink = ({ email }: { email: string }) => {
    sendPasswordResetEmailLink({ email });
  };

  const handlePasswordReset = ({
    password,
    obCode,
    callback,
  }: {
    password: string;
    obCode: string;
    callback: () => void;
  }) => {
    confirmPasswordResetHandler({ password, obCode, callback });
  };

  const handleCreateUserWithEmailPassword = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    signupWithEmailPassword({ email, password });
  };

  const handleSignInWithEmailPassword = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    signInWithEmailPassword({ email, password });
  };

  return {
    handleGoogleLogin,
    handleAppleLogin,
    handleMagicLink,
    handleSignInWithEmailPassword,
    handleCreateUserWithEmailPassword,
    signInWithMagicLinkHandler,
    handleForgotPasswordLink,
    handlePasswordReset,
    isLoading,
    signInErrorState,
    resetErrorState,
    isMagicLinkSent,
    isForgotPasswordEmailSent,
  };
}
