import { useMutation, UseMutationResult } from "react-query";
import api from "shared/network/api";
import { API_URL, API_VERSION_V3 } from "shared/network/config";

const service = "user/logout";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface AuthResponse {
  data: {
    message: string;
  };
}

export function useUserLogoutApi(): UseMutationResult<
  AuthResponse,
  unknown,
  unknown
> {
  return useMutation(() => {
    return api.post<AuthResponse>(serviceUrl, {});
  });
}
