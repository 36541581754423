import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import api from "shared/network/api";
import { API_URL, API_VERSION_V3 } from "shared/network/config";

const service = "listing/designation";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

interface DesignationListResponse {
  data: {
    designation: { key: string; value: string }[];
  };
}

export function useDesignationListApi(): [
  UseQueryResult<DesignationListResponse, Error>,
  () => void
] {
  const queryKey = "designation-list";
  const response = useQuery<DesignationListResponse, Error>(
    queryKey,
    async () => (await api.get)<DesignationListResponse>(`${serviceUrl}`)
  );

  const client = useQueryClient();
  const refreshResponse = () => {
    client.invalidateQueries(queryKey);
  };

  return [response, refreshResponse];
}
