import {
  File,
  FileCsv,
  FileDoc,
  FilePdf,
  FilePng,
  FilePpt,
  FileText,
  IconProps,
} from "@phosphor-icons/react";

interface Props extends IconProps {
  type: string;
}

function FileIcon({ type, ...rest }: Props): JSX.Element {
  if (
    [
      "image/gif",
      "image/tiff",
      "image/jpeg",
      "image/png",
      "image/bmp",
      "image/webp",
    ].includes(type)
  ) {
    return <FilePng {...rest} />;
  } else if (
    type ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    return <FileDoc {...rest} />;
  } else if (
    type ===
    "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  ) {
    return <FilePpt {...rest} />;
  } else if (type === "application/pdf") {
    return <FilePdf {...rest} />;
  }
  if (type === "text/csv") {
    return <FileCsv {...rest} />;
  }
  if (type === "text/plain") {
    return <FileText {...rest} />;
  }
  return <File {...rest} />;
}

export default FileIcon;
