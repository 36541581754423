import React, { useEffect } from "react";
import { FlexBox, View } from "mool-design/src";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import MarkdownEditor from "./markdown-editor";
import QnAModule from "./qna";
import LogsUi from "./logs";
import { useDocId } from "shared/modules/editor/hooks";

const StyledContainer = styled(FlexBox)`
  height: 90vh;
  align-items: center;
  width: 100vw;
`;

function EditorHome(): JSX.Element {
  const { setDocId } = useDocId();
  const params = useParams();
  const docId = params.doc_id || "";

  useEffect(() => {
    setDocId(docId);
    return () => {
      setDocId(null);
    };
  }, [docId]);

  return (
    <View position="fixed">
      <StyledContainer mt={1}>
        <FlexBox>
          <QnAModule />
        </FlexBox>
        <FlexBox flex={1} px={10}>
          <MarkdownEditor />
        </FlexBox>
        <FlexBox>
          <LogsUi />
        </FlexBox>
      </StyledContainer>
    </View>
  );
}

export default EditorHome;
