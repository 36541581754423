import { useUserLogoutApi } from "shared/api/v3/user/logout/post";

export function useUserLogout() {
  const { mutateAsync } = useUserLogoutApi(); // Assuming mutateAsync is available

  const handleLogout = async (callback: () => void) => {
    try {
      await mutateAsync({}); // Wait for the API call to finish
      callback(); // Trigger callback after API call settles
    } catch (error) {
      callback(); // Trigger callback after API call settles
      console.error("Logout failed:", error);
    }
  };

  return { handleLogout };
}
