import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";
import { IGraphFile } from "shared/types";

const service = "enterprise/document/files";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

interface ProjectListResponse {
  data: {
    files: IGraphFile[];
  };
}

export function useFileListApi(
  docId: string | null
): [UseQueryResult<ProjectListResponse, Error>, () => void] {
  const queryKey = `doc-file-list-${docId}`;
  const response = useQuery<ProjectListResponse, Error>(
    queryKey,
    async () => (await api.get)<ProjectListResponse>(`${serviceUrl}/${docId}`),
    { enabled: !!docId }
  );

  const client = useQueryClient();
  const refreshResponse = () => {
    client.invalidateQueries(queryKey);
  };
  return [response, refreshResponse];
}
