import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";

const service = "organization/accept";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface RequestPayload {
  invitationId: string;
}

export interface InvitationAcceptResponse {
  data: {
    magic_link: string;
  };
}

export function useInvitationAcceptApi(): UseMutationResult<
  InvitationAcceptResponse,
  unknown,
  RequestPayload,
  unknown
> {
  return useMutation((payload: RequestPayload) => {
    return api.post<InvitationAcceptResponse>(serviceUrl, payload);
  });
}
