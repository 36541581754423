import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "shared/modules/auth-module";

const PrivateRoutes = () => {
  const { isLoggedIn } = useAuth();

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
