import { useMutation, UseMutationResult } from "react-query";
import { API_URL } from "shared/config";
import pako from "pako";
import { API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";

const service = "enterprise/docgen/edit";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface EditRequestPayload {
  inputText: string;
  instruction: string;
  docId: string;
}

interface OutputEditResponse {
  data: {
    inputText: string;
    instruction: string;
    response: string;
  };
}

export function useDocGenOutputEditApi(): UseMutationResult<
  OutputEditResponse,
  unknown,
  EditRequestPayload,
  unknown
> {
  return useMutation((payload: EditRequestPayload) => {
    const payloadBody = {
      inputText: payload.inputText,
      instruction: payload.instruction,
    };
    const compressedData = pako.deflate(JSON.stringify(payloadBody));

    // Convert compressed data to Base64
    const base64Data = btoa(
      String.fromCharCode.apply(null, new Uint8Array(compressedData))
    );

    return api.post<OutputEditResponse>(`${serviceUrl}/${payload.docId}`, {
      data: base64Data,
    });
  });
}
