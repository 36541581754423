import React, { useState } from "react";
import { FlexBox, Text } from "mool-design/src";
import styled from "styled-components";

const StyledLogText = styled(Text)<{ isExpanded: boolean }>`
  color: #4bb676;
  font-size: 11px;
  font-family: Roboto Mono;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.isExpanded ? "none" : 5)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const StyledText = styled(Text)`
  font-size: 11px;
  font-family: Roboto Mono;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
`;

const MoreButton = styled.span`
  color: #6e6e7a;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 11px;
  &:hover {
    color: #b9b9c7;
    text-decoration: underline;
  }
`;

function LogContent({ content }: { content: string }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <FlexBox alignItems={"flex-start"} flexDirection={"column"}>
      <StyledLogText isExpanded={isExpanded} fontWeight={"regular"}>
        <StyledText color={"text2"} fontWeight={"regular"}>
          [CONTENT]::
        </StyledText>
        {content}
      </StyledLogText>
      {/* Check if content has more than 5 lines */}
      {content.split(".").length > 5 && (
        <MoreButton onClick={toggleExpanded}>
          {isExpanded ? "Show less" : "Show more"}
        </MoreButton>
      )}
    </FlexBox>
  );
}

export default LogContent;
