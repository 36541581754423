import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";

const service = "enterprise/docgen/generate";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface RequestPayload {
  files: File[];
  source: string;
  documentName?: string;
  template?: string;
  projectId?: string;
  startPrompt: string;
  tools: string[];
}

export interface DocgenDocumentGenerateResponse {
  data: {
    text: string;
    uuid: string;
    projectId: string;
    docId: string;
    docName: string;
    timestamp: string;
  };
}

export function useDocumentGenerateApi(): UseMutationResult<
  DocgenDocumentGenerateResponse,
  unknown,
  RequestPayload,
  unknown
> {
  return useMutation((payload: RequestPayload) => {
    const formData = new FormData();
    const files = payload?.files;
    const source = payload?.source;
    const documentName = payload?.documentName;
    const template = payload?.template;
    const projectId = payload?.projectId;
    const startPrompt = payload?.startPrompt;
    const tools = JSON.stringify(payload?.tools);
    if (source) {
      formData.append("source", source);
    }
    if (documentName) {
      formData.append("documentName", documentName);
    }
    if (template) {
      formData.append("template", template);
    }
    if (projectId) {
      formData.append("projectId", projectId);
    }
    if (startPrompt) {
      formData.append("startPrompt", startPrompt);
    }
    formData.append(`tools`, tools);
    files.forEach((file) => {
      formData.append("file", file, file.name);
    });
    return api.postMultiPart<DocgenDocumentGenerateResponse>(
      serviceUrl,
      formData
    );
  });
}
