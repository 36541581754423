import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";
import { IGraphProjectDetail } from "shared/types";

const service = "enterprise/project";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

interface ProjectListResponse {
  data: {
    projects: IGraphProjectDetail[];
  };
}

export function useProjectListApi(): [
  UseQueryResult<ProjectListResponse, Error>,
  () => void
] {
  const queryKey = "project-list";
  const response = useQuery<ProjectListResponse, Error>(queryKey, async () =>
    (await api.get)<ProjectListResponse>(`${serviceUrl}`)
  );

  const client = useQueryClient();
  const refreshResponse = () => {
    client.invalidateQueries(queryKey);
  };
  return [response, refreshResponse];
}
