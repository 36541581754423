import React, { useState } from "react";

import {
  ModuleStateContext,
  InitialState,
} from "shared/modules/editor/module-state";

import EditorHome from "./editor-home";

function Editor(): JSX.Element {
  const [moduleState, setModuleState] = useState(InitialState);

  return (
    <ModuleStateContext.Provider
      value={{ state: moduleState, setState: setModuleState }}
    >
      <EditorHome />
    </ModuleStateContext.Provider>
  );
}

export default Editor;
