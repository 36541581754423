import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";
import { IGraphDocumentSettings } from "shared/types/editor";

const service = "enterprise/document";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

interface DocumentSettingsResponse {
  data: {
    settings: IGraphDocumentSettings;
  };
}

export function useDocumentSettingsApi(
  documentId: string | null
): [UseQueryResult<DocumentSettingsResponse, Error>, () => void] {
  const queryKey = `document-settings-${documentId}`;
  const response = useQuery<DocumentSettingsResponse, Error>(
    queryKey,
    async () =>
      (await api.get)<DocumentSettingsResponse>(
        `${serviceUrl}/${documentId}/settings`
      ),
    { enabled: !!documentId }
  );

  const client = useQueryClient();
  const refreshResponse = () => {
    client.invalidateQueries(queryKey);
  };
  return [response, refreshResponse];
}
