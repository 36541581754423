import React, { useState } from "react";
import {
  Button,
  Dropdown,
  Ellipsis,
  FlexBox,
  Input,
  Text,
} from "mool-design/src";
import styled from "styled-components";
import { FilePlus } from "@phosphor-icons/react";
import FileUploadModal from "./components/file-upload-modal";
import { MenuItem } from "mool-design/src/components/molecules/dropdown";
import ProjectCreateModal from "./components/project-create-modal";
import {
  useDocumentGenerate,
  useDocumentSourceList,
  useFileUploadInput,
  useGenerateDocumentInput,
  useProjectList,
  useTemplateList,
} from "shared/modules/dashboard/hooks";
import { useNavigate } from "react-router-dom";
import UploadLoaderModal from "./components/upload-loader-modal";

const StyledContainer = styled(FlexBox)`
  flex: 1;
  padding: 20px;
  border-radius: 20px;
  background-color: #17171c;
`;

const StyledReferenceDocumentContainer = styled(FlexBox)`
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid #3b3b47;
  justify-content: space-between;
  algin-items: center;
  flex: 1;
  background-color: #1e1e24;
  cursor: pointer;
  position: relative;
`;

function GenerateDocument(): JSX.Element {
  const navigate = useNavigate();

  const { templates } = useTemplateList();
  const { documentSources } = useDocumentSourceList();
  const {
    selectedDocumentSources,
    selectedProject,
    selectedTemplate,
    setSelectedProject,
    setSelectedTemplate,
    setSelectedDocumentSources,
  } = useGenerateDocumentInput();
  const { selectedFiles, removeAllFiles } = useFileUploadInput();
  const { refreshProjectList } = useProjectList();

  const generateDocumentCallback = (docId: string) => {
    removeAllFiles();
    setSelectedProject(null);
    setSelectedTemplate(null);
    setSelectedDocumentSources(null);
    refreshProjectList();
    navigate(`/document/${docId}`);
  };

  const { handleDocumentGenerate, isLoading } = useDocumentGenerate(
    generateDocumentCallback
  );

  const { projectMenuItems } = useProjectList();
  const [isInstantMode] = useState(false);
  const [userPrompt, setUserPrompt] = useState("");
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);

  const [isUserPromptError, setIsUserPromptError] = useState(false);

  const handleUserPrompt = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUserPromptError(false);
    const value = e.target.value;
    setUserPrompt(value);
  };

  const handleProjectSelection = (arg: MenuItem) => {
    if (arg.key === "create_new") {
      setIsProjectModalOpen(true);
    } else {
      setSelectedProject(arg);
    }
  };

  const handleTemplateSelection = (arg: MenuItem) => {
    setSelectedTemplate(arg);
  };

  const handleDocumentSourceSelection = (arg: MenuItem) => {
    setSelectedDocumentSources(arg);
  };

  const createDocument = () => {
    if (userPrompt) {
      const payload = {
        files: selectedFiles,
        source: "enterprise",
        template: selectedTemplate?.label || "",
        projectId: selectedProject?.key || "",
        startPrompt: userPrompt,
        tools: selectedDocumentSources?.map((source) => source.key),
      };
      handleDocumentGenerate(payload);
    } else {
      setIsUserPromptError(true);
    }
  };

  return (
    <StyledContainer flexDirection={"column"}>
      <FileUploadModal
        closeModal={() => setIsUploadModalOpen(false)}
        isModalOpen={isUploadModalOpen}
      />
      <UploadLoaderModal
        isModalOpen={
          isLoading && Array.isArray(selectedFiles) && selectedFiles.length > 0
        }
      />
      <ProjectCreateModal
        closeModal={() => setIsProjectModalOpen(false)}
        isModalOpen={isProjectModalOpen}
      />
      <FlexBox flex={1} justifyContent={"space-between"}>
        <FlexBox>
          <Text fontSize={4} fontWeight={"regular"} color={"text1"}>
            Get started with a{" "}
            <Text fontSize={4} fontWeight={"bold"} color={"text1"}>
              new document
            </Text>
          </Text>
        </FlexBox>
        {/* <FlexBox alignItems={"center"}>
          <Lightning color="#FFE600" size={16} />
          <Text fontSize={1} fontWeight={"bold"} color={"text1"}>
            Instant Mode
          </Text>
          <FlexBox ml={1}>
            <Switch
              onChange={handleToggle}
              checked={isInstantMode}
              onColor="#635C1D"
              offColor="#26262E"
              offHandleColor="#565661"
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={36}
              handleDiameter={18}
            />
          </FlexBox>
        </FlexBox> */}
      </FlexBox>
      <FlexBox mt={5} flex={1}>
        <FlexBox flex={4} mr={3}>
          <Input
            value={userPrompt}
            onChange={handleUserPrompt}
            placeholderText={
              "Provide instructions on content and process for creating your draft"
            }
            variant={"gradient"}
            isError={isUserPromptError}
          />
        </FlexBox>
        <FlexBox flex={1}>
          <Button
            label={"Create Document"}
            onClick={createDocument}
            background="gradient"
          />
        </FlexBox>
      </FlexBox>
      {!isInstantMode && (
        <FlexBox mt={5} flexDirection={"row"}>
          <FlexBox flex={1} mr={4}>
            <Dropdown
              variant={"default"}
              label={"Project"}
              placeholder={"Select a project"}
              iconColor="#635C1D"
              menuItems={[
                ...projectMenuItems,
                { key: "create_new", label: "Create New" },
              ]}
              selectedItem={selectedProject}
              onClick={handleProjectSelection}
              maxHeight="300px"
            />
          </FlexBox>
          <FlexBox flex={1} mr={4}>
            <Dropdown
              variant={"default"}
              label={"Template"}
              placeholder={"Select a Template"}
              iconColor="#635C1D"
              menuItems={templates}
              selectedItem={selectedTemplate}
              onClick={handleTemplateSelection}
              maxHeight="300px"
            />
          </FlexBox>
          <FlexBox flex={1} mr={4}>
            <Dropdown
              variant={"multiselect"}
              label={"Sources"}
              placeholder={"Pick sources"}
              iconColor="#635C1D"
              menuItems={documentSources}
              selectedItems={selectedDocumentSources}
              onClick={handleDocumentSourceSelection}
            />
          </FlexBox>
          <FlexBox flex={1}>
            <FlexBox flexDirection={"column"} flex={1}>
              <Text fontSize={1} color={"text1"} fontWeight={"bold"}>
                Reference Documents
              </Text>
              <StyledReferenceDocumentContainer
                mt={1}
                onClick={() => setIsUploadModalOpen(true)}
              >
                <FlexBox flex={1}>
                  {selectedFiles.length > 0 ? (
                    <Ellipsis
                      title={
                        selectedFiles?.length > 1
                          ? `${selectedFiles.length}+ Files Selected`
                          : selectedFiles[0]?.name
                      }
                    >
                      <Text fontSize={2} color={"text1"} fontWeight={"regular"}>
                        {selectedFiles?.length > 1
                          ? `${selectedFiles.length}+ Files Selected`
                          : selectedFiles[0]?.name}
                      </Text>
                    </Ellipsis>
                  ) : (
                    <Ellipsis>
                      <Text fontSize={2} color={"text1"} fontWeight={"regular"}>
                        Upload Documents
                      </Text>
                    </Ellipsis>
                  )}
                </FlexBox>
                <FlexBox>
                  <FilePlus size={24} color={"#635C1D"} />
                </FlexBox>
              </StyledReferenceDocumentContainer>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    </StyledContainer>
  );
}

export default GenerateDocument;
