import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V3 } from "shared/network/config";
import api from "shared/network/api";

const service = "enterprise/docgen/upload";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export interface RequestPayload {
  files: File[];
  source: string;
  docId: string;
}

export interface DocgenDocumentFileUploadResponse {
  data: {
    text: string;
  };
}

export function useDocumentFileUploadApi(): UseMutationResult<
  DocgenDocumentFileUploadResponse,
  unknown,
  RequestPayload,
  unknown
> {
  return useMutation((payload: RequestPayload) => {
    const formData = new FormData();
    const files = payload?.files;
    const source = payload?.source;
    const docId = payload?.docId;
    if (source) {
      formData.append("source", source);
    }
    if (docId) {
      formData.append("docId", docId);
    }
    files.forEach((file) => {
      formData.append("file", file, file.name);
    });
    return api.postMultiPart<DocgenDocumentFileUploadResponse>(
      serviceUrl,
      formData
    );
  });
}
