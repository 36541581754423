import React, { useState } from "react";
import styled from "styled-components";
import FlexBox from "../../atoms/flexbox";
import Text from "../../atoms/text";

const StyledInputContainer = styled(FlexBox)<{
  backgroundColor: string;
  isError?: boolean;
  paddingRight?: string;
  isFocused?: boolean;
}>`
  padding: 0px ${(props) => (props.paddingRight ? props.paddingRight : "12px")}
    0px 12px;
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor};
  flex: 1;
  border: 0.5px solid
    ${(props) =>
      props.isError ? "#D81846" : props.isFocused ? "#b9b9c7" : "transparent"};
  outline: none;
  justify-content: space-between;
  align-items: center;
`;

const StyledInput = styled.input`
  background-color: transparent;
  outline: none;
  border: 0px;
  &::placeholder {
    color: #b9b9c7;
  }
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: DM Sans;
  line-height: 20px;
  width: 100%;
  height: 100%;
  padding-right: 8px;
`;

const StyledGradientBackground = styled(FlexBox)`
  flex: 1;
  border: 1px solid transparent;
  background-image: linear-gradient(#1e1e24, #1e1e24),
    linear-gradient(89.7deg, #635c1d 0.14%, #1d1293 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 10px;
`;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholderText: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  variant?: "default" | "gradient";
  backgroundColor?: string;
  label?: string;
  isError?: boolean;
  errorText?: string;
  sizeVariant?: "sm" | "md" | "lg";
  icon?: React.ReactNode;
}

function Input({
  placeholderText,
  value,
  onChange,
  label,
  variant = "default",
  backgroundColor = "#1e1e24",
  isError = false,
  errorText = "",
  sizeVariant = "md",
  icon,
  ...rest
}: Props): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);

  if (variant === "gradient") {
    return (
      <StyledGradientBackground>
        <StyledInputContainer
          isError={isError}
          backgroundColor={backgroundColor}
          paddingRight={icon ? "0px" : "12px"}
          isFocused={isFocused}
        >
          <StyledInput
            onChange={onChange}
            value={value}
            placeholder={placeholderText}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            {...rest}
          />
        </StyledInputContainer>
      </StyledGradientBackground>
    );
  }
  return (
    <FlexBox flex={1} flexDirection={"column"}>
      {label && (
        <FlexBox mb={1}>
          <Text fontSize={0} fontWeight={"bold"} color={"text1"}>
            {label}
          </Text>
        </FlexBox>
      )}
      <FlexBox flexDirection={"column"}>
        <FlexBox height={sizeVariant === "lg" ? "48px" : "42px"}>
          <StyledInputContainer
            isError={isError}
            backgroundColor={backgroundColor}
            paddingRight={icon ? "0px" : "12px"}
            isFocused={isFocused}
          >
            <StyledInput
              placeholder={placeholderText}
              onChange={onChange}
              value={value}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              {...rest}
            />
            {icon && icon}
          </StyledInputContainer>
        </FlexBox>
        {isError && errorText && (
          <FlexBox mt={1}>
            <Text fontSize={0} fontWeight={"regular"} color={"error"}>
              {errorText}
            </Text>
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
}

export default Input;
