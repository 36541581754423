import React from "react";

import { FlexBox, Spinner, View } from "mool-design/src";
import styled from "styled-components";
import MoolAiLogoDark from "theme/assets/images/mool-ai-logo-dark.png";

const StyledGradientBackground = styled(FlexBox)`
  width: 100vw;
  height: 100vh;
  background: radial-gradient(
      circle at 40% 98%,
      rgba(255, 230, 0, 0.2),
      transparent 30%
    ),
    radial-gradient(circle at 60% 98%, rgba(29, 18, 147, 0.35), transparent 30%);

  position: relative;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    background: transparent;
  }

  &:before {
    background-image: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 1px,
      transparent 1.5px
    );
    background-size: 600px 350px;
    opacity: 0.6;
  }
  &:after {
    background-image: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 1px,
      transparent 1.5px
    );
    background-size: 450px 700px;
    opacity: 0.6;
  }
`;
export function LoadingComponent(): JSX.Element {
  return (
    <View position="fixed" style={{ marginTop: -64 }}>
      <StyledGradientBackground>
        <FlexBox flex={1} alignItems={"center"} justifyContent={"center"}>
          <FlexBox
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <FlexBox>
              <Spinner
                size={42}
                color={"#00BC62"}
                spinColor={"#3B3B47"}
                borderSize={6}
              />
            </FlexBox>
            <FlexBox mt={2}>
              <img
                src={MoolAiLogoDark}
                style={{ height: 62 }}
                alt="MoolAiLogoDark"
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </StyledGradientBackground>
    </View>
  );
}
