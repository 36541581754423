import { API_URL, API_VERSION_V3 } from "shared/network/config";
import { TEventHandlerFunc } from "shared/types";
import { useEffect } from "react";
import { CustomEventSource } from "shared/network/api/event-source";

const service = "enterprise/document/graph-logs/stream";
const serviceUrl = `${API_URL}${API_VERSION_V3}${service}`;

export function useGraphLogsStreamApi(
  documentId: string | null,
  eventHandler: TEventHandlerFunc
) {
  const url = `${serviceUrl}/${documentId}`;

  useEffect(() => {
    // Create a new EventSource instance to connect to the server
    if (documentId) {
      const eventSource = new CustomEventSource(url);

      eventSource.on("data", (data) => {
        eventHandler(data);
      });

      return () => {
        eventSource.close();
        console.log("[GRAPH-LOGS]::EventSource connection terminated");
      };
    }
  }, [documentId]);
}
